import { catchError, map, Subscription, switchMap, filter } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import type { Disposable } from 'tsyringe';
import type { NotificationEvent } from './notifications.contracts';
import { NOTIFICATION_VISIBILITY_VALUE } from './notifications.contracts';
import { NotificationVisiblitySignal } from './notifications.signals';
import { openNewOrders, openPendingModifications, openRepairQueue } from '@app/generated/sdk';
import { AppWorkspace } from '@app/app-config/workspace.config';
import { notificationSoundLibrary } from './sound-service';
import { testScoped } from '@app/workspace.registry';
import { TsNotificationsWithFilterDocument } from '@oms/generated/frontend';
import type { NotificationRow, TsNotificationsWithFilterSubscription } from '@oms/generated/frontend';
import { TableServerService } from '@app/data-access/services/system/table-server/table-server.service';
import {
  agFilterModelToTableServerFilterStr,
  agSortModelToTableServerSortStr
} from '@app/data-access/services/system/table-server/filters/ag-grid.table-server.transformer';
import { RepairQueueTab } from '@app/widgets/trading/repair-queue/schema.repair-queue.layout/repair_queue.snapshots.schema.v-0';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { SharedNotification } from '@oms/shared/oms-common';

@testScoped
@singleton()
export class NotificationsBackgroundService implements Disposable {
  private isInitialized = false;
  private eventTimestampRef: number;
  private subscription: Subscription | undefined = undefined;

  constructor(
    @inject(NotificationVisiblitySignal) private visibilitySignal: NotificationVisiblitySignal,
    @inject(TableServerService) private tableServerService: TableServerService,
    @inject(AppWorkspace) private appWorkspace: AppWorkspace,
    @inject(AuthService) private authService: AuthService
  ) {
    this.eventTimestampRef = new Date().getTime();
  }

  initialize(): void {
    if (this.isInitialized) {
      return;
    }

    // TODO: Think about retry logic if there are errors?
    // TODO: Think about how to handle errors, and show them in the UI.
    // TODO: Use users service to get user preferences not direct apollo query (See UsersService)

    this.visibilitySignal.signal.$.pipe(
      switchMap((visibility) =>
        this.tableServerService
          .query$<NotificationRow, TsNotificationsWithFilterSubscription>({
            query: TsNotificationsWithFilterDocument,
            variables: {
              filterBy: agFilterModelToTableServerFilterStr({
                addressedUserId: {
                  // Only get the notifications addressed to the current user
                  filterType: 'set',
                  values: [this.authService.getUserId()]
                },
                primaryAlertType: {
                  filterType: 'set',
                  values: NOTIFICATION_VISIBILITY_VALUE[visibility]
                }
              }),
              sortBy: agSortModelToTableServerSortStr([
                {
                  sort: 'desc',
                  colId: 'sourceEventTimestamp'
                }
              ]),
              limit: 1,
              offset: 0
            },
            getData: (r) => r.tsNotificationsWithFilter
          })
          .pipe(
            catchError((err) => {
              console.error(err);
              return [];
            }),
            // Only get notifications that are newer than now, so on dismissal action we don't get the previous notification.
            filter((data) => {
              const eventTimestamp =
                data?.rows && data?.rows.length ? new Date(data.rows[0].sourceEventTimestamp).getTime() : 0;
              return eventTimestamp > this.eventTimestampRef;
            }),
            map(({ errors, rows }) => {
              const notificationEvent = {
                notification: rows![0],
                visibility,
                error: errors
              };

              return notificationEvent;
            })
          )
      )
    ).subscribe(this.handleNotification.bind(this));

    this.isInitialized = true;
  }

  dispose(): void {
    this.subscription?.unsubscribe();
    this.isInitialized = false;
  }

  private handleNotification(notificationEvent: NotificationEvent): void {
    /**
     * Handle notification logic:
     * Look up in dictionary of notification types
     * Apply visibility and user preferences filters
     * Pop up?
     * Play sound?
     */

    const { notification } = notificationEvent;
    if (notification.isPopupLaunched || notification.isPopupLaunched === 'true') {
      switch (notification.name as SharedNotification.DomainDefaultNotificationName) {
        case SharedNotification.DomainDefaultNotificationName.IO_NEW:
          openNewOrders(this.appWorkspace.getLeaderProcessId(), {
            componentProps: {
              autoCloseOnEmpty: true
            }
          }).catch(console.error);
          break;
        case SharedNotification.DomainDefaultNotificationName.IO_MODIFY:
        case SharedNotification.DomainDefaultNotificationName.IO_CANCEL:
          openPendingModifications(this.appWorkspace.getLeaderProcessId(), {
            componentProps: {
              autoCloseOnEmpty: true
            }
          }).catch(console.error);
          break;
        case SharedNotification.DomainDefaultNotificationName.IO_FAILED:
        case SharedNotification.DomainDefaultNotificationName.IO_MODIFY_FAILED:
          openRepairQueue(this.appWorkspace.getLeaderProcessId(), {
            componentProps: {
              tab: RepairQueueTab.INVESTOR_ORDERS,
              autoCloseOnEmpty: true
            }
          }).catch(console.error);
          break;
        case SharedNotification.DomainDefaultNotificationName.TRADE_FAILED:
        case SharedNotification.DomainDefaultNotificationName.TRADE_MODIFY_FAILED:
          openRepairQueue(this.appWorkspace.getLeaderProcessId(), {
            componentProps: {
              tab: RepairQueueTab.TRADES,
              autoCloseOnEmpty: true
            }
          }).catch(console.error);
          break;
      }
    }

    if (notification.isSoundPlayed || notification.isSoundPlayed === 'true') {
      this.playSound(notification.name as SharedNotification.DomainDefaultNotificationName);
    }
  }

  private playSound(notificationName: SharedNotification.DomainDefaultNotificationName) {
    // TODO: check user preferences (for the given notification type) to see whether we play or suppress the sound.
    try {
      notificationSoundLibrary.get(notificationName)?.play();
    } catch (err) {
      console.error(err);
    }
  }
}
