import { FieldDefinition, FormContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import type { OrderSizeProfile } from '@app/data-access/offline/collections/user-preferences.collection';
import { profileIdField, descriptionField, rowsField } from './order-settings.profile.form-fields';

export const orderSizeProfileContract = FormContract.create<OrderSizeProfile>()
  .fields({
    id: profileIdField,
    description: descriptionField,
    rows: rowsField
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.simpleGrid('profile-wrapper', 3, [f.id, f.description], { isInArray: 'profiles' }),
      FieldDefinition.simpleGrid('rows-wrapper', 1, [f.rows], { isInArray: 'profiles' })
    ]
  }));

export type OrderSizeProfileContractType = typeof orderSizeProfileContract;
export type OrderSizeProfileFormValues = InferFormValuesFromFormContract<OrderSizeProfileContractType>;
