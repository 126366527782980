import { Center, Text, VStack, Button } from '@oms/shared-frontend/ui-design-system';
import * as Sentry from '@sentry/react';
import isError from 'lodash/isError';

const ErrorFallback: Sentry.FallbackRender = ({ error, resetError }) => {
  return (
    <Center role="alert" fillArea>
      <VStack>
        <Text type="mediumB" as="p">
          Something went wrong:
        </Text>
        <Text type="baseR" as="p">
          {isError(error) ? error.message : String(error)}
        </Text>
        <Button onClick={resetError}>Try again</Button>
      </VStack>
    </Center>
  );
};

export const ErrorBoundary: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <Sentry.ErrorBoundary fallback={ErrorFallback}>{children}</Sentry.ErrorBoundary>;
};
