import type { ColumnBuilderCallback, ColumnLibrary } from '@oms/frontend-vgrid';
import {
  sharedDateTimeCol,
  sharedDefaultCol,
  sharedIdCol,
  sharedTextCol
} from '@app/common/grids/columns/generic-cols';
import { sharedNameCol, sharedActiveCol } from '@app/common/grids/columns/reference-data-cols';
import type { SnapshotComputedDocType } from '@app/data-access/offline/collections/snapshots/snapshots.collection';

const defaultCol: ColumnBuilderCallback<SnapshotComputedDocType> = (c) =>
  sharedDefaultCol<SnapshotComputedDocType>(c);

const idCol: ColumnBuilderCallback<SnapshotComputedDocType> = (c) =>
  sharedIdCol<SnapshotComputedDocType>(c, 'id').header('ID').hide();

const nameCol: ColumnBuilderCallback<SnapshotComputedDocType> = (c) =>
  sharedNameCol<SnapshotComputedDocType>(c, 'name');

const createdAtCol: ColumnBuilderCallback<SnapshotComputedDocType> = (c) =>
  sharedDateTimeCol<SnapshotComputedDocType>(c, 'createdAt').header('Created at');

const isActiveCol: ColumnBuilderCallback<SnapshotComputedDocType> = (c) =>
  sharedActiveCol<SnapshotComputedDocType>(c, 'isActive');

const sharedByCol: ColumnBuilderCallback<SnapshotComputedDocType> = (c) =>
  sharedTextCol<SnapshotComputedDocType>(c, 'sharerName')
    .header('Shared by')
    .cell((c) =>
      c.valueFormatter<string>(({ data }) => {
        const ownerIsCurrentUser = !data?.sharerName;

        if (!ownerIsCurrentUser) {
          return data.sharerName || 'Unknown';
        } else if (data?.isShared) {
          return 'You';
        } else {
          return 'Not shared';
        }
      })
    );

export const commonSnapshotGridColumnLibrary: ColumnLibrary<SnapshotComputedDocType> = {
  defaultColumn: defaultCol,
  columns: [idCol, nameCol, createdAtCol, isActiveCol, sharedByCol]
};
