import { LookupBySymbolDocument } from '@oms/generated/frontend';
import type {
  InstrumentDetails,
  LookupBySymbolQuery,
  LookupBySymbolQueryVariables
} from '@oms/generated/frontend';
import { container } from 'tsyringe';
import type { AdvancedSelectInstrumentFieldValue } from './symbol.field.queries';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import type { ValidatorFunction } from '@data-driven-forms/react-form-renderer';

export const validateInstrumentTypeahead =
  (): ValidatorFunction =>
  async (value: AdvancedSelectInstrumentFieldValue, _allValues?: object, meta?: object) => {
    if (!value || !value.label) {
      return null;
    }

    try {
      const apolloClient = container.resolve(RxApolloClient);
      const results = await apolloClient.query<LookupBySymbolQuery, LookupBySymbolQueryVariables>({
        query: LookupBySymbolDocument,
        variables: { symbol: value.label }
      });

      if (!results.data.instrumentBySymbol!.length || results.error) {
        throw 'Instrument not found';
      }

      const matchingInstrumentByDisplayCode = results.data.instrumentBySymbol!.find(
        (i) =>
          i &&
          (i.mappings?.displayCode === value.label ||
            i.mappings?.displayCode?.split('-')?.[0] === value.label ||
            i.longName === value.label ||
            `${i.mappings?.displayCode || ''} - ${i.longName || ''}` === value.label)
      );

      if (!matchingInstrumentByDisplayCode) {
        throw 'Instrument not found';
      } else if (matchingInstrumentByDisplayCode.id !== value.id) {
        const val: AdvancedSelectInstrumentFieldValue = {
          type: 'item',
          id: matchingInstrumentByDisplayCode.id,
          label: String(matchingInstrumentByDisplayCode.mappings?.displayCode),
          sublabel: matchingInstrumentByDisplayCode.longName,
          value: matchingInstrumentByDisplayCode as InstrumentDetails
        };

        (meta as { change: (val: AdvancedSelectInstrumentFieldValue) => {} }).change(val);
      }
    } catch (e) {
      console.error(e);
      throw 'Instrument not found';
    }

    return null;
  };
