import { UUID } from '@oms/shared/util';
import { OrderSettingsFormValues } from './order-settings.form-contract';
import { OrderSettingsSchema } from '@app/data-access/offline/collections/user-preferences.collection';

export const transformToFormValues = (schema: Partial<OrderSettingsSchema> = {}): OrderSettingsFormValues => {
  const { profiles = [] } = schema;
  return {
    profiles: profiles.map(({ id = UUID(), description = '', rows = [] }) => ({
      id,
      description,
      rows: rows.map(({ from, id, orderSize, to }) => ({
        from,
        id,
        orderSize,
        to
      }))
    }))
  };
};

export const tranformFromFormValues = (formValues: OrderSettingsFormValues): OrderSettingsSchema => {
  const { profiles = [] } = formValues;
  return {
    profiles: profiles.map(({ id = UUID(), description = '', rows = [] }) => ({
      id,
      description,
      rows: rows.map(({ from, id = UUID(), orderSize, to }) => ({
        from: typeof from === 'string' ? Number.parseFloat(from) : from,
        id,
        orderSize,
        to: typeof to === 'string' ? Number.parseFloat(to) : to
      }))
    }))
  };
};
