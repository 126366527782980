import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import {
  instrumentRestrictionContract,
  InstrumentRestrictionFormContract
} from './instrument-restriction-entry.form-contract';
import { ReferenceDataService } from '@app/data-access/services/trading/reference-data/reference-data.service';
import {
  InstrumentRestrictionEntryInput,
  InstrumentRestrictionEntryOutput
} from './instrument-restriction-entry.form-common';
import { RefDataRestrictionTypeEnum } from '@oms/generated/frontend';

export const instrumentRestrictionEntryBuilder = FormBuilder.create<
  InstrumentRestrictionEntryInput,
  InstrumentRestrictionEntryOutput
>('instrument-restriction-entry')
  .contract<InstrumentRestrictionFormContract>(instrumentRestrictionContract)
  .type('instrument-restriction-entry')
  .sanitizer((s) =>
    s
      .input(() => {
        return {};
      })
      .output((formValues) => ({
        ...formValues,
        instrumentId: formValues.symbol?.id || '',
        restrictionType: formValues.restrictionType ?? RefDataRestrictionTypeEnum.Restricted,
        account: formValues.account?.id || ''
      }))
  )
  .change(async (event, ctx) => {
    const refDataService = ctx.container.resolve(ReferenceDataService);
    if (event.type === FORM_EVENT_TYPE.SUBMIT) {
      const response = await refDataService.createInstrumentRestriction(event.payload.output);
      return response;
    }
  });

export default instrumentRestrictionEntryBuilder;
export type RouteOrderFormBuilderType = typeof instrumentRestrictionEntryBuilder;
