import { INLINE_SPLIT_BUTTON_ACTION } from '@oms/frontend-vgrid';
import type {
  ActionContext,
  ActionDefFactory,
  SplitButtonActionComponentConfig,
  SplitButtonActionConfig
} from '@oms/frontend-vgrid';
import { TradingOrderStatus } from '@oms/generated/frontend';
import { cancelTradingOrderOnChange } from './cancel.trading-order.action';
import { modifyTradingOrderOnChange } from './modify.trading-order.action';
import { viewTradingOrderOnChange } from './view.trading-order.action';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { ValstroEntitlements } from '@oms/codegen/entitlements';

type InlineTradingOrderActionArgs = {
  id: string;
  status: string;
  side: string;
  orderType: string;
  limitPrice: string;
  totalQuantity: number;
  instrumentDisplayCode: string;
};

export const inlineTradingOrderActions =
  <T extends InlineTradingOrderActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('inline_trading_order_split_button')
      .inline((col) =>
        col
          .header('Actions')
          .initialWidth(130)
          .filter(false)
          .cell((c) =>
            c.dependsOn(['id', 'status']).renderer(INLINE_SPLIT_BUTTON_ACTION, { variant: 'secondary' })
          )
      )
      .onChange<SplitButtonActionComponentConfig<T>>((ctx) => {
        switch (ctx.lifecycle) {
          case 'init':
          case 'refresh': {
            const [rowData] = ctx.data;
            ctx.notify({ actionsConfig: rowActions(ctx, rowData) });
            break;
          }
          case 'change': {
            const { selectedId } = ctx.state;
            switch (selectedId) {
              case 'inline_view_trading_order':
                return viewTradingOrderOnChange(ctx);
              case 'inline_modify_trading_order':
                return modifyTradingOrderOnChange(ctx);
              case 'inline_cancel_trading_order':
                return cancelTradingOrderOnChange(ctx);
              default:
                throw new Error(`onChange not defined for ${selectedId || ''}`);
            }
          }
        }
      });

function hasEntitlement<T extends InlineTradingOrderActionArgs>(
  ctx: ActionContext<T, SplitButtonActionComponentConfig<T>>
): boolean {
  const authService = ctx.appContainer.resolve(AuthService);
  return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
}

function rowActions<T extends InlineTradingOrderActionArgs>(
  ctx: ActionContext<T, SplitButtonActionComponentConfig<T>>,
  rowData: T
): SplitButtonActionConfig[] {
  const canManage = hasEntitlement(ctx);
  if (!canManage) {
    return rowData.id
      ? [
          {
            id: 'inline_view_trading_order',
            title: 'View Order'
          }
        ]
      : [];
  }
  switch (rowData.status) {
    case TradingOrderStatus.Active:
      return [
        {
          id: 'inline_cancel_trading_order',
          title: 'Cancel'
        },
        {
          id: 'inline_modify_trading_order',
          title: 'Modify'
        }
      ];

    default:
      return rowData.id
        ? [
            {
              id: 'inline_view_trading_order',
              title: 'View Order'
            }
          ]
        : [];
  }
}
