import { useRef } from 'react';
import { InvestorOrderRepairRequestService } from '@app/data-access/services/trading/repair-requests/investor-order-repair-requests.service';
import { useScopedActorId } from '@app/data-access/offline/hooks/use-scoped-actor-id.hook';
import { useSelectTabOnLayoutEffect } from '@app/data-access/hooks/use-select-tab.hook';
import { useScopedProps } from '@app/data-access/hooks/use-scoped-props.hook';
import type { IoRepairRequestFragment } from '@oms/generated/frontend';
import {
  useVGrid,
  VGrid,
  toClientsideGridData,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE
} from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { createCloseOnEmptyEventHandler } from '@app/common/grids/event-handlers/close-on-empty.event-handler';
import { ioRepairRequestColumnLibrary } from './repair-queue.investor-orders.columns';
import { groupByInvestorOrdersAction } from './grid-actions/group-by.repair-queue.investor-orders.action';
import { rejectRepairQueueInvestorOrdersAction } from './grid-actions/reject.repair-queue.investor-orders.action';
import { retryInvestorOrdersAction } from './grid-actions/retry.investor-orders.action';
import { repairInvestorOrdersAction } from './grid-actions/repair.investor-orders.action';
import { repairQueueInlineActions } from './grid-actions/inline.repair-queue.investor-orders.action';
import type { RepairQueueLayoutProps } from './repair-queue.layout.config';

export const REPAIR_QUEUE_INVESTOR_ORDERS = 'repair-queue-investor-orders';

export const RepairQueueInvestorOrdersGridWidget = () => {
  const { tab = '', autoCloseOnEmpty } = useScopedProps<RepairQueueLayoutProps>() ?? {};
  const scopedActorId = useScopedActorId();
  const datasourceService = useService(InvestorOrderRepairRequestService);
  const query$ = useRef(datasourceService.watchAll$().pipe(toClientsideGridData()));

  useSelectTabOnLayoutEffect(scopedActorId, tab);

  const gridProps = useVGrid<IoRepairRequestFragment>(
    REPAIR_QUEUE_INVESTOR_ORDERS,
    (b) =>
      b
        .columnLibrary(ioRepairRequestColumnLibrary)
        .datasource((d) => d.source(query$.current).rowId(({ data }) => data?.id as string))
        .rowSelection((c) => c.multiple())
        .rowStateRules({
          hazard: () => true
        })
        .sideBar()
        .injectEvents([createCloseOnEmptyEventHandler(autoCloseOnEmpty)])
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(groupByInvestorOrdersAction)
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(retryInvestorOrdersAction<IoRepairRequestFragment>())
                .action(repairInvestorOrdersAction<IoRepairRequestFragment>())
                .action(repairQueueInlineActions)
                .action(rejectRepairQueueInvestorOrdersAction<IoRepairRequestFragment>())
                .action(refreshServersideCustomMenuAction)
            )
            .settings((s) => s.fromFile({}))
        ),
    [query$]
  );

  return <VGrid {...gridProps} />;
};

export default RepairQueueInvestorOrdersGridWidget;
