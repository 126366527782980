import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('ACTION_BUTTON_FORM_INPUT', {
  version: 0,
  schema: z
    .object({
      id: z.string()
    })
    .describe('Action button schema')
});
