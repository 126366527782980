import type { FetchResult, Observable as ApolloObservable } from '@apollo/client';
import type { GraphQLError } from 'graphql';
import { Observable as RxObservable } from 'rxjs';

export function fromApolloSub<T>(apolloObs$: ApolloObservable<FetchResult<T>>): RxObservable<FetchResult<T>> {
  return new RxObservable<FetchResult<T>>((obs) => {
    const sub = apolloObs$.subscribe(
      (e) => obs.next(e),
      (err) => {
        const error = err as GraphQLError;
        console.error(error);
        obs.error(err);
      },
      () => obs.complete()
    );

    return () => {
      sub.unsubscribe();
    };
  });
}
