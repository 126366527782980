import { ObservableQuery } from '@apollo/client';
import type {
  ApolloQueryResult,
  Observable as ApolloObservable,
  ApolloError,
  FetchMoreQueryOptions,
  NetworkStatus,
  SubscribeToMoreOptions,
  TypedDocumentNode,
  WatchQueryOptions,
  OperationVariables
} from '@apollo/client';
import { Observable as RxObservable } from 'rxjs';

export type ApolloObserverKeys<TData = any> = keyof ApolloObservable<TData>;
export interface ObservableQueryOperations<TData, TVariables extends OperationVariables = OperationVariables>
  extends Omit<ObservableQuery<TData, TVariables>, ApolloObserverKeys<TData>> {}

export class RxObservableQuery<TData, TVariables extends OperationVariables = OperationVariables>
  extends RxObservable<ApolloQueryResult<TData>>
  implements ObservableQueryOperations<TData, TVariables>
{
  private _observableQuery: ObservableQuery<TData, TVariables>;

  constructor(observableQuery: ObservableQuery<TData, TVariables>) {
    super((obs) => {
      const sub = observableQuery.subscribe(
        (r) => {
          obs.next(r);
        },
        (err) => obs.error(err),
        () => obs.complete()
      );

      return () => {
        sub.unsubscribe();
      };
    });
    this._observableQuery = observableQuery;
  }

  public get options(): WatchQueryOptions<TVariables, TData> {
    return this._observableQuery.options;
  }

  public get queryId(): string {
    return this._observableQuery.queryId;
  }

  public get queryName(): string | undefined {
    return this._observableQuery.queryName;
  }

  public get query(): TypedDocumentNode<TData, TVariables> {
    return this._observableQuery.query;
  }

  public get variables(): TVariables | undefined {
    return this._observableQuery.variables;
  }

  public result(): Promise<ApolloQueryResult<TData>> {
    return this._observableQuery.result();
  }

  public getCurrentResult(saveAsLastResult?: boolean | undefined): ApolloQueryResult<TData> {
    return this._observableQuery.getCurrentResult(saveAsLastResult);
  }

  public isDifferentFromLastResult(newResult: ApolloQueryResult<TData>): boolean | undefined {
    return this._observableQuery.isDifferentFromLastResult(newResult);
  }

  public getLastResult(variablesMustMatch?: boolean | undefined): ApolloQueryResult<TData> | undefined {
    return this._observableQuery.getLastResult(variablesMustMatch);
  }

  public getLastError(variablesMustMatch?: boolean | undefined): ApolloError | undefined {
    return this._observableQuery.getLastError(variablesMustMatch);
  }

  public resetLastResults(): void {
    this._observableQuery.resetLastResults();
  }

  public resetQueryStoreErrors(): void {
    this._observableQuery.resetQueryStoreErrors();
  }

  public refetch(variables?: Partial<TVariables> | undefined): Promise<ApolloQueryResult<TData>> {
    return this._observableQuery.refetch(variables);
  }

  public fetchMore<TFetchData = TData, TFetchVars extends OperationVariables = OperationVariables>(
    fetchMoreOptions: FetchMoreQueryOptions<TFetchVars, TFetchData> & {
      updateQuery?:
        | ((
            previousQueryResult: TData,
            options: { fetchMoreResult: TFetchData; variables: TFetchVars }
          ) => TData)
        | undefined;
    }
  ): Promise<ApolloQueryResult<TFetchData>> {
    return this._observableQuery.fetchMore(fetchMoreOptions);
  }

  public subscribeToMore<
    TSubscriptionData = TData,
    TSubscriptionVariables extends OperationVariables = OperationVariables
  >(options: SubscribeToMoreOptions<TData, TSubscriptionVariables, TSubscriptionData>): () => void {
    return this._observableQuery.subscribeToMore(options);
  }

  public setOptions(
    newOptions: Partial<WatchQueryOptions<TVariables, TData>>
  ): Promise<ApolloQueryResult<TData>> {
    return this._observableQuery.setOptions(newOptions);
  }

  public setVariables(variables: TVariables): Promise<void | ApolloQueryResult<TData>> {
    return this._observableQuery.setVariables(variables);
  }

  public updateQuery<TVars extends OperationVariables = OperationVariables>(
    mapFn: (previousQueryResult: TData, options: Pick<WatchQueryOptions<TVars, TData>, 'variables'>) => TData
  ): void {
    this._observableQuery.updateQuery(mapFn);
  }

  public startPolling(pollInterval: number): void {
    this._observableQuery.startPolling(pollInterval);
  }

  public stopPolling(): void {
    this._observableQuery.stopPolling();
  }

  public reobserve(
    newOptions?: Partial<WatchQueryOptions<TVariables, TData>> | undefined,
    newNetworkStatus?: NetworkStatus | undefined
  ): Promise<ApolloQueryResult<TData>> {
    return this._observableQuery.reobserve(newOptions, newNetworkStatus);
  }

  public hasObservers(): boolean {
    return this._observableQuery.hasObservers();
  }
}

export function fromObservableQuery<TData, TVariables extends OperationVariables = OperationVariables>(
  query: ObservableQuery<TData, TVariables>
): RxObservableQuery<TData, TVariables> {
  return new RxObservableQuery<TData, TVariables>(query);
}
