import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';
import {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { JoinedInstrumentRestrictionRow } from '@oms/generated/frontend';

const instrumentRestrictionGridColumns: ColDefMap<JoinedInstrumentRestrictionRow> = {
  id: { extends: 'orders.id', hide: true },
  instrumentId: { extends: 'orders.instrumentId', hide: true },
  instrumentDisplayCode: { extends: 'orders.instrumentDisplayCode' },
  instrumentDescription: { extends: 'orders.instrumentLongName' },
  account: { extends: 'generic.text' },
  accountDescription: { extends: 'generic.text' },
  applyToFungible: { extends: 'generic.default' },
  applyToRelated: { extends: 'generic.default' },
  restrictionType: { extends: 'generic.text', width: 200 },
  restrictionEffectiveDate: { extends: 'generic.dateTime', width: 200 },
  restrictionExpiryDate: { extends: 'generic.dateTime', width: 200 }
};

export const defaultColumn: EnrichedColumnDef = {
  extends: 'generic.default'
};

const instrumentsGridColumnLibrary: TableServerColumnLibrary<JoinedInstrumentRestrictionRow> = {
  defaultColumn,
  columns: instrumentRestrictionGridColumns
};

export const buildInstrumentsGridColmuns = () => buildColumnDefs(instrumentsGridColumnLibrary, 'instruments');
