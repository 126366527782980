import { FORM_EVENT_TYPE, FormBuilder, GQLResult } from '@oms/frontend-foundation';
import type {
  ActionButtonLayoutContractType,
  ActionButtonLayoutValues
} from './action-button-layout.form-contract';
import { actionButtonLayoutContract } from './action-button-layout.form-contract';
import type {
  ActionButtonLayoutFormInput,
  ActionButtonLayoutFormOutput
} from './action-button-layout.contracts';
import { ActionConfigService } from '@app/actions/services/action.config.service';
import { ACTION_COLOR_LABELS, ACTION_DEFINED_COLORS } from '../types';
import type { LayoutAction } from '../types';
import omit from 'lodash/omit';
import { ACTION_COMMANDS_TO_LABELS, getCommandFromLabel } from '../utils/button.action.schema';
import { GeneralError } from '@oms/shared/oms-common';
import type { ActionCommands } from '@app/actions/commands/command.registry.types';
import { Actor } from '@valstro/workspace';
import type { AppWindowActorSchema } from '@app/app-config/workspace.config';

export const actionButtonLayoutFormBuilder = FormBuilder.create<
  ActionButtonLayoutFormInput,
  ActionButtonLayoutFormOutput['layout']
>('action-layout-form')
  .contract<ActionButtonLayoutContractType>(actionButtonLayoutContract)
  .type('action-layout-form')
  .sanitizer((s) =>
    s
      .input(async function sanitize(incomingValues, ctx) {
        const { container } = ctx;
        const actionsConfigSvc = container.resolve(ActionConfigService);
        const actions = await actionsConfigSvc.actionsBy({
          ...omit(incomingValues, 'allowedCommands')
        });
        const { allowedCommands = [] } = incomingValues;

        const values: ActionButtonLayoutValues = {
          layout: {
            requiredFields: incomingValues as ActionButtonLayoutFormInput,
            actions: actions
              .filter((a) => allowedCommands.includes(a.commandId) || !allowedCommands?.length)
              .map((a) => {
                const labelOrFn = ACTION_COMMANDS_TO_LABELS[a.commandId as ActionCommands];
                return {
                  ...a,
                  commandId: labelOrFn
                    ? typeof labelOrFn === 'function'
                      ? labelOrFn()
                      : labelOrFn
                    : a.commandId,
                  color: ACTION_DEFINED_COLORS.get(a.color.backgroundColor) || 'Blue'
                };
              }) as LayoutAction[]
          }
        };

        return values;
      })
      .output(function sanitize(formValues) {
        const output = {
          actions: (formValues.layout?.actions || []).map((a) => ({
            ...omit(
              a,
              'id',
              'allowedCommands',
              'allowsColor',
              'allowsConfirmation',
              'allowsLabel',
              'allowsSize'
            ),
            id: ActionConfigService.idOf({
              ...a,
              color: ACTION_COLOR_LABELS[a.color],
              commandId: getCommandFromLabel(a.commandId)
            }),
            commandId: getCommandFromLabel(a.commandId),
            color: ACTION_COLOR_LABELS[a.color]
          })),
          requiredFields: omit(formValues.layout?.requiredFields, 'allowedCommands')
        } as ActionButtonLayoutFormOutput['layout'];
        return output;
      })
  )
  .change(async (event, ctx) => {
    const actionsConfigSvc = ctx.container.resolve(ActionConfigService);
    switch (event.type) {
      case FORM_EVENT_TYPE.MOUNT:
        break;
      case FORM_EVENT_TYPE.UNMOUNT:
        break;
      case FORM_EVENT_TYPE.SUBMIT: {
        const { output } = event.payload;
        const { actions } = output;

        const changes = await actionsConfigSvc.merge(output.requiredFields, ...actions);

        const { upserts, deletes } = changes;

        const results = await Promise.allSettled([
          actionsConfigSvc.delete(...deletes),
          actionsConfigSvc.upsert(...upserts)
        ]);

        const generalErrors: GeneralError[] = results.reduce((errors, promise) => {
          if (promise.status === 'rejected') {
            errors.push(new GeneralError(String(promise.reason)));
          }
          return errors;
        }, [] as GeneralError[]);

        if (generalErrors.length) {
          return GQLResult.failure(generalErrors);
        }

        Actor.get<AppWindowActorSchema>(event.meta.windowId, 500)
          .then((actor) => {
            actor.operations.close().catch(console.error);
          })
          .catch(console.error);

        break;
      }
      case FORM_EVENT_TYPE.VALUES_CHANGED:
        break;
    }
  });

export default actionButtonLayoutFormBuilder;
