import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { TradingOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import { openCancelTradingOrderDialog } from '@app/common/types/orders/trading-order/open.cancel.trading-order';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';

type CancelTradingOrderAction = { id: string; status: string };

function getActiveRowsLength(data: CancelTradingOrderAction[]) {
  return data.filter((selectedRow) => selectedRow.status === TradingOrderStatus.Active).length;
}

function isVisible(data: CancelTradingOrderAction[]) {
  return getActiveRowsLength(data) > 0;
}

export const cancelTradingOrderOnChange = async <T extends CancelTradingOrderAction>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data, workspace, container, windowActor } = ctx;
  const activeRows = getActiveRowsLength(data);

  ctx.notify({ isDisabled: activeRows === 0 });

  if (lifecycle === 'change') {
    const activeTOIds = data
      .filter((selectedRow) => selectedRow.status === TradingOrderStatus.Active)
      .map((row) => row.id);
    await openCancelTradingOrderDialog({
      workspace,
      container,
      windowId: windowActor.id,
      tradingOrderIds: activeTOIds
    });
  }
};

export const cancelTradingOrderAction =
  <T extends CancelTradingOrderAction>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('cancel_trading_order')
      .toolbar((t) =>
        t
          .component('action-button')
          .id('cancel_trading_order_button')
          .location('HorizontalToolbarRight')
          .props({
            isDisabled: true,
            content: 'Cancel'
          })
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);

        return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
      })
      .customMenu((m) => m.name('Cancel').visible(({ rowData }) => isVisible(rowData)))
      .onChange<ActionComponentConfig<T>>(cancelTradingOrderOnChange);
