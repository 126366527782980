import { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import { FormContract } from '@oms/frontend-foundation';
import {
  symbol,
  account,
  restrictionType,
  fungible,
  related,
  restrictionEffectiveDate,
  restrictionExpiryDate
} from './instrument-restriction-entry.form-fields';
import { InstrumentRestrictionEntryFieldContract } from './instrument-restriction-entry.form-common';
import { createEnrichedField } from '@app/forms/form-builder/fields/additional-fields/additional-fields.common';

export const instrumentRestrictionContract = FormContract.create<InstrumentRestrictionEntryFieldContract>()
  .fields({
    symbol,
    account,
    restrictionType,
    fungible,
    related,
    restrictionEffectiveDate,
    restrictionExpiryDate
  })
  .schema(() => ({
    fields: [
      {
        component: 'box',
        name: 'symbol-box',
        fields: [[createEnrichedField(symbol)]],
        sx: { paddingLeft: 5, paddingRight: 5, paddingTop: 5 }
      },
      {
        component: 'simple-grid',
        name: 'checboxes-grid',
        columns: 2,
        fields: [[createEnrichedField(fungible)], [createEnrichedField(related)]],
        sx: { paddingLeft: 5, paddingRight: 5, paddingTop: 5 }
      },
      {
        component: 'simple-grid',
        name: 'restiction-options-grid',
        columns: 2,
        rows: 2,
        fields: [
          [createEnrichedField(restrictionType), createEnrichedField(account)],
          [createEnrichedField(restrictionEffectiveDate), createEnrichedField(restrictionExpiryDate)]
        ],
        sx: { paddingLeft: 5, paddingRight: 5, paddingTop: 5 }
      }
    ]
  }))
  .template('simple', {
    showSubmitButton: true,
    submitLabel: 'Add instrument restriction',
    showCancelButton: true
  });

export type InstrumentRestrictionFormContract = typeof instrumentRestrictionContract;

export type InstrumentRestrictionFormValues =
  InferFormValuesFromFormContract<InstrumentRestrictionFormContract>;
