import useInterval from 'beautiful-react-hooks/useInterval';
import { useState, useMemo } from 'react';
import type { ICellRendererParams } from '@ag-grid-community/core';
import {
  Box,
  Flex,
  Heading,
  Text,
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger
} from '@oms/shared-frontend/ui-design-system';
import type { FeedbackWrapper, NotificationRow } from '@oms/generated/frontend';
import { getElapsedTime, getNotificationBody } from './notifications.utils';

const feedbackTextStyle = (level: string) => ({
  backgroundColor: level === 'Warning' ? 'var(--colors-base-BlueGrey-700)' : 'var(--colors-base-Red-800)'
});

const FeedbackText = ({ error }: { error: FeedbackWrapper }) => (
  <Box
    style={feedbackTextStyle(error.level)}
    sx={{ paddingX: 3, paddingY: 4 }}
    data-testid="notificationFeedbackItem"
  >
    <Text type={'gridBase'}>{error.message} </Text>
    <Text type={'gridTiny'} sx={{ color: 'BlueGrey.400' }}>
      {error.code}
    </Text>
  </Box>
);

const FeedbackList = ({ feedback, testId }: { feedback: FeedbackWrapper[]; testId: string }) => (
  <ul style={{ padding: 0 }} data-testid={testId}>
    {feedback.map((feedbackItem) => (
      <li
        key={feedbackItem.code}
        style={{ listStyleType: 'none', borderTop: '1px solid var(--colors-base-BlueGrey-700)' }}
      >
        <FeedbackText error={feedbackItem} />
      </li>
    ))}
  </ul>
);

// TODO: may be reused (e.g. Repair Widget)
type FeedbackComponentProps = {
  feedback?: FeedbackWrapper[];
};
export const FeedbackComponent: React.FC<FeedbackComponentProps> = ({ feedback }) => {
  if (!feedback || feedback.length === 0) return null;

  const errors = feedback.filter((f) => f.level === 'Error');
  const warnings = feedback.filter((f) => f.level === 'Warning');
  const triggerText = (feedbacks: FeedbackWrapper[], text: 'error' | 'warning') => {
    const plural = feedbacks.length > 1 ? `${text}s` : text;

    return `${feedbacks.length} ${plural}`;
  };

  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger asChild>
        <Box sx={{ display: 'inline' }}>
          {` · `}
          <Text
            sx={{ display: 'inline', cursor: 'pointer', color: 'BrandBlue.300' }}
            data-testid="feedbackTrigger"
          >
            {errors.length > 0 && warnings.length > 0
              ? `${triggerText(errors, 'error')} + ${triggerText(warnings, 'warning')}`
              : errors.length > 0
                ? triggerText(errors, 'error')
                : triggerText(warnings, 'warning')}
          </Text>
        </Box>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent style={{ padding: 0 }}>
          <FeedbackList feedback={feedback} testId="notificationFeedbackList" />
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenuRoot>
  );
};

export const NotificationCellRenderer: React.FC<ICellRendererParams<NotificationRow, any>> = (props) => {
  // TODO: feedback should be of type FeedbackWrapper[] but is currently returned to us as a string.
  const { name: templateName = '', sourceEventTimestamp = '' /*, feedback*/ } = props.data || {};
  const [elapsedTime, setElapsedTime] = useState(getElapsedTime(sourceEventTimestamp));
  const content = useMemo(() => (props.data ? getNotificationBody(props.data) : ''), [props.data]);

  useInterval(() => {
    setElapsedTime(getElapsedTime(sourceEventTimestamp));
  }, 60000);

  return (
    <Flex sx={{ position: 'absolute', inset: 0 }} align="center">
      <Box sx={{ width: 'full', px: 3 }}>
        <Heading type="baseB" sx={{ lineHeight: 'smallR' }}>
          {content}
        </Heading>
        <Text type="baseR" sx={{ color: 'text.semiMinor' }}>
          <span>{elapsedTime}</span>
          {` · `}
          <span>{templateName}</span>
          {/* TODO: comment out the FeedbackComponent for now */}
          {/* {<FeedbackComponent feedback={feedbackMetadata} />} */}
        </Text>
      </Box>
    </Flex>
  );
};
