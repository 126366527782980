import type { Maybe } from '@oms/generated/frontend';
import type { Level1IntegrationEvent } from '@oms/generated/frontend';
import type { Optional } from '@oms/shared/util-types';
import type { Observable } from 'rxjs';

export type Level1Data = Omit<Level1IntegrationEvent, '__typename'>;
export class MarketData {
  constructor(data?: Maybe<Partial<MarketData>>) {
    data = data || {};
    data.level1 = data.level1 || {};
    this.level1 = data.level1;
  }

  public level1: Level1Data;

  public advPct(quantity: number): number {
    return quantity && this.level1.adv30day ? quantity / this.level1.adv30day : 0;
  }
}

export interface MarketDataEvent {
  ticker: string;
  data?: Level1IntegrationEvent;
}

export interface IMarketDataService {
  observe(...tickers: string[]): Observable<MarketDataEvent>;

  hasAllTickers(...tickers: string[]): boolean;

  read(ticker: string): Optional<MarketData>;

  readAll(...tickers: string[]): Optional<MarketData>[];
}
