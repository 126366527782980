import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import type {
  GetOrderByIdQuery,
  GetOrderByIdQueryVariables,
  PrincipalFillInput,
  RequestPrincipalFillMutation,
  RequestPrincipalFillMutationVariables
} from '@oms/generated/frontend';
import { GetOrderByIdDocument, RequestPrincipalFillDocument } from '@oms/generated/frontend';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class PrincipalFillService {
  private _apolloClient: RxApolloClient;
  private _gqlResponse: GQLResponse;

  constructor(
    @inject(RxApolloClient) apolloClient: RxApolloClient,
    @inject(GQLResponse) gqlResponse: GQLResponse
  ) {
    this._apolloClient = apolloClient;
    this._gqlResponse = gqlResponse;
  }

  async requestPrincipalFill({
    clientInvestorOrderIds,
    principalFill,
    dryRun
  }: {
    clientInvestorOrderIds: string[];
    principalFill: PrincipalFillInput;
    dryRun: boolean;
  }) {
    const response = this._gqlResponse.wrapMutate<
      RequestPrincipalFillMutation,
      RequestPrincipalFillMutationVariables
    >({
      mutation: RequestPrincipalFillDocument,
      variables: {
        clientInvestorOrderIds,
        principalFill,
        dryRun
      }
    });

    return await response.awaitAsyncResponse().exec();
  }

  async getOrderById(investorOrderId: string) {
    return await this._apolloClient.query<GetOrderByIdQuery, GetOrderByIdQueryVariables>({
      query: GetOrderByIdDocument,
      variables: {
        id: investorOrderId
      }
    });
  }
}
