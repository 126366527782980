import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type { DependencyContainer } from 'tsyringe';
import { openCrossPrincipalFill } from '@app/generated/sdk';
import type { ActionDefFactory } from '@oms/frontend-vgrid';
import { ValstroEntitlements } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';
import { internalizationCommonFilters } from './grid-actions.utils';

type OpenCrossPrincipalFillActionArgs = {
  id: string;
  status: string;
  investorAccountType: string;
  openQuantity: number;
  ownerID: string;
};

export const isActionEnabled = (
  appContainer: DependencyContainer,
  data: OpenCrossPrincipalFillActionArgs[]
) => {
  if (data.length !== 1) {
    return false;
  }

  const authSerive = appContainer.resolve(AuthService);
  const userId = authSerive.getUserId();

  return internalizationCommonFilters(data[0], userId);
};

export const openCrossPrincipalFillAction =
  <T extends OpenCrossPrincipalFillActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('open_cross_principal_fill')
      .toolbar((t) =>
        t.id('cross_principal_fill').component('action-button').location('HorizontalToolbarRight').props({
          content: 'Cross',
          isDisabled: true
        })
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);

        return authService.hasEntitlement([ValstroEntitlements.TradeManage]);
      })
      .customMenu((m) =>
        m.name('Cross').visible(({ rowData, appContainer }) => isActionEnabled(appContainer, rowData))
      )
      .onChange(async (e) => {
        const { lifecycle, data, appContainer } = e;

        e.notify({ isDisabled: !isActionEnabled(appContainer, data) });

        if (lifecycle === 'change') {
          await openCrossPrincipalFill(PROCESS_ID.LEADER, {
            form: {
              input: {
                id: data[0].id
              }
            }
          });
        }
      });
