import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('ACTION_BUTTON_LAYOUT_FORM_INPUT', {
  version: 0,
  schema: z
    .object({
      layout: z
        .object({
          requiredFields: z.object({
            objectId: z.string(),
            locationId: z.string(),
            widgetTypeId: z.string()
          }),
          allowedCommands: z.array(z.string()).nullable(),
          actions: z.array(
            z.object({
              id: z.string(),
              widgetTypeId: z.string(),
              objectId: z.string(),
              commandId: z.string(),
              locationId: z.string(),
              payload: z.any(),
              label: z.string(),
              color: z.object({
                backgroundColor: z.string(),
                color: z.string()
              }),
              size: z.string(),
              order: z.number(),
              separator: z.boolean(),
              parentId: z.string().nullable()
            })
          )
        })
        .nullable()
    })
    .describe('Action button layout schema')
});
