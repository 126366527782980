import { InvestorAccountType, InvestorOrderStatus } from '@oms/generated/frontend';

type InternalizationFilterArgs = {
  investorAccountType: string;
  openQuantity: number;
  status: string;
  ownerID: string;
};

export const internalizationCommonFilters = <T extends InternalizationFilterArgs>(
  order: T,
  userId: string | null | undefined
) => {
  const accountTypeFilter = order.investorAccountType === InvestorAccountType.Client;
  const openQuantityFilter = order.openQuantity && order.openQuantity > 0;
  const statusFilter = order.status === InvestorOrderStatus.Active;
  const ownerFilter = userId ? order.ownerID === userId : false;

  return Boolean(accountTypeFilter && openQuantityFilter && statusFilter && ownerFilter);
};
