import { inject, singleton } from 'tsyringe';
import type { Dispatch, SetStateAction } from 'react';
import { TableServerService } from '../../system/table-server/table-server.service';
import { TableServerRowSubscriptionVariables } from '../../system/table-server/table-server.datasource.contracts';
import { agFilterModelToTableServerFilterStr } from '../../system/table-server/filters/ag-grid.table-server.transformer';
import { testScoped } from '@app/workspace.registry';
import { TsInvestorOrdersWithChargesDocument } from '@app/widgets/trading/investor-order-monitor/investor-order-monitor.contracts';
import type {
  InvestorOrderWithChargesRow,
  TsInvestorOrdersWithChargesSubscription
} from '@app/widgets/trading/investor-order-monitor/investor-order-monitor.contracts';

@testScoped
@singleton()
export class InvestorOrdersSubscriptionService {
  constructor(@inject(TableServerService) private tableServerService: TableServerService) {}

  public track(
    orderId: string,
    setOrder: Dispatch<SetStateAction<InvestorOrderWithChargesRow | undefined>>,
    setError: Dispatch<SetStateAction<Error | undefined>>
  ) {
    const variables: TableServerRowSubscriptionVariables = {
      filterBy: agFilterModelToTableServerFilterStr<keyof InvestorOrderWithChargesRow>({
        id: {
          filterType: 'text',
          type: 'contains',
          filter: orderId
        }
      }),
      limit: 1,
      offset: 0,
      sortBy: ''
    };

    const subscription = this.tableServerService
      .query$<InvestorOrderWithChargesRow, TsInvestorOrdersWithChargesSubscription>({
        query: TsInvestorOrdersWithChargesDocument,
        getData: (r) => r.tsInvestorOrdersWithFilter,
        variables
      })
      .subscribe(({ errors, rows }) => {
        if (errors?.length) {
          setError(new Error('Error getting investor order'));
          console.error(errors);
        }
        if (!errors && rows?.length === 0) {
          setError(new Error('Investor order not found'));
        }

        setOrder(rows?.[0]);
      });

    return () => {
      subscription.unsubscribe();
    };
  }

  public dispose() {}
}
