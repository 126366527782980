import { catchError, finalize, map, of, retry, tap, timer } from 'rxjs';
import type { OperatorFunction } from 'rxjs';
import type {
  EndpointFilter,
  FactsetEndpoints,
  FactsetFinalizeConfig,
  FactsetMapConfig,
  Level1FactsetWithFallbackFields,
  MarketDataErrors,
  RawLevel1IntegrationEvent
} from './factset.types';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { DateTime } from 'luxon';
import { TickDirection } from '@oms/generated/frontend';
import type { Level1IntegrationEvent } from '@oms/generated/frontend';
import { createLogger } from '@oms/shared/util';
import * as Sentry from '@sentry/react';
import { addFactsetScopeToSentry } from './sentry.factset';

const logger = createLogger({ label: 'Level1IntegrationEvent' });

// STUB: might need later different identifier types
export const parseTicker = (ticker: string) => ticker;

// Some of Factset's data comes back as untrimmed strings so this trims those fields.
export const trimAllStringValues = (e: any) => {
  Object.entries(e)
    .filter(([_, v]) => isString(v))
    .forEach(([k, v]) => {
      (e as Record<string, any>)[k] = (v as string).trim();
    });
};

const getNumericScale = (val: any): number => val?.toString()?.split('.')?.[1]?.length || 0;

export const factsetMap = <T extends object, R extends object>(
  config: FactsetMapConfig<R>
): OperatorFunction<T, R> => {
  const { mappings, result = {} as R } = config;
  return (obs$) =>
    obs$.pipe(
      map((e) => {
        const missingFields: (keyof R)[] = [];
        mappings.forEach(([source, dest]) => {
          const val = get(e, source);
          const exists = typeof val === 'boolean' || !!val;

          if (!exists) {
            missingFields.push(dest);
          }

          exists && set(result, dest, val);
        });
        evaluateEndpointFilter(config.endpoint, config.logMissingFields, () => {
          missingFields.length &&
            logger.warn(`[${config.endpoint}] factset missing fields`, missingFields, ' response ', e);
        });

        return result;
      })
    );
};

export const marketDataErrors = <T>(
  endpoint: FactsetEndpoints,
  config: MarketDataErrors<Level1IntegrationEvent>
): OperatorFunction<T, T | Level1IntegrationEvent> => {
  return (source$) =>
    source$.pipe(
      retry({
        resetOnSuccess: true,
        delay: (err, count) => {
          const retryTime = Math.min(count * 1000, 30000); // capping retry backoff to 30s.
          Sentry.captureException(err, (scope) => {
            addFactsetScopeToSentry(scope, {
              client: config.client,
              requestPayload: config.requestPayload,
              ticker: config.ticker,
              endpoint,
              message: `Market data error for ${config.ticker} on endpoint ${endpoint}`
            });

            return scope;
          });
          evaluateEndpointFilter(endpoint, config.logRetries, () => {
            logger.error(`[${endpoint}] Error ${err}. Retrying in ${retryTime / 1000}s.`);
          });
          return timer(retryTime);
        }
      }),
      catchError((err, _caught) => {
        logger.error(`[${endpoint}] `, err);
        Sentry.captureException(err, (scope) => {
          addFactsetScopeToSentry(scope, {
            client: config.client,
            requestPayload: config.requestPayload,
            ticker: config.ticker,
            endpoint,
            message: `Market data error for ${config.ticker} on endpoint ${endpoint}`
          });

          return scope;
        });
        return of(config.result as Level1IntegrationEvent);
      })
    );
};

export const factsetFinalizeEndpoint = <T>(config: FactsetFinalizeConfig): OperatorFunction<T, T> => {
  const { job, client, endpoint, ticker } = config;
  return (source$) =>
    source$.pipe(
      finalize(() => {
        if (job.idJob) {
          client
            .unobserveEndpoint(job.idJob)
            .then(() => {})
            .catch((e) => {
              Sentry.captureException(e, (scope) => {
                addFactsetScopeToSentry(scope, {
                  client,
                  ticker,
                  endpoint,
                  message: `Finalize error for ticker ${ticker} on endpoint ${endpoint}`
                });

                return scope;
              });

              logger.error(e);
            });
        }
      })
    );
};

export const logFactsetResponse = <T>(
  endpoint: FactsetEndpoints,
  config: EndpointFilter | undefined
): OperatorFunction<T, T> => {
  return (obs$) =>
    obs$.pipe(
      tap(() => {
        evaluateEndpointFilter(endpoint, config, () => {});
      })
    );
};

export const evaluateEndpointFilter = (
  endpoint: FactsetEndpoints,
  config: EndpointFilter | undefined,
  fn: () => void
) => {
  if (config === true || (Array.isArray(config) && config.includes(endpoint))) {
    fn();
  }
};

const TickDirectionMap: { [key: number]: TickDirection } = {
  0: TickDirection.NoTick,
  1: TickDirection.UpTick,
  2: TickDirection.DownTick,
  3: TickDirection.UpUnchanged,
  4: TickDirection.DownUnchanged,
  9: TickDirection.EndOfDay
};

const isValidTickDirection = (value: number): value is keyof typeof TickDirectionMap => {
  return value in TickDirectionMap;
};

export const mapTickDirection = (tickValue?: unknown): TickDirection => {
  return typeof tickValue === 'number' && isValidTickDirection(tickValue)
    ? TickDirectionMap[tickValue]
    : TickDirection.NoTick;
};

export const calculateMidPrice = (e: Level1IntegrationEvent) => {
  const tickSize = e.tickSize || 0;
  const bidPrice = e.bidPrice || 0;
  const askPrice = e.askPrice || 0;
  const scale = getNumericScale(tickSize || bidPrice || askPrice) || -1;
  const avgPrice = (bidPrice + askPrice) / 2;
  const midPrice = scale > 0 ? Number(avgPrice.toFixed(scale)) : avgPrice;

  return midPrice;
};

export const transformTickDirection = (e: Level1FactsetWithFallbackFields) => {
  e.askTickDirection = mapTickDirection(e.askTickDirection);
  e.bidTickDirection = mapTickDirection(e.bidTickDirection);
  e.lastTradeTickDirection = mapTickDirection(e.lastTradeTickDirection);
  e.postTradingTickDirection = mapTickDirection(e.postTradingTickDirection);
  e.preTradingTickDirection = mapTickDirection(e.preTradingTickDirection);
};

const mapPropertyIds = (propertyIds?: RawLevel1IntegrationEvent['propertyIds']) => {
  return propertyIds && !isEmpty(propertyIds) ? propertyIds.map((item) => item?.id) : [];
};

export const transformPropertyIds = (e: Level1FactsetWithFallbackFields) => {
  e.propertyIds = mapPropertyIds(e?.propertyIds as RawLevel1IntegrationEvent['propertyIds']);
  e.preTradingPropertyIds = mapPropertyIds(
    e.preTradingPropertyIds as RawLevel1IntegrationEvent['preTradingPropertyIds']
  );
  e.postTradingPropertyIds = mapPropertyIds(
    e.postTradingPropertyIds as RawLevel1IntegrationEvent['postTradingPropertyIds']
  );
};

export const transformTradeConditions = (e: Level1IntegrationEvent) => {
  const lastTradeCondition = e?.lastTradeCondition as RawLevel1IntegrationEvent['lastTradeCondition'];
  const matchByPropertyIds = e.propertyIds
    ?.map((propertyId) => lastTradeCondition?.find((property) => property?.id === propertyId))
    .filter((p) => !!p)
    .map((p) => p?.shortName);

  e.lastTradeCondition = matchByPropertyIds?.join(',') || '';
};

const applyEventAndsetValueIfExists =
  (e: Level1FactsetWithFallbackFields) =>
  <T>(field: keyof Level1FactsetWithFallbackFields, value: T | undefined) => {
    if (typeof value !== 'undefined') {
      e[field] = value;
    }
  };

export const setMostRecentTradeData = (e: Level1FactsetWithFallbackFields) => {
  // return when only last trade data expected
  if (!e.preTradingDateTime && !e.postTradingDateTime) {
    return;
  }

  // even if just the dateTime was published for the last trade
  // in current trading (last) trading session, we want to show it
  const tradeData = [];
  if (e.preTradingDateTime) {
    tradeData.push({
      dateTime: e.preTradingDateTime,
      exchange: e.preTradingExchange,
      ids: e.preTradingPropertyIds,
      price: e.preTradingPrice,
      size: e.preTradingSize,
      tickDirection: e.preTradingTickDirection
    });
  }
  if (e.lastTradeDateTime) {
    tradeData.push({
      dateTime: e.lastTradeDateTime,
      exchange: e.lastTradeExchange,
      ids: e.propertyIds,
      price: e.lastTradePrice,
      size: e.lastTradeSize,
      tickDirection: e.lastTradeTickDirection
    });
  }
  if (e.postTradingDateTime) {
    tradeData.push({
      dateTime: e.postTradingDateTime,
      exchange: e.postTradingExchange,
      ids: e.postTradingPropertyIds,
      price: e.postTradingPrice,
      size: e.postTradingSize,
      tickDirection: e.postTradingTickDirection
    });
  }

  const mostRecentData = tradeData.reduce((currentMostRecentData, currentData) =>
    DateTime.fromISO(currentMostRecentData.dateTime) > DateTime.fromISO(currentData.dateTime)
      ? currentMostRecentData
      : currentData
  );

  e.lastTradeDateTime = mostRecentData.dateTime;
  e.lastTradeExchange = mostRecentData.exchange;
  e.lastTradePrice = mostRecentData.price;
  e.lastTradeSize = mostRecentData.size;
  e.lastTradeTickDirection = mostRecentData.tickDirection;
  e.propertyIds = mostRecentData.ids;
};

export const applyFallbackValues = (e: Level1FactsetWithFallbackFields) => {
  const setValueIfExists = applyEventAndsetValueIfExists(e);

  // fallbacks 9:OfficialClose with 208:Trade only when all the fields are null (TKT-11600)
  const shouldFallbackCloseWithLastTrade =
    !e.officialCloseDateTime && !e.officialClosePrice && !e.officialCloseVolume;

  // fallbacks 234:ExClose with 9:OfficialClose only when all the 234:ExClose are null
  const shouldFallbackPrevExCloseWithOffClose = !e.previousExCloseDateTime && !e.previousExClosePrice;

  // fallbacks 234:ExClose with 208:Trade only when 234:ExClose AND 9:OfficialClose are null
  const shouldFallbackOffCloseWithPrevClose =
    shouldFallbackPrevExCloseWithOffClose && !e.officialCloseDateTime && !e.officialClosePrice;

  if (shouldFallbackCloseWithLastTrade) {
    setValueIfExists('closeDateTime', e.lastTradeDateTime);
    setValueIfExists('closePrice', e.lastTradePrice);
    setValueIfExists('closeVolume', e.lastTradeSize);
  }

  setValueIfExists('closeDateTime', e.officialCloseDateTime);
  setValueIfExists('closePrice', e.officialClosePrice);
  setValueIfExists('closeVolume', e.officialCloseVolume);

  if (shouldFallbackPrevExCloseWithOffClose) {
    setValueIfExists('previousCloseDateTime', e.officialCloseDateTime);
    setValueIfExists('previousClosePrice', e.officialClosePrice);
  }
  if (shouldFallbackOffCloseWithPrevClose) {
    setValueIfExists('previousCloseDateTime', e.previousCloseDateTime);
    setValueIfExists('previousClosePrice', e.previousClosePrice);
  }

  setValueIfExists('previousCloseDateTime', e.previousExCloseDateTime);
  setValueIfExists('previousClosePrice', e.previousExClosePrice);

  setValueIfExists('highDayPrice', e.officialHighDayPrice || e.highDayPrice);
  setValueIfExists('lowDayPrice', e.officialLowDayPrice || e.lowDayPrice);
  setValueIfExists('midPrice', e.midPrice || calculateMidPrice(e));
  setValueIfExists('openDateTime', e.officialOpenDateTime || e.openDateTime);
  setValueIfExists('openPrice', e.officialOpenPrice || e.openPrice);
};
