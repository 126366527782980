import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { openViewInvestorOrder } from '@app/common/types/orders/investor-order/open.view.investor-order';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';

type ViewInvestorOrderActionArgs = {
  id: string;
  side: string;
  orderType: string;
  limitPrice: string;
  totalQuantity: number;
  instrumentDisplayCode: string;
};

function isVisible(rowData: ViewInvestorOrderActionArgs[] | undefined) {
  return !!rowData && rowData.length === 1;
}

export const viewInvestorOrderOnChange = async <T extends ViewInvestorOrderActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];
  const isActionVisible = isVisible(data);

  ctx.notify({ isDisabled: !isActionVisible });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }
    const id = selectedRow.id;
    if (!id) {
      return;
    }

    await openViewInvestorOrder({
      windowId: getLeaderOrTabId(ctx.appContainer),
      dialogInfo: {
        id: selectedRow.id,
        side: selectedRow.side,
        orderType: selectedRow.orderType,
        price: selectedRow.limitPrice,
        quantity: selectedRow.totalQuantity,
        symbol: selectedRow.instrumentDisplayCode
      }
    });
  }
};

export const viewInvestorOrderAction =
  <T extends ViewInvestorOrderActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('view_investor_order')
      .toolbar((t) =>
        t
          .component('action-button')
          .id('view_investor_order_button')
          .location('HorizontalToolbarRight')
          .props({
            isDisabled: true,
            content: 'View'
          })
      )
      .customMenu((m) => m.name('View Order').visible(({ rowData }) => isVisible(rowData)))
      .onChange<ActionComponentConfig<T>>(viewInvestorOrderOnChange);
