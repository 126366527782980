import { AppDatabase } from '@app/data-access/offline/app-database';
import type { AnyRecord, Optional, Segmented } from '@oms/shared/util-types';
import type { DependencyContainer } from 'tsyringe';

export type BaseFieldType<AdditionalFields extends AnyRecord = AnyRecord> = Segmented<number> &
  AdditionalFields;

// For a given form, out of any "additional fields", return the "selected fields" that appear in the main form.
export const getSelectedAdditionalFields = async (
  formType: string | undefined,
  container: DependencyContainer
) => {
  const appDatabase = container.resolve(AppDatabase);
  const result = await appDatabase.offline.additional_fields.findOne(formType || 'unknown').exec();
  return result?.selectedFields ?? [];
};

/**
 * Extracts indices from field names.
 *
 * @example
 * ```ts
 * const fieldName = 'profiles[0].rows[1].priceFrom';
 * const indices = extractIndicesFromFieldName(fieldName); // [0, 1]
 * expect(indices).toEqual([0, 1]);
 * ```
 */
export const extractIndicesFromFieldName = (fieldName: string): number[] => {
  const pattern = /\[(\d+)\]/g;
  const parsed = fieldName.matchAll(pattern);
  return Array.from(parsed).flatMap(([_, match]) => Number.parseInt(match, 10));
};

export type PathSegment = {
  name: string;
  index?: number;
};

/**
 * Extracts path segements including names and indices from field names.
 *
 * @example
 * ```ts
 * const fieldName = 'profiles[0].rows[1].priceFrom';
 * const indices = extractFieldNamePathSegments(fieldName);
 * expect(indices).toEqual([
 *   { name: 'profiles', index: 0 },
 *   { name: 'rows', index: 1 },
 *   { name: 'priceFrom' }
 * ]);
 * ```
 * ex: 'profiles[0].rows[1].priceFrom' -> [0, 1]
 */
export const extractFieldNamePathSegments = (fieldName: string): PathSegment[] => {
  const pattern = /([A-Za-z\d\-\_]+)(?:\[(\d+)\])?/g;
  const parsed = fieldName.matchAll(pattern);
  return Array.from(parsed).flatMap(([_, name, index]) => {
    const segment: PathSegment = { name };
    if (typeof index === 'undefined') return segment;
    const parsed = Number.parseInt(index, 10);
    if (Number.isNaN(parsed)) return segment;
    segment.index = parsed;
    return segment;
  });
};

/**
 * Navigates through an object by field name path and applies an optional predicate.
 *
 * @param record - The object to navigate through.
 * @param fieldName - The field name path to navigate by.
 * @param predicate - An optional predicate to apply to the value at the field name path.
 * @returns The value at the field name path if it matches the predicate, otherwise undefined.
 *
 * @example
 * ```ts
 * const data = {
 *   profiles: [
 *     {
 *       rows: [
 *         { from: 0, to: 2 },
 *         { from: 2 }
 *       ]
 *     }
 *   ]
 * };
 * const value = navigateByFieldName(data, 'profiles[0].rows[1].from', value => typeof value === 'number');
 * expect(value).toBe(2);
 * ```
 */
export function navigateByFieldName<T extends Record<string, unknown>, Expected = unknown>(
  record: T,
  fieldName: string,
  predicate?: (value: unknown) => value is Expected
): Optional<Expected>;

/**
 * Navigates through an object by field name path and applies an optional predicate.
 *
 * @param record - The object to navigate through.
 * @param path - An arrray of path segments to navigate by.
 * @param predicate - An optional predicate to apply to the value at the field name path.
 * @returns The value at the field name path if it matches the predicate, otherwise undefined.
 *
 * @example
 * ```ts
 * const data = {
 *   profiles: [
 *     {
 *       rows: [
 *         { from: 0, to: 2 },
 *         { from: 2 }
 *       ]
 *     }
 *   ]
 * };
 * const value = navigateByFieldName(data, [{ name: 'profiles', index: 0 }, { name: 'rows', index: 1 }, { name: 'from' }], value => typeof value === 'number');
 * expect(value).toBe(2);
 * ```
 */
export function navigateByFieldName<T extends Record<string, unknown>, Expected = unknown>(
  record: T,
  path: PathSegment[],
  predicate?: (value: unknown) => value is Expected
): Optional<Expected>;

// Implementation only
export function navigateByFieldName<T extends Record<string, unknown>, Expected = unknown>(
  allValues: T,
  fieldNameOrPath: string | PathSegment[],
  predicate?: (value: unknown) => value is Expected
): Optional<Expected> {
  const path =
    typeof fieldNameOrPath === 'string' ? extractFieldNamePathSegments(fieldNameOrPath) : fieldNameOrPath;
  const extracted = path.reduce(
    (result, { name, index }) => {
      if (typeof result === 'undefined') {
        return undefined;
      }
      const value = typeof result === 'object' ? result[name] : result;
      return Array.isArray(value) && typeof index === 'number' ? value[index] : value;
    },
    structuredClone(allValues) as Record<string, unknown> | undefined
  );
  if (!predicate) return extracted as Expected;
  return predicate(extracted) ? (extracted as Expected) : undefined;
}
