import { SplitButtonAction } from './split.button.action';
import type { ICellRendererParams } from '@ag-grid-community/core';
import type { FC } from 'react';
import type { SplitButtonActionProps } from './split.button.action';
import type { ActionComponentProps, InlineActionComponentProps } from '../../../models/actions.model';
import { VStack } from '@oms/shared-frontend/ui-design-system';

export const INLINE_SPLIT_BUTTON_ACTION = 'inline-split-button-action';

export const InlineSplitButtonAction: FC<
  ICellRendererParams<any, any> &
    InlineActionComponentProps<SplitButtonActionProps<any> & ActionComponentProps<any>>
> = ({ node, action, value }) => {
  return (
    <VStack justify={'center'} align={'flex-end'} style={{ height: '100%' }}>
      <SplitButtonAction {...action} value={value} instanceId={node.id} rowData={node.data} />
    </VStack>
  );
};
