import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export enum RepairQueueTab {
  INVESTOR_ORDERS = 'repair-investor-orders',
  TRADES = 'repair-trades'
}

export default createAppVersionedSchema('REPAIR_QUEUE', {
  version: 0,
  schema: z.object({
    tab: z.enum([RepairQueueTab.INVESTOR_ORDERS, RepairQueueTab.TRADES]).optional().describe('Layout tab ID'),
    autoCloseOnEmpty: z.boolean().optional().describe('Close window when grid is empty')
  })
});
