import { sharedDefaultCol } from '@app/common/grids/columns/generic-cols';
import { ColumnBuilderCallback, ColumnLibrary, CellBadgeClassEnum } from '@oms/frontend-vgrid';
import { sharedDateTimeCol, sharedTextCol, sharedUserCol } from '@app/common/grids/columns/generic-cols';
import {
  sharedOrderEntryTypeCol,
  sharedOrderStatusWithoutMapperCol
} from '@app/common/grids/columns/order-cols';
import { TradingOrderStatus } from '@oms/generated/frontend';
import { mapTradingOrderStatus } from '@app/common/mappers/map-trading-order-status';
import type { OrderAuditTrail } from '@app/common/types/orders/orders.types';

const defaultAuditTrailCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedDefaultCol<OrderAuditTrail>(c).minWidth(180);

const eventTimestampCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedDateTimeCol(c, 'eventTimestamp').header('Timestamp').sort('desc').minWidth(200);

const eventCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedTextCol(c, 'event').header('Event').width(220);

const sourceCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedOrderEntryTypeCol(c).header('Source').width(140);

const enteredByCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedUserCol(c, 'user').header('Entered By').width(200);

const orderStatusCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedOrderStatusWithoutMapperCol(c)
    .width(200)
    .header('Order Status')
    .filter('agSetColumnFilter')
    .filterParams<TradingOrderStatus>({
      values: Object.values(TradingOrderStatus),
      valueFormatter: ({ value }) => mapTradingOrderStatus(value)
    })
    .cell((c) => c.badge(CellBadgeClassEnum.Capital, (data) => mapTradingOrderStatus(data?.status)));

const rejectReasonCol: ColumnBuilderCallback<OrderAuditTrail> = (c) =>
  sharedTextCol(c, 'rejectReason').header('Reason').width(220);

export const auditTrailColumnLibrary: ColumnLibrary<OrderAuditTrail> = {
  defaultColumn: defaultAuditTrailCol,
  columns: [eventTimestampCol, eventCol, sourceCol, enteredByCol, orderStatusCol, rejectReasonCol]
};
