import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('INVESTOR_ORDER_ENTRY_FORM_INPUT', {
  version: 0,
  schema: z
    .object({
      entryType: z.enum(['create', 'update', 'repair']).optional(),
      id: z.string().optional()
    })
    .describe('Investor order entry form input schema')
});
