import {
  ConsoleLogger,
  DeploymentStage,
  FrontgateConnectionOptions,
  FrontgateWSConnection,
  JobObservable,
  LoggerInterface,
  Mdg2Client,
  TokenAuthentication
} from '@fds/frontgate-js-sdk';
import { defer, from, of, repeat, retry, timer } from 'rxjs';
import { Observable, Subscription } from 'rxjs';
import type {
  FactsetHighLevelRequestResult,
  FactsetObserverConfig,
  FactsetRequestResult
} from './factset.types';
import * as Sentry from '@sentry/react';
import { addFactsetScopeToSentry } from './sentry.factset';

/**
 * Encapsulates Factset connection, keep alive, and polling into a single place.
 */
export class FactsetClient {
  public client: Mdg2Client;
  public connectSubscription?: Subscription;
  private logger: LoggerInterface;

  constructor(
    private url: string,
    public env: DeploymentStage,
    client: Mdg2Client,
    logger?: LoggerInterface
  ) {
    this.logger = logger || new ConsoleLogger('Factset Client');
    this.client = client;
    this.client.setLogger(this.logger);
  }

  public get connected() {
    return this.connectSubscription && !this.connectSubscription.closed;
  }

  public connect() {
    if (this.connectSubscription && !this.connectSubscription.closed) return;

    this.connectSubscription = defer(() => {
      this.logger.trace(
        `🔍 Is Factset client connected? ${this.client.isConnected} connecting? ${this.client.isConnecting}`
      );

      return !this.client.isConnected && !this.client.isConnecting ? from(this.setupConnection()) : of();
    })
      .pipe(
        repeat({
          delay: () => timer(10_000)
        }),
        retry({
          resetOnSuccess: true,
          delay: (err) => {
            const errMsg = '❌ Unable to connect to factset, retrying in 10s';
            this.logger.error(errMsg, err);
            Sentry.captureException(err, (scope) => {
              addFactsetScopeToSentry(scope, {
                client: this,
                message: errMsg
              });

              return scope;
            });
            return timer(10_000);
          }
        })
      )
      .subscribe(() => {});
  }

  private async setupConnection() {
    if (this.client.isConnected) return;

    this.logger.debug('🟡 Connecting to level 1 market data');

    const response = await fetch(this.url, {
      method: 'GET',
      mode: 'no-cors'
    });
    const { headers } = response;
    const responseToken = headers.get('frontgate-authentication-token');
    const responseHost = headers.get('frontgate-host');

    if (response.status !== 200) {
      throw new Error(`Server ${this.url} responded with code ${response.status}`);
    } else if (!responseToken || !responseHost) {
      throw new Error(`Missing factset auth token for ${this.url}`);
    } else {
      //https://endpointreference.factset.com/documentation/4-client-libraries/js-setup#frontgate-connection-options
      //
      const options: FrontgateConnectionOptions = {
        host: responseHost,
        //logger: this.logger,
        shouldReconnectOnConnectionLoss: true,
        payload_content: '',
        deployment_stage: this.env,
        maximum_idle_interval: 0,
        port: 443,
        tls: true,
        path_prefix: '',
        encoding: 'jsjson-v2',
        connectionTimeoutInMs: 1_000,
        sessionHandling: false
      };

      const auth = new TokenAuthentication(responseToken);
      const connection = new FrontgateWSConnection(auth, options);
      this.client.setConnection(connection);
      await this.client.connect();
    }
  }

  public async disconnect() {
    this.connectSubscription?.unsubscribe();
    this.connectSubscription = undefined;
    if (this.client.isConnected || this.client.isConnecting) {
      await this.client.disconnect(true);
    }
  }

  public observeEndpoint<TRequest extends Record<string, unknown>, TResponse>(
    config: FactsetObserverConfig<TRequest>
  ): JobObservable<FactsetRequestResult<TResponse>> {
    const { payload, endpoint, method } = config;
    const defer$: JobObservable<FactsetRequestResult<TResponse>> = defer(() => {
      if (this.client.isConnected) {
        const endpoint$ = this.client.observeEndpoint(method, `/api/v1${endpoint}`, payload);
        defer$.idJob &&
          this.unobserveEndpoint(defer$.idJob)
            .then(() => {})
            .catch((err) => {
              Sentry.captureException(err, (scope) => {
                addFactsetScopeToSentry(scope, {
                  message: `Unobserve factset endpoint ${endpoint}`,
                  client: this,
                  endpoint
                });

                return scope;
              });
            });
        defer$.idJob = endpoint$.idJob;
        return endpoint$;
      }
      throw new Error('Not connected to factset yet');
    });

    return defer$;
  }

  public async unobserveEndpoint(jobId: number) {
    await this.client.unobserveEndpoint(jobId);
  }

  public pollEndpoint<TRequest extends Record<string, unknown>, TResponse>(
    config: FactsetObserverConfig<TRequest> & { interval?: number }
  ): Observable<FactsetHighLevelRequestResult<TResponse>> {
    const { payload, endpoint, method, interval = 1000 } = config;

    return defer(() => {
      if (this.client.isConnected) {
        return from(this.client.requestEndpoint(method, `/api/v1${endpoint}`, payload));
      }
      throw new Error('Not connected to factset.');
    }).pipe(
      repeat({
        delay: interval
      })
    );
  }
}
