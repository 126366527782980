import type { RxCollectionCreator } from 'rxdb-v15';
import { snapshotSchema } from './collections/snapshots/snapshots.collection';
import type { SnapshotCollection } from './collections/snapshots/snapshots.collection';
import {
  WINDOW_CONTEXTS_COLLECTION_NAME,
  windowContextsSchema
} from './collections/window-contexts.collection';
import type { WindowContextsCollection } from './collections/window-contexts.collection';
import { gridTemplateSchema, GRID_TEMPLATE_COLLECTION_NAME } from './collections/grid-templates.collection';
import type { GridTemplateCollection } from './collections/grid-templates.collection';
import { COMPLETED_MIGRATIONS_COLLECTION } from './collections/completed-migrations.collection';
import type { CompletedMigrationsCollection } from './collections/completed-migrations.collection';
import { gridsSchema } from './collections/grids.collection';
import type { GridsCollection } from './collections/grids.collection';
import {
  ADDITIONAL_FIELDS_COLLECTION_NAME,
  ADDITIONAL_FIELDS_COLLECTION
} from './collections/additional-fields.collection';
import type { AdditionalFieldsCollection } from './collections/additional-fields.collection';
import { ACTION_COLLECTION } from '@app/actions/collections/action.collection';
import type { ActionsCollection } from '@app/actions/collections/action.collection';
import { COLLECTION_NAME } from '@oms/frontend-schemas';
import { MIGRATION_STRATEGIES as SNAPSHOT_MIGRATION_STRATEGIES } from './collections/snapshots/migrations';
import { USER_PREFERENCES_COLLECTION } from './collections/user-preferences.collection';
import type { UserPreferencesCollection } from './collections/user-preferences.collection';

// ------------------- OFFLINE DATABASE COLLECTIONS -------------------

export const OFFLINE_DB_COLLECTIONS_MAP: Record<string, RxCollectionCreator<any>> = {
  [ADDITIONAL_FIELDS_COLLECTION_NAME]: ADDITIONAL_FIELDS_COLLECTION,
  [GRID_TEMPLATE_COLLECTION_NAME]: {
    schema: gridTemplateSchema
  },
  [WINDOW_CONTEXTS_COLLECTION_NAME]: {
    schema: windowContextsSchema
  }
};

export type OfflineDatabaseCollections = {
  [ADDITIONAL_FIELDS_COLLECTION_NAME]: AdditionalFieldsCollection;
  [GRID_TEMPLATE_COLLECTION_NAME]: GridTemplateCollection;
  [WINDOW_CONTEXTS_COLLECTION_NAME]: WindowContextsCollection;
};

export type OfflineDatabaseCollectionType = keyof OfflineDatabaseCollections;

// ------------------- MEMORY DATABASE COLLECTIONS -------------------

export const MEMORY_DB_COLLECTIONS_MAP: Record<string, RxCollectionCreator<any>> = {
  [COLLECTION_NAME.SNAPSHOTS]: {
    schema: snapshotSchema,
    migrationStrategies: SNAPSHOT_MIGRATION_STRATEGIES
  },
  [COLLECTION_NAME.SNAPSHOTS_TEMPORARY]: {
    schema: snapshotSchema,
    migrationStrategies: SNAPSHOT_MIGRATION_STRATEGIES
  },
  [COLLECTION_NAME.ACTIONS_TEMPORARY]: ACTION_COLLECTION,
  [COLLECTION_NAME.ACTIONS]: ACTION_COLLECTION,
  [COLLECTION_NAME.GRIDS]: {
    schema: gridsSchema
  },
  [COLLECTION_NAME.GRIDS_TEMPORARY]: {
    schema: gridsSchema
  },
  [COLLECTION_NAME.COMPLETED_MIGRATIONS]: COMPLETED_MIGRATIONS_COLLECTION,
  [COLLECTION_NAME.USER_PREFERENCES]: USER_PREFERENCES_COLLECTION
};

export type MemoryDatabaseCollections = {
  [COLLECTION_NAME.SNAPSHOTS]: SnapshotCollection;
  [COLLECTION_NAME.SNAPSHOTS_TEMPORARY]: SnapshotCollection;
  [COLLECTION_NAME.GRIDS]: GridsCollection;
  [COLLECTION_NAME.GRIDS_TEMPORARY]: GridsCollection;
  [COLLECTION_NAME.ACTIONS]: ActionsCollection;
  [COLLECTION_NAME.ACTIONS_TEMPORARY]: ActionsCollection;
  [COLLECTION_NAME.COMPLETED_MIGRATIONS]: CompletedMigrationsCollection;
  [COLLECTION_NAME.USER_PREFERENCES]: UserPreferencesCollection;
};

export type MemoryDatabaseCollectionType = keyof MemoryDatabaseCollections;
