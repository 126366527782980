import { AppDatabase } from '@app/data-access/offline/app-database';
import type { MemoryDatabaseCollections } from '@app/data-access/offline/collections';
import type { GridTemplateDocument } from '@app/data-access/offline/collections/grid-templates.collection';
import type { GridsDocType, GridsDocument } from '@app/data-access/offline/collections/grids.collection';
import { getCompositeId } from '@app/data-access/offline/collections/grids.collection';
import { TEMPORARY_SNAPSHOT_ID } from '@app/data-access/offline/collections/snapshots/snapshots.collection';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class GridConfigService {
  private collections: MemoryDatabaseCollections;

  constructor(@inject(AppDatabase) private db: AppDatabase) {
    this.collections = this.db.memory;
  }
  /**
   * Query object for actions from rxdb.
   * @returns An mutable query for actions
   */
  public get grids(): AppDatabase['memoryDb']['collections']['grids'] {
    return this.db.memory.grids;
  }

  public getAll() {
    return this.collections.grids.find().exec();
  }

  public async find(
    gridId: string
  ): Promise<{ doc: GridsDocument; currentGridTemplateDoc: GridTemplateDocument | null } | null> {
    // Note, always use the temporary snapshot id for loading a grid
    // Because when loading a snapshot, we replace the snapshot grids with the temporary snapshot grids
    // This allows us to keep an auto-save that can be applied to a snapshot (if one is taken)
    // It also allows us to clean orphaned grids when a snapshot is loaded
    // thanks @doron
    const doc = await this.collections.grids_temporary
      .findOne(getCompositeId(gridId, TEMPORARY_SNAPSHOT_ID))
      .exec();

    if (!doc) {
      return null;
    }

    const currentGridTemplateDoc = (await doc.populate('currentGridTemplate')) as GridTemplateDocument | null;

    return {
      doc,
      currentGridTemplateDoc
    };
  }

  public findByWidgetActorId(widgetActorId: string) {
    return this.collections.grids.find({ selector: { widgetActorId } }).exec();
  }

  public create(options: Pick<GridsDocType, 'currentGridTemplate' | 'gridId' | 'state' | 'widgetActorId'>) {
    return this.collections.grids_temporary.upsert({
      // Note, always use the temporary snapshot id for loading a grid
      // Because when loading a snapshot, we replace the snapshot grids with the temporary snapshot grids
      // This allows us to keep an auto-save that can be applied to a snapshot (if one is taken)
      // It also allows us to clean orphaned grids when a snapshot is loaded
      // thanks @doron
      id: getCompositeId(options.gridId, TEMPORARY_SNAPSHOT_ID),
      snapshotId: TEMPORARY_SNAPSHOT_ID,
      ...options
    });
  }

  public updateDoc(doc: GridsDocument, options: Partial<GridsDocType>) {
    return doc.incrementalUpdate({
      $set: options
    });
  }

  /**
   * Updates & inserts actions into rxdb collection.
   * @param actions Actions to insert / update.
   * @returns The new / modified docs.
   */
  public async upsert(...grids: GridsDocType[]): ReturnType<typeof this.grids.bulkUpsert> {
    return await this.grids.bulkUpsert(grids);
  }

  public bulkRemove(gridIds: string[]) {
    return this.collections.grids.bulkRemove(gridIds);
  }
}
