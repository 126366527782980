import { Plugin } from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import type { Unsubscribe } from '@valstro/remote-link';
import type { ApolloClientLinkType } from '@app/data-access/api/apollo-client';
import { createOrUpdateApolloClient, createGraphQLAuthWsClient } from '@app/data-access/api/apollo-client';
import { getValidPersistedAuthState } from '@app/common/auth/auth.helpers';
import type { AuthClientState } from '@app/common/auth/keycloak.types';
import { createLogger } from '@oms/shared/util';
import type { WildcardMockLink } from 'wildcard-mock-link';
import type { DependencyContainer } from 'tsyringe';
import { DataAccessSignal } from '@app/data-access/memory/data-access.signal';
import { AuthSignal } from '@app/data-access/memory/auth.signal';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import type { BroadcastInMemoryCache } from '@app/data-access/api/broadcast-apollo-cache';

export const dataAccessPluginLogger = createLogger({ label: 'Data Access Plugin' });

export interface DataAccessPluginOptions {
  apolloMockLink?: WildcardMockLink;
  container: DependencyContainer;
}

/**
 * Manages data access
 */
export const dataAccessPlugin = ({ container, apolloMockLink }: DataAccessPluginOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-data-access-plugin',
    pluginFn: ({ workspace }) => {
      let httpSetup: ApolloHTTPSetup | undefined;
      let wsSetup: ApolloWSSetup | undefined;
      let unsubAuth: Unsubscribe | undefined;
      const dataAccessSignalService = container.resolve(DataAccessSignal);
      const authSignalService = container.resolve(AuthSignal);
      const authSignal = authSignalService.signal;
      const dataAccessSignal = dataAccessSignalService.signal;

      async function setupAuthenticatedWebSocket(auth: AuthClientState) {
        const isSuccess = auth.lastAuthClientEvent === 'onAuthSuccess';
        const hasSetupWS = !!wsSetup && !!httpSetup;
        const isUnauthenticated =
          auth.lastAuthClientEvent === 'onTokenExpired' ||
          auth.lastAuthClientEvent === 'onAuthLogout' ||
          auth.lastAuthClientEvent === 'onAuthRefreshError';
        switch (true) {
          case isSuccess && hasSetupWS === false:
            wsSetup = setupApolloClientWebSocket(httpSetup!);
            dataAccessSignal.set({
              isConnected: true,
              isReady: true
            });
            break;
          case isUnauthenticated && hasSetupWS === true:
            await wsSetup?.teardown();
            dataAccessSignal.set({
              isConnected: false,
              isReady: true
            });
        }
      }

      workspace.addHook('leaderElection', ({ isLeader }) => {
        if (unsubAuth) {
          unsubAuth();
        }

        httpSetup = httpSetup ? httpSetup : setupApolloClientHTTP(container, isLeader, apolloMockLink);

        const state = authSignal.get();
        setupAuthenticatedWebSocket(state).catch(console.error);
        unsubAuth = authSignal.subscribe((auth) => {
          setupAuthenticatedWebSocket(auth).catch(console.error);
        });
      });

      return async function unsubscribe() {
        httpSetup?.teardown();
        await wsSetup?.teardown();
        unsubAuth?.();
        dataAccessSignalService.reset();
      };
    }
  });

type ApolloHTTPSetup = {
  client: RxApolloClient;
  cache?: BroadcastInMemoryCache;
  isLeader: boolean;
  getAuthToken: () => string;
  teardown: () => void;
  apolloMockLink?: WildcardMockLink;
};

function setupApolloClientHTTP(
  container: DependencyContainer,
  isLeader: boolean,
  apolloMockLink?: WildcardMockLink
): ApolloHTTPSetup {
  const getAuthToken = () => {
    const authState = getValidPersistedAuthState();
    return authState?.token ?? '';
  };

  const linkType: ApolloClientLinkType = apolloMockLink ? 'mock' : 'auth-http';

  const client = createOrUpdateApolloClient(linkType, {
    getAuthToken,
    isLeader,
    mockLink: apolloMockLink
  });

  container.register(RxApolloClient, { useValue: client });

  dataAccessPluginLogger.log('Registered Apollo Client instance & GraphQL HTTP Client');

  return {
    client,
    isLeader,
    getAuthToken,
    apolloMockLink,
    teardown: () => {
      client.stop();
      dataAccessPluginLogger.log('Stopped Apollo Client instance & GraphQL WS Client');
    }
  };
}

type ApolloWSSetup = {
  teardown: () => Promise<void>;
};

function setupApolloClientWebSocket({
  client,
  getAuthToken,
  cache,
  isLeader,
  apolloMockLink
}: ApolloHTTPSetup): ApolloWSSetup {
  if (apolloMockLink) {
    dataAccessPluginLogger.log('Skipping setup of GraphQL WS Client due to mock link');
    return {
      teardown: async () => {
        dataAccessPluginLogger.log('GraphQL WS Client not setup, so nothing to teardown');
      }
    };
  }

  const wsClient = createGraphQLAuthWsClient(isLeader, getAuthToken);
  createOrUpdateApolloClient(
    'auth-http-ws',
    {
      getAuthToken,
      cache,
      isLeader,
      mockLink: apolloMockLink,
      graphqlWsClient: wsClient
    },
    client // Update the existing client with the new link
  );

  dataAccessPluginLogger.log('Opened WebSocket connection for GraphQL');

  return {
    teardown: async () => {
      if (wsClient) {
        await wsClient.dispose();
      }

      dataAccessPluginLogger.log('Destroyed GraphQL WS Client');
    }
  };
}
