import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

enum ICloseType {
  Visible = 1,
  Always = 2,
  Selected = 3
}

const closeType = z.any().refine((v) => Object.values(ICloseType).includes(v), {
  message: 'Invalid close type'
});

const borderLocation = z.union([
  z.literal('top'),
  z.literal('bottom'),
  z.literal('left'),
  z.literal('right')
]);

const tabLocation = z.union([z.literal('top'), z.literal('bottom')]);

const insets = z.object({
  top: z.number(),
  right: z.number(),
  bottom: z.number(),
  left: z.number()
});

const globalAttributes = z.object({
  borderAutoSelectTabWhenClosed: z.boolean().optional(),
  borderAutoSelectTabWhenOpen: z.boolean().optional(),
  borderBarSize: z.number().optional(),
  borderClassName: z.string().optional(),
  borderEnableAutoHide: z.boolean().optional(),
  borderEnableDrop: z.boolean().optional(),
  borderMinSize: z.number().optional(),
  borderSize: z.number().optional(),
  enableEdgeDock: z.boolean().optional(),
  enableRotateBorderIcons: z.boolean().optional(),
  enableUseVisibility: z.boolean().optional(),
  legacyOverflowMenu: z.boolean().optional(),
  marginInsets: insets.optional(),
  rootOrientationVertical: z.boolean().optional(),
  splitterExtra: z.number().optional(),
  splitterSize: z.number().optional(),
  tabBorderHeight: z.number().optional(),
  tabBorderWidth: z.number().optional(),
  tabClassName: z.string().optional(),
  tabCloseType: closeType.optional(),
  tabContentClassName: z.string().optional(),
  tabDragSpeed: z.number().optional(),
  tabEnableClose: z.boolean().optional(),
  tabEnableDrag: z.boolean().optional(),
  tabEnableFloat: z.boolean().optional(),
  tabEnableRename: z.boolean().optional(),
  tabEnableRenderOnDemand: z.boolean().optional(),
  tabIcon: z.string().optional(),
  tabSetAutoSelectTab: z.boolean().optional(),
  tabSetBorderInsets: insets.optional(),
  tabSetClassNameHeader: z.string().optional(),
  tabSetClassNameTabStrip: z.string().optional(),
  tabSetEnableClose: z.boolean().optional(),
  tabSetEnableDeleteWhenEmpty: z.boolean().optional(),
  tabSetEnableDivide: z.boolean().optional(),
  tabSetEnableDrag: z.boolean().optional(),
  tabSetEnableDrop: z.boolean().optional(),
  tabSetEnableMaximize: z.boolean().optional(),
  tabSetEnableSingleTabStretch: z.boolean().optional(),
  tabSetEnableTabStrip: z.boolean().optional(),
  tabSetHeaderHeight: z.number().optional(),
  tabSetMarginInsets: insets.optional(),
  tabSetMinHeight: z.number().optional(),
  tabSetMinWidth: z.number().optional(),
  tabSetTabLocation: tabLocation.optional(),
  tabSetTabStripHeight: z.number().optional()
});

const rowAttributes = z.object({
  height: z.number().optional(),
  id: z.string().optional(),
  type: z.literal('row'),
  weight: z.number().optional(),
  width: z.number().optional()
});

const tabSetAttributes = z.object({
  autoSelectTab: z.boolean().optional(),
  borderInsets: insets.optional(),
  classNameHeader: z.string().optional(),
  classNameTabStrip: z.string().optional(),
  config: z.any().optional(),
  enableClose: z.boolean().optional(),
  enableDeleteWhenEmpty: z.boolean().optional(),
  enableDivide: z.boolean().optional(),
  enableDrag: z.boolean().optional(),
  enableDrop: z.boolean().optional(),
  enableMaximize: z.boolean().optional(),
  enableSingleTabStretch: z.boolean().optional(),
  enableTabStrip: z.boolean().optional(),
  headerHeight: z.number().optional(),
  height: z.number().optional(),
  id: z.string().optional(),
  marginInsets: insets.optional(),
  minHeight: z.number().optional(),
  minWidth: z.number().optional(),
  name: z.string().optional(),
  selected: z.number().optional(),
  tabLocation: tabLocation.optional(),
  tabStripHeight: z.number().optional(),
  type: z.literal('tabset'),
  weight: z.number().optional(),
  width: z.number().optional(),
  maximized: z.boolean().optional(),
  active: z.boolean().optional()
});

const tabAttributes = z.object({
  altName: z.string().optional(),
  borderHeight: z.number().optional(),
  borderWidth: z.number().optional(),
  className: z.string().optional(),
  closeType: closeType.optional(),
  component: z.string().optional(),
  config: z.any().optional(),
  contentClassName: z.string().optional(),
  tabsetClassName: z.string().optional(),
  enableClose: z.boolean().optional(),
  enableDrag: z.boolean().optional(),
  enableFloat: z.boolean().optional(),
  enableRename: z.boolean().optional(),
  enableRenderOnDemand: z.boolean().optional(),
  floating: z.boolean().optional(),
  helpText: z.string().optional(),
  icon: z.string().optional(),
  id: z.string().optional(),
  name: z.string().optional(),
  type: z.string().optional()
});

const borderAttributes = z.object({
  autoSelectTabWhenClosed: z.boolean().optional(),
  autoSelectTabWhenOpen: z.boolean().optional(),
  barSize: z.number().optional(),
  className: z.string().optional(),
  config: z.any().optional(),
  enableAutoHide: z.boolean().optional(),
  enableDrop: z.boolean().optional(),
  minSize: z.number().optional(),
  selected: z.number().optional(),
  show: z.boolean().optional(),
  size: z.number().optional(),
  type: z.literal('border')
});

const jsonTabNode = tabAttributes;

const jsonBorderNode = borderAttributes.extend({
  location: borderLocation,
  children: z.array(jsonTabNode)
});

const jsonTabSetNode = tabSetAttributes.extend({
  active: z.boolean().optional(),
  maximized: z.boolean().optional(),
  children: z.array(jsonTabNode)
});

type RecursiveJSONRowNode = z.infer<typeof rowAttributes> & {
  children: (RecursiveJSONRowNode | z.infer<typeof jsonTabSetNode>)[];
};

const jsonRowNode: z.ZodType<RecursiveJSONRowNode> = rowAttributes.extend({
  children: z.lazy(() => z.array(z.union([jsonRowNode, jsonTabSetNode])))
});

const jsonModel = z.object({
  global: globalAttributes.optional(),
  borders: z.array(jsonBorderNode).optional(),
  layout: jsonRowNode
});

export default createAppVersionedSchema('FLEX_LAYOUT', {
  version: 0,
  schema: jsonModel
});
