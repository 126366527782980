import { ArrowUpIcon, ArrowDownIcon } from '../../../../icons/icons';

export function getTickDirection(direction: 'up' | 'down' | 'neutral') {
  switch (direction) {
    case 'up':
      return ArrowUpIcon({ sx: { color: 'Green.500', fontSize: 'largeB', marginLeft: 1.5 } });

    case 'down':
      return ArrowDownIcon({ sx: { color: 'Red.500', fontSize: 'largeB', marginLeft: 1.5 } });

    default:
      return null;
  }
}
