import {
  InvestorOrderStatus,
  OrderEntryType,
  OrderSettleType,
  OrderType,
  TimeInForce,
  TradingOrderCategory,
  TradingOrderStatus,
  VisibilityReason
} from '@oms/generated/frontend';
import { mapSettleType } from '@app/common/mappers/map-settle-type';
import { mapTradingOrderStatus } from '@app/common/mappers/map-trading-order-status';
import { mapOrderVisibilityReason } from '@app/common/mappers/map-order-visibility-reason';
import { mapTradingOrderCategory } from '@app/common/mappers/map-trading-order-category';
import type { ValueFormatterFunc, ValueFormatterParams } from '@ag-grid-community/core';
import { mapOrderType } from '@app/common/mappers/map-order-type';
import { mapOrderStatus } from '@app/common/mappers/map-order-status';
import { mapOrderEntryType } from '@app/common/mappers/map-order-entry-type';
import { format } from '@oms/shared-frontend/ui-design-system';
import { SharedNotification } from '@oms/shared/oms-common';
import { mapPrimaryAlertType } from '@app/common/mappers/map-primary-alert-type';
import { getDurationInMMSS } from '@app/forms/common/validators/validate-duration-input/validate-duration-input.validator';

const customFormatters = [
  'orderType',
  'orderEntryType',
  'timeInForce',
  'orderSettleType',
  'tradingOrderStatus',
  'investorOrderStatus',
  'visibilityReason',
  'tradingOrderCategory',
  'limitPrice',
  'primaryAlertType'
] as const;

export type CustomFormatters = (typeof customFormatters)[number];

export const isCustomFormatter = (value: string): value is CustomFormatters => {
  return customFormatters.includes(value as CustomFormatters);
};

export const timeInForce = ({
  value,
  data
}: {
  value: TimeInForce;
  data: { tifDuration: number; expiryDateTime: string };
}) => {
  switch (value) {
    case TimeInForce.Duration:
      return getDurationInMMSS(data.tifDuration) || '';
    case TimeInForce.Gtd:
      return format('datetime', data.expiryDateTime) || '';
    default:
      return value;
  }
};

const orderSettleType = ({ value }: ValueFormatterParams) => mapSettleType(value as OrderSettleType);

const orderType = ({ value }: ValueFormatterParams) => mapOrderType(value as OrderType);

const orderEntryType = ({ value }: ValueFormatterParams) => mapOrderEntryType(value as OrderEntryType);

const tradingOrderStatus = ({ value }: ValueFormatterParams) =>
  mapTradingOrderStatus(value as TradingOrderStatus);

const investorOrderStatus = ({ value }: ValueFormatterParams) => mapOrderStatus(value as InvestorOrderStatus);

const visibilityReason = ({ value }: ValueFormatterParams) =>
  mapOrderVisibilityReason(value as VisibilityReason);

const tradingOrderCategory = ({ value }: { value: TradingOrderCategory }) =>
  mapTradingOrderCategory(value as TradingOrderCategory);

const limitPrice = ({ value, data }: ValueFormatterParams) => {
  if (data.orderType === OrderType.Market) {
    return 'MARKET';
  } else {
    return format('price', value);
  }
};
const primaryAlertType = ({ value }: { value: SharedNotification.PrimaryAlertType }) =>
  mapPrimaryAlertType(value as SharedNotification.PrimaryAlertType);

export const CUSTOM_FORMATTER_REGISTRY: Record<CustomFormatters, ValueFormatterFunc> = {
  timeInForce,
  orderType,
  orderEntryType,
  orderSettleType,
  tradingOrderStatus,
  investorOrderStatus,
  visibilityReason,
  tradingOrderCategory,
  limitPrice,
  primaryAlertType
};
