import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { RepairCategory } from '@oms/generated/frontend';
// import type { IoRepairRequestFragment } from '@oms/generated/frontend';
import { openInvestorOrderEntry } from '@app/generated/sdk';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { ValstroEntitlements } from '@oms/generated/frontend';
import { Maybe } from '@oms/shared/util-types';

const categoryTitleMap: Record<RepairCategory, string> = {
  [RepairCategory.IoCreate]: 'Create',
  [RepairCategory.IoModify]: 'Modify',
  [RepairCategory.IoCancel]: '',
  [RepairCategory.TradeCreate]: '',
  [RepairCategory.TradeModify]: ''
};

type RepairInvestorOrdersActionArgs = {
  id?: Maybe<string>;
  category?: RepairCategory;
};

function isVisible(rowData: RepairInvestorOrdersActionArgs[] | undefined) {
  return !!rowData && rowData.length === 1 && !!rowData[0];
}

export const repairInvestorOrdersOnChange = async <T extends RepairInvestorOrdersActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data, workspace } = ctx;
  const selectedRow = data[0];

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change' && selectedRow?.id && selectedRow?.category) {
    const titleAction = categoryTitleMap[selectedRow.category];

    await openInvestorOrderEntry(workspace.getLeaderProcessId(), {
      title: `Repair ${titleAction} Investor Order`,
      form: {
        input: {
          entryType: 'repair',
          id: selectedRow.id
        },
        triggerValidationOnOpen: true
      }
    });
  }
};

export const repairInvestorOrdersAction =
  <T extends RepairInvestorOrdersActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('repair_investor_order')
      .toolbar((t) =>
        t
          .component('action-button')
          .id('repair_investor_order_button')
          .location('HorizontalToolbarRight')
          .props({
            isDisabled: true,
            content: 'Repair'
          })
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);

        return authService.hasEntitlement([ValstroEntitlements.RepairQueueManage]);
      })
      .customMenu((m) => m.name('Repair').visible(({ rowData }) => isVisible(rowData)))
      .onChange<ActionComponentConfig<T>>(repairInvestorOrdersOnChange);
