import { Button } from '@oms/shared-frontend/ui-design-system';
import type { ButtonProps } from '@oms/shared-frontend/ui-design-system';
import type { FC, MouseEventHandler } from 'react';
import React, { useCallback } from 'react';
import { useActionProps } from '../../../hooks/action.events.hook';
import type { ActionComponentProps } from '../../../models/actions.model';
import type { AnyRecord } from '@oms/frontend-foundation';
import { omit } from 'lodash';

export const ActionButton: FC<ActionComponentProps<AnyRecord>> = (props) => {
  const actionProps = useActionProps<any, ActionComponentProps<AnyRecord>>(props);
  const isConfig = 'config' in actionProps;
  const isVertical = actionProps.actionType.includes('Vert');

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    actionProps.onChange({
      state: actionProps,
      actionId: actionProps.id,
      lifecycle: 'change'
    });
  }, [actionProps]);

  if (actionProps.isVisible === false) {
    return null;
  }

  const isDisabled = actionProps.isDisabled || actionProps.isLoading;

  const style: React.CSSProperties | undefined = isDisabled
    ? omit(actionProps.style, 'backgroundColor', 'color')
    : actionProps.style;

  return (
    <Button
      {...(omit(
        actionProps,
        'onChange',
        'content',
        'actionType',
        'isVisible',
        'instanceId',
        'notify',
        'rowData',
        'config'
      ) as unknown as ButtonProps)}
      style={style}
      sx={{ ...actionProps.sx, overflow: 'hidden' }}
      title={typeof actionProps.content === 'string' ? String(actionProps.content) : ''}
      type="button"
      onClick={onClick}
    >
      {(isVertical || isConfig) && actionProps.isLoading ? undefined : actionProps.content}
    </Button>
  );
};
