import { useVGrid, VGrid } from '@oms/frontend-vgrid';
import { buildInstrumentsGridColmuns } from './instuments-grid.columns';
import { useService } from '@oms/frontend-foundation';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import {
  TableServerJoinedInstrument,
  TsInstrumentsWithFilterDocument,
  TsInstrumentsWithFilterSubscription
} from '@oms/generated/frontend';

export const InstrumentsGridWidgetComponent = () => {
  const datasourceService = useService(TableServerDatasourceService);

  const gridProps = useVGrid(
    'instruments',
    (builder) =>
      builder
        .tableServerColumnLibrary(buildInstrumentsGridColmuns())
        .rowSelection((c) => c.multiple())
        .datasource((d) =>
          d
            .source(
              datasourceService.getSource<TableServerJoinedInstrument, TsInstrumentsWithFilterSubscription>({
                query: TsInstrumentsWithFilterDocument,
                getData: (r) => r.tsInstrumentsWithFilter
              })
            )
            .rowId((r) => r.data.id)
            .cacheBlockSize(100)
        )

        .sideBar()
        .reactiveCustomComponents(),
    [buildInstrumentsGridColmuns, datasourceService]
  );

  return <VGrid {...gridProps} />;
};

export default InstrumentsGridWidgetComponent;
