import { InMemoryCache } from '@apollo/client';
import type { InMemoryCacheConfig, Cache, Reference } from '@apollo/client';
import { BroadcastChannel } from 'broadcast-channel';

export const broadcastApolloCacheChannel = new BroadcastChannel('apollo-cache');

export class BroadcastInMemoryCache extends InMemoryCache {
  constructor(config?: InMemoryCacheConfig) {
    super(config);

    // Subscribe to changes on the broadcast channel and update the cache
    broadcastApolloCacheChannel.onmessage = (message) => {
      if (message.type === 'update') {
        this.write(message.update, true);
      }
    };
  }

  override write(write: Cache.WriteOptions, isExternal?: boolean): Reference | undefined {
    super.write(write);

    if (!isExternal) {
      this.broadcastWrite(write);
    }

    return;
  }

  private broadcastWrite(write: Cache.WriteOptions) {
    // Broadcast the updated data to other windows
    void broadcastApolloCacheChannel.postMessage({
      type: 'update',
      update: write
    });
  }
}
