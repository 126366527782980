import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('REJECT_INVESTOR_ORDER_FORM_INPUT', {
  version: 0,
  schema: z
    .object({
      id: z.string()
    })
    .describe('Reject investor order input schema')
});
