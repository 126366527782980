import { Result } from '@oms/shared/util';
import type { Named, Optional } from '@oms/shared/util-types';
import { OrderSettingsFormValues } from '../order-settings.form-contract';
import { extractIndicesFromFieldName } from '@app/forms/common/utils/field.utils';

export const VALIDATE_ENDING_PRICE = 'validateEndingPrice';

type OrderSettingsValidationErrorReason =
  | 'invalid-field-name'
  | 'leave-blank-if-last-row'
  | 'profile-not-found'
  | 'too-low';

type DebugPayload = {
  value: number;
  fieldName: string;
  allValues?: OrderSettingsFormValues;
};

type OrderSettingsValidationError<P extends DebugPayload = DebugPayload> = {
  reason: OrderSettingsValidationErrorReason;
  payload: P;
};

const getErrorMessage = (reason: OrderSettingsValidationErrorReason): string => {
  switch (reason) {
    case 'leave-blank-if-last-row':
      return 'Leave blank if last row';
    case 'profile-not-found':
      return 'Profile not found';
    case 'too-low':
      return 'Too low';
    default:
      return 'Unknown error';
  }
};

const validate = (
  value: number,
  fieldName: string,
  allValues?: OrderSettingsFormValues
): Result<void, OrderSettingsValidationError<DebugPayload>> => {
  const [profileIndex, rowIndex] = extractIndicesFromFieldName(fieldName);

  const forReason = <A extends Record<string, unknown>>(
    reason: OrderSettingsValidationErrorReason,
    additional?: A
  ): OrderSettingsValidationError<DebugPayload & A> => ({
    reason,
    payload: { value, fieldName, allValues, ...(additional || {}) } as DebugPayload & A
  });

  if (typeof profileIndex === 'undefined' || typeof rowIndex === 'undefined') {
    return Result.failure(forReason('invalid-field-name'));
  }

  const { profiles = [] } = allValues || {};

  if (profiles.length <= profileIndex) {
    return Result.failure(forReason('profile-not-found', { profileIndex }));
  }

  const profile = profiles[profileIndex];

  if (!profile) {
    return Result.failure(forReason('profile-not-found', { profileIndex }));
  }

  const { rows = [] } = profile;

  if (rows.length <= rowIndex) {
    return Result.failure(forReason('profile-not-found', { profileIndex, rowIndex }));
  }

  const row = rows[rowIndex];

  if (!row) {
    return Result.failure(forReason('profile-not-found', { profileIndex, rowIndex }));
  }

  const { from = 0 } = row;

  const isLastRow = rowIndex === rows.length - 1;

  if (isLastRow && typeof value === 'number') {
    return Result.failure(forReason('leave-blank-if-last-row', { profileIndex, rowIndex, row, isLastRow }));
  }

  if (!isLastRow && typeof value === 'undefined') {
    return Result.failure(forReason('too-low', { profileIndex, rowIndex, row, isLastRow }));
  }

  if (!isLastRow && typeof value === 'number' && value === -1) {
    return Result.failure(forReason('too-low', { profileIndex, rowIndex, row, isLastRow }));
  }

  if (from && value <= from) {
    return Result.failure(forReason('too-low', { profileIndex, rowIndex, row, isLastRow, from }));
  }

  return Result.success(undefined);
};

export const validateEndingPrice =
  () =>
  (value: number, allValues?: OrderSettingsFormValues, meta?: Partial<Named>): Optional<string> => {
    const { name: fieldName = '' } = meta || {};
    const result = validate(value, fieldName, allValues);

    return result.mapTo(
      () => undefined,
      ({ reason }) => getErrorMessage(reason)
    );
  };
