import type { DependencyContainer } from 'tsyringe';
import { OrderSettleType, RepairCategory } from '@oms/generated/frontend';
import type { TradeInfoFragment, Trade, TradeRepairRequestFragment } from '@oms/generated/frontend';
import { TradesService } from '@app/data-access/services/trading/trades/trades.service';
import TradeRepairRequestService from '@app/data-access/services/trading/repair-requests/trade-repair-requests.service';
import type { TradeModifyFormInfo, TradeModifyInput } from './trade-modify.form-common';
import type { TradeModifyValues } from './trade-modify.form-contract';
import { getTradeFromRepairEvent, getTradingNodeFromRepairEvent } from './trade-modify.form-utils';

export async function getTradeInfo(
  input: TradeModifyInput,
  container: DependencyContainer
): Promise<TradeModifyFormInfo> {
  if (!input.id) {
    throw new Error('Trade ID is required');
  }

  if (!input.entryType) {
    throw new Error('Entry type is required');
  }

  const tradesService = container.resolve(TradesService);
  const repairService = container.resolve(TradeRepairRequestService);

  switch (input.entryType) {
    case 'trade-modify': {
      const response = await tradesService.getById(input.id);
      if (response.isFailure()) {
        throw new Error('Failed to get trade');
      }

      if (response.isSuccess() && response.value.data.trade) {
        return {
          type: 'trade-modify',
          trade: response.value.data.trade
        };
      }
      break;
    }
    case 'trade-repair': {
      const repairResponse = await repairService.getTradeRepairRequestById(input.id);
      if (repairResponse.isFailure()) {
        throw new Error('Failed to get trade repair');
      }

      if (repairResponse.isSuccess() && repairResponse.value.data.getTradeRepairRequestById) {
        const tradeRepair = repairResponse.value.data.getTradeRepairRequestById;
        const tradeData: Trade =
          tradeRepair.category === RepairCategory.TradeCreate
            ? getTradeFromRepairEvent(tradeRepair)
            : getTradingNodeFromRepairEvent(tradeRepair);

        return {
          type: 'trade-repair',
          tradeRepair,
          tradeData
        };
      }
      break;
    }
    default:
      throw new Error('Invalid entry type');
  }

  throw new Error('Trade information could not be retrieved');
}

export function getInitialCommonFormValues(trade: TradeInfoFragment | Trade): Partial<TradeModifyValues> {
  const initialFormValues: Partial<TradeModifyValues> = {};
  const tradingAccount = trade.tradingOrder?.tradingAccount;

  initialFormValues.side = trade.side;
  initialFormValues.instrument = trade.instrument
    ? {
        id: trade.instrument.id
      }
    : undefined;
  initialFormValues.tradeContraAccountId = tradingAccount
    ? {
        id: tradingAccount.id
      }
    : undefined;
  initialFormValues.tradeCounterpartyId = trade.tradeCounterpartyId
    ? {
        id: trade.tradeCounterpartyId
      }
    : undefined;
  initialFormValues.tradePrice = trade.tradePrice || undefined;
  initialFormValues.tradeQuantity = trade.tradeQuantity || undefined;
  initialFormValues.tradeDateTime = trade.createdDateTime || undefined;
  initialFormValues.settleDate = trade.settleDate;
  initialFormValues.settleType = OrderSettleType.Future;
  initialFormValues.tradeTags = undefined;

  return initialFormValues;
}

export function getInitialRepairOnlyFormValues(
  tradeRepair: TradeRepairRequestFragment,
  trade: Trade
): Partial<TradeModifyValues> {
  const initialFormValues: Partial<TradeModifyValues> = {};
  const { settleDate } = trade;

  initialFormValues.instrument = tradeRepair.tradeInstrument
    ? {
        id: tradeRepair.tradeInstrument.id
      }
    : undefined;
  initialFormValues.tradeContraAccountId = tradeRepair.tradeAccount
    ? {
        id: tradeRepair.tradeAccount.id
      }
    : undefined;
  initialFormValues.tradeCounterpartyId = tradeRepair.tradeCounterparty
    ? {
        id: tradeRepair.tradeCounterparty.id || ''
      }
    : undefined;
  initialFormValues.tradeDateTime = trade.tradeDateTime || undefined;
  initialFormValues.settleDate = settleDate;
  initialFormValues.settleType = OrderSettleType.Future;
  if (tradeRepair.tradeQuantity != undefined) {
    initialFormValues.tradeQuantity = tradeRepair.tradeQuantity;
  }
  if (tradeRepair.tradePrice != undefined) {
    initialFormValues.tradePrice = tradeRepair.tradePrice;
  }
  return initialFormValues;
}
