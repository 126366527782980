import { FieldContract } from '@oms/frontend-foundation';
import type { OrderSizeProfile } from '@app/data-access/offline/collections/user-preferences.collection';
import { getFieldsFromContract } from '../../user-preferences.util';
import { orderSizeRowContract } from './order-settings.row.form-contract';
import { INIT_PRICE_ROWS } from './actions/init-price-rows.action';

const fc = FieldContract.create<OrderSizeProfile>();

export const profileIdField = fc.field('id', 'text-field').options({
  isVisible: false
});

export const descriptionField = fc.field('description', 'text-field').options({
  label: 'Description',
  actions: {
    resolveProps: [INIT_PRICE_ROWS]
  },
  validate: [{ type: 'required' }]
});

export const rowsField = fc.field('rows', 'field-array').options({
  form: {
    fields: getFieldsFromContract(orderSizeRowContract)
  }
});
