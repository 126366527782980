import {
  createPriceFrom,
  createPriceTo,
  createOrderSize
} from '@app/forms/common/fields/price-range/price-range.fields';
import type { OrderSizeRow } from '@app/data-access/offline/collections/user-preferences.collection';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';
import { FieldContract } from '@oms/frontend-foundation';
import { VALIDATE_ENDING_PRICE } from './validators/validate-price-to-value.validator';
import { SET_STARTING_PRICE } from './actions/set-price-from-value.action';
import { ADD_PRICE_ROW } from './actions/add-price-row.action';

const fc = FieldContract.create<OrderSizeRow, AdditionalFormFieldUnion>();

export const idField = fc.field('id', 'text-field').options({
  isVisible: false
});

export const fromField = createPriceFrom(fc, 'from', {
  format: 'price',
  actions: {
    resolveProps: [SET_STARTING_PRICE]
  }
});

export const toField = createPriceTo(fc, 'to', {
  format: 'price',
  validate: [{ type: VALIDATE_ENDING_PRICE }],
  actions: {
    resolveProps: [ADD_PRICE_ROW]
  }
});

export const orderSizeField = createOrderSize(fc, 'orderSize', {
  validate: [{ type: 'required' }, { type: 'min-number-value', value: 1, message: 'Min 1' }]
});
