import { Lifecycle, scoped, inject } from 'tsyringe';
import type { VisibilityReason } from '@oms/generated/frontend';
import { ContextMenuService, VGridInstance } from '@oms/frontend-vgrid';
import type { EventHandler, EventSource, GridEvent, VGridContextInstance } from '@oms/frontend-vgrid';
import type { GridApi } from '@ag-grid-community/core';
import { VIS_KEY, VIS_VALUE } from './order-monitor.visibility.filters';
import type { VisKey } from './order-monitor.visibility.filters';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { CustomContextMenuService } from '@oms/frontend-vgrid';

const ORDER_VISBILITY_FIELD_NAME = 'visibilityReason';
const OWNER_ID_FIELD_NAME = 'ownerID';

export type OrderWithVisRow = {
  id: string;
  visibilityReason: string;
  ownerID: string;
};

@scoped(Lifecycle.ContainerScoped)
export class OrderMonitorVisibilityEventHander implements EventHandler<OrderWithVisRow> {
  public name = 'order-visiblity-events';
  private currentVisibilityFilter: VisKey = VIS_KEY.ALL;
  private api: GridApi<OrderWithVisRow> | undefined;
  private authService: AuthService;

  constructor(
    @inject(VGridInstance.Context) context: VGridContextInstance,
    @inject(ContextMenuService)
    private contextMenuService: ContextMenuService<OrderWithVisRow>,
    @inject(CustomContextMenuService)
    private customContextMenuService: CustomContextMenuService<OrderWithVisRow>
  ) {
    this.authService = context.parentContainer.resolve(AuthService);
  }

  public addEvents(eventSource: EventSource<keyof GridEvent, OrderWithVisRow>): void {
    eventSource.add('onGridReady', ({ api }) => {
      this.api = api;
      this.renderVisibilityContextMenuItems();
    });
    eventSource.add('onFilterChanged', () => {
      this.handleVisibilityFilterChange();
    });
  }

  public removeEvents(): void {}

  /**
   * Re-render the visibility context menu items
   * with the correct checked state
   * when the filter changes
   */
  private handleVisibilityFilterChange = () => {
    const api = this.assertApi();
    const currentFilters = api.getFilterModel() as {
      [ORDER_VISBILITY_FIELD_NAME]: {
        filterType: string;
        values: VisibilityReason[];
      };
      [OWNER_ID_FIELD_NAME]: {
        filterType: string;
        operator: string;
        filter?: string;
        condition1: {
          filterType: string;
          type: string;
          filter?: string;
        };
        condition2: {
          filterType: string;
          type: string;
        };
      };
    };

    const selectedValues = currentFilters[ORDER_VISBILITY_FIELD_NAME]?.values || [];
    const hasOwnerIdCondition =
      currentFilters[OWNER_ID_FIELD_NAME]?.condition1?.filter === this.authService.getUserId() || '';
    const hasOwnerIdFilter =
      currentFilters[OWNER_ID_FIELD_NAME]?.filter === this.authService.getUserId() || '';
    const hasOwnerId = hasOwnerIdCondition || hasOwnerIdFilter;

    switch (true) {
      case hasOwnerId &&
        this.haveSameStrings(
          selectedValues,
          VIS_VALUE[VIS_KEY.MINE].map((x) => x.toString())
        ):
        this.currentVisibilityFilter = VIS_KEY.MINE;
        break;
      case this.haveSameStrings(
        selectedValues,
        VIS_VALUE[VIS_KEY.COVERAGE_TEAM_WITH_PRIMARY].map((x) => x.toString())
      ):
        this.currentVisibilityFilter = VIS_KEY.COVERAGE_TEAM_WITH_PRIMARY;
        break;
      case !hasOwnerId &&
        this.haveSameStrings(
          selectedValues,
          VIS_VALUE[VIS_KEY.COVERAGE_PRIMARY].map((x) => x.toString())
        ):
        this.currentVisibilityFilter = VIS_KEY.COVERAGE_PRIMARY;
        break;
      case this.haveSameStrings(
        selectedValues,
        VIS_VALUE[VIS_KEY.COVERAGE_TEAM].map((x) => x.toString())
      ):
        this.currentVisibilityFilter = VIS_KEY.COVERAGE_TEAM;
        break;
      case this.haveSameStrings(
        selectedValues,
        VIS_VALUE[VIS_KEY.ALL].map((x: string) => x.toString())
      ):
        this.currentVisibilityFilter = VIS_KEY.ALL;
        break;
      default:
        this.currentVisibilityFilter = VIS_KEY.ALL;
        break;
    }

    this.renderVisibilityContextMenuItems();
  };

  /**
   * Render the visibility context menu items
   * with the correct checked state
   */
  private renderVisibilityContextMenuItems() {
    this.customContextMenuService.upsertTabNameOrder([{ tabName: 'Visibility', order: 1000 }]);
    const checkedKey = this.currentVisibilityFilter;
    this.customContextMenuService.upsert([
      {
        // If the ownerId filter is set to the logged in user AND visibility is set to Owner, then we know it's the "My Orders" filter
        isChecked: checkedKey === VIS_KEY.MINE,
        id: 'vis-my-orders',
        label: 'My Orders',
        onClick: () => this.selectedVisibilityFilter(VIS_KEY.MINE),
        tabName: 'Visibility'
      },
      {
        isChecked: checkedKey === VIS_KEY.COVERAGE_PRIMARY,
        id: 'vis-primary-coverage-orders',
        label: 'Primary Coverage',
        onClick: () => this.selectedVisibilityFilter(VIS_KEY.COVERAGE_PRIMARY),
        tabName: 'Visibility'
      },
      {
        isChecked: checkedKey === VIS_KEY.COVERAGE_TEAM,
        id: 'vis-team-coverage-orders',
        label: 'Team Coverage',
        onClick: () => this.selectedVisibilityFilter(VIS_KEY.COVERAGE_TEAM),
        tabName: 'Visibility'
      },
      {
        isChecked: checkedKey === VIS_KEY.COVERAGE_TEAM_WITH_PRIMARY,
        id: 'vis-team-coverage-orders-with-primary',
        label: 'Team Coverage (Inc. Primary)',
        onClick: () => this.selectedVisibilityFilter(VIS_KEY.COVERAGE_TEAM_WITH_PRIMARY),
        tabName: 'Visibility'
      },
      {
        isChecked: checkedKey === VIS_KEY.ALL,
        id: 'vis-all-orders',
        label: 'All Orders',
        onClick: () => this.selectedVisibilityFilter(VIS_KEY.ALL),
        tabName: 'Visibility'
      }
    ]);

    this.contextMenuService.upsert([
      {
        id: 'visibility',
        pinToBottom: true,
        callback: () => ({
          name: 'Visibility',
          subMenu: [
            {
              // If the ownerId filter is set to the logged in user AND visibility is set to Owner, then we know it's the "My Orders" filter
              checked: checkedKey === VIS_KEY.MINE,
              name: 'My Orders',
              action: () => this.selectedVisibilityFilter(VIS_KEY.MINE)
            },
            {
              checked: checkedKey === VIS_KEY.COVERAGE_PRIMARY,
              name: 'Primary Coverage',
              action: () => this.selectedVisibilityFilter(VIS_KEY.COVERAGE_PRIMARY)
            },
            {
              checked: checkedKey === VIS_KEY.COVERAGE_TEAM,
              name: 'Team Coverage',
              action: () => this.selectedVisibilityFilter(VIS_KEY.COVERAGE_TEAM)
            },
            {
              checked: checkedKey === VIS_KEY.COVERAGE_TEAM_WITH_PRIMARY,
              name: 'Team Coverage (Inc. Primary)',
              action: () => this.selectedVisibilityFilter(VIS_KEY.COVERAGE_TEAM_WITH_PRIMARY)
            },
            {
              checked: checkedKey === VIS_KEY.ALL,
              name: 'All Orders',
              action: () => this.selectedVisibilityFilter(VIS_KEY.ALL)
            }
          ]
        })
      }
    ]);
  }

  /**
   * Update the visibility filter in Ag grid.
   * @param selectedKey
   */
  private selectedVisibilityFilter(selectedKey: VisKey) {
    const api = this.assertApi();
    this.currentVisibilityFilter = selectedKey;
    this.renderVisibilityContextMenuItems();
    const currentFilters = api.getFilterModel();

    api.setFilterModel({
      ...currentFilters,
      [ORDER_VISBILITY_FIELD_NAME]:
        selectedKey === VIS_KEY.ALL
          ? undefined
          : {
              type: 'set',
              values: VIS_VALUE[selectedKey]
            },
      [OWNER_ID_FIELD_NAME]:
        selectedKey !== VIS_KEY.MINE
          ? undefined
          : {
              filterType: 'text',
              operator: 'OR',
              condition1: {
                filterType: 'text',
                type: 'equals',
                filter: this.authService.getUserId() || ''
              },
              condition2: {
                filterType: 'text',
                type: 'blank'
              }
            }
    });
  }

  private assertApi(): GridApi<OrderWithVisRow> {
    if (!this.api) {
      throw new Error('Grid API is not available');
    }
    return this.api;
  }

  private haveSameStrings(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  }
}
