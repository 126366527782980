import {
  agGridTextFilterSchema,
  agGridNumberFilterSchema,
  agGridDateFilterSchema,
  agGridSetFilterSchema,
  agGridFiltersModelSchema,
  agGridSortModelchema,
  agGridCustomDateFilterSchema,
  agGridRelationFilterSchema
} from './ag-grid.filters.schema';
import type {
  AgGridFilterModelBase,
  AgGridDateFilter,
  AgGridNumberFilter,
  AgGridSetFilter,
  AgGridTextFilter,
  AgGridCustomDateFilter,
  AgGridRelationFilter
} from './ag-grid.filters.schema';
import type { AnyRecord } from '@oms/frontend-foundation';

// ------------ FILTER MODEL --------------------------------------------------------
// ----------------------------------------------------------------------------------

function Get(key: string): string {
  const [first, ...rest] = key.split('.');
  return `Get('${first}')${rest.map((k) => `.${k}`).join('')}`;
}

export function transformCondition(fieldKey: string, condition: AgGridFilterModelBase): string {
  switch (condition.filterType) {
    case 'text':
      return transformTextFilter(fieldKey, agGridTextFilterSchema.parse(condition));
    case 'number':
      return transformNumberFilter(fieldKey, agGridNumberFilterSchema.parse(condition));
    case 'date':
      return transformDateFilter(fieldKey, agGridDateFilterSchema.parse(condition));
    case 'customDate':
      return transformCustomDateFilter(fieldKey, agGridCustomDateFilterSchema.parse(condition));
    case 'set':
      return transformSetFilter(fieldKey, agGridSetFilterSchema.parse(condition));
    case 'relation':
      return transformRelationFilter(fieldKey, agGridRelationFilterSchema.parse(condition));
    default:
      throw new Error('Unknown filter type');
  }
}

export function transformTextFilter(fieldKey: string, condition: AgGridTextFilter): string {
  if (!condition.filter && typeof condition.filter !== 'number') {
    return `${Get(fieldKey)} == nil`;
  }

  switch (condition.type) {
    case 'equals':
      return `lower(${Get(fieldKey)}) == lower('${condition.filter}')`;
    case 'notEqual':
      return `lower(${Get(fieldKey)}) != lower('${condition.filter}')`;
    case 'contains':
      return `lower(${Get(fieldKey)}) contains lower('${condition.filter}')`;
    case 'notContains':
      return `not (lower(${Get(fieldKey)}) contains lower('${condition.filter}'))`;
    case 'startsWith':
      return `lower(${Get(fieldKey)}) startsWith lower('${condition.filter}')`;
    case 'endsWith':
      return `lower(${Get(fieldKey)}) endsWith lower('${condition.filter}')`;
    case 'blank':
      return `lower(${Get(fieldKey)}) == nil`;
    case 'notBlank':
      return `lower(${Get(fieldKey)}) != nil`;
    default:
      throw new Error('Unknown text filter type');
  }
}

export function transformNumberFilter(fieldKey: string, condition: AgGridNumberFilter): string {
  if (!condition.filter && typeof condition.filter !== 'number') {
    if (condition.type === 'notBlank') {
      return `${Get(fieldKey)} != nil`;
    }
    return `${Get(fieldKey)} == nil`;
  }
  switch (condition.type) {
    case 'equals':
      return `${Get(fieldKey)} == ${condition.filter}`;
    case 'notEqual':
      return `${Get(fieldKey)} != ${condition.filter}`;
    case 'lessThan':
      return `${Get(fieldKey)} < ${condition.filter}`;
    case 'lessThanOrEqual':
      return `${Get(fieldKey)} <= ${condition.filter}`;
    case 'greaterThan':
      return `${Get(fieldKey)} > ${condition.filter}`;
    case 'greaterThanOrEqual':
      return `${Get(fieldKey)} >= ${condition.filter}`;
    case 'inRange':
      if (!condition.filterTo) {
        throw new Error('condition.filterTo is required if condition.type is "inRange"');
      }
      return `(${Get(fieldKey)} >= ${condition.filter}) and (${Get(fieldKey)} <= ${condition.filterTo})`;
    case 'blank':
      return `${Get(fieldKey)} == nil`;
    case 'notBlank':
      return `${Get(fieldKey)} != nil`;
    default:
      throw new Error('Unknown number filter type');
  }
}

export function transformDateFilter(fieldKey: string, condition: AgGridDateFilter): string {
  if (!condition.dateFrom) {
    if (condition.type === 'notBlank') {
      return `${Get(fieldKey)} != nil`;
    }
    return `${Get(fieldKey)} == nil`;
  }
  const dateFrom = `date("${condition.dateFrom}")`;
  const dateTo = condition.dateTo ? `date("${condition.dateTo}")` : null;

  switch (condition.type) {
    case 'equals':
      return `${Get(fieldKey)} == ${dateFrom}`;
    case 'notEqual':
      return `${Get(fieldKey)} != ${dateFrom}`;
    case 'lessThan':
      return `${Get(fieldKey)} < ${dateFrom}`;
    case 'lessThanOrEqual':
      return `${Get(fieldKey)} <= ${dateFrom}`;
    case 'greaterThan':
      return `${Get(fieldKey)} > ${dateFrom}`;
    case 'greaterThanOrEqual':
      return `${Get(fieldKey)} >= ${dateFrom}`;
    case 'inRange':
      if (!dateTo) {
        throw new Error('condition.dateTo is required if condition.type is "inRange"');
      }
      return `(${Get(fieldKey)} >= ${dateFrom}) and (${Get(fieldKey)} <= ${dateTo})`;
    case 'blank':
      return `${Get(fieldKey)} == nil`;
    case 'notBlank':
      return `${Get(fieldKey)} != nil`;
    default:
      throw new Error('Unknown date filter type');
  }
}

const formatDate = (date: Date) => date.toISOString().split('T')[0];

const getFromToDates = (range: number) => {
  const endDate = new Date();
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - range);

  return {
    dateFrom: formatDate(startDate),
    dateTo: formatDate(endDate)
  };
};

export function transformCustomDateFilter(fieldKey: string, condition: AgGridCustomDateFilter): string {
  const buildExpression = (operator: string, date: string) =>
    `${Get(fieldKey)} ${operator} ${`date('${date}')`}`;

  // we need to use ranges for specific dates as the == operator isn't supported
  switch (condition.period) {
    case 'today': {
      const todayStart = new Date();
      const tomorrowStart = new Date(todayStart);
      tomorrowStart.setDate(todayStart.getDate() + 1);

      return `(${buildExpression('>=', formatDate(todayStart))}) and (${buildExpression('<', formatDate(tomorrowStart))})`;
    }

    case 'yesterday': {
      const yesterdayStart = new Date();
      yesterdayStart.setDate(yesterdayStart.getDate() - 1);

      const todayStart = new Date(yesterdayStart);
      todayStart.setDate(yesterdayStart.getDate() + 1);

      return `(${buildExpression('>=', formatDate(yesterdayStart))}) and (${buildExpression('<', formatDate(todayStart))})`;
    }

    case 'no-filter': {
      return '';
    }

    default:
      if (condition.period.includes('days')) {
        const days = parseInt(condition.period.split('-')[0], 10);
        const { dateFrom } = getFromToDates(days);

        const tomorrowStart = new Date();
        tomorrowStart.setDate(tomorrowStart.getDate() + 1);

        return `(${buildExpression('>=', dateFrom)}) and (${buildExpression('<', formatDate(tomorrowStart))})`;
      }

      if (condition.period === 'range' && condition.dateFrom && condition.dateTo) {
        const rangeEnd = new Date(condition.dateTo);
        rangeEnd.setDate(rangeEnd.getDate() + 1);

        return `(${buildExpression('>=', condition.dateFrom)}) and (${buildExpression('<', formatDate(rangeEnd))})`;
      }

      throw new Error('Unknown date period');
  }
}

export function transformSetFilter(fieldKey: string, condition: AgGridSetFilter): string {
  if (condition.filterType !== 'set') {
    throw new Error('Invalid set filter type');
  }
  const v = condition?.values ?? [];
  const values = v.map((value) => `"${value}"`).join(', ');
  return `${Get(fieldKey)} in [${values}]`;
}

export function transformRelationFilter(fieldKey: string, condition: AgGridRelationFilter): string {
  if (condition.filterType !== 'relation') {
    throw new Error('Invalid relation filter type');
  }
  return `'${condition.value}' in Get('${fieldKey}')`;
}

type UnknownFilters<TKey extends string, TFilter> = Partial<Record<TKey, TFilter>>;

export function agFilterModelToTableServerFilterStr<
  TKey extends string,
  TFilter extends AnyRecord = Record<string, unknown>
>(unknownFilters: UnknownFilters<TKey, TFilter>): string {
  const filters = agGridFiltersModelSchema.parse(unknownFilters);
  const expressions = Object.entries(filters).map(([fieldKey, filterModel]) => {
    if (filterModel.conditions && filterModel.conditions.length > 0) {
      const conditions = filterModel.conditions.map((cond) => transformCondition(fieldKey, cond));
      const operator = filterModel.operator === 'OR' ? 'or' : 'and';
      return `(${conditions.join(` ${operator} `)})`;
    } else {
      return transformCondition(fieldKey, filterModel);
    }
  });
  const filter = `(${expressions.filter((e) => !!e).join(' and ')})`;
  if (filter === '()') {
    return '';
  }
  return filter;
}

// ------------ SORT MODEL ----------------------------------------------------------
// ----------------------------------------------------------------------------------

export function agSortModelToTableServerSortStr(unknownSort: unknown): string {
  const sortModel = agGridSortModelchema.parse(unknownSort);

  return sortModel
    .map((sort) => {
      const direction = sort.sort === 'asc' ? 'ascending' : 'descending';
      return `${direction}('${sort.colId}')`;
    })
    .join(' | ');
}
