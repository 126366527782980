import {
  INSTRUMENT_RESTRICTION_START_DATE,
  instrumentRestrictionStartDate
} from './instrument-restriction-entry-start-date-field.action';

import {
  INSTRUMENT_RESTRICTION_END_DATE,
  instrumentRestrictionEndDate
} from './instrument-restriction-entry-end-date-field.action';

export const INSTRUMENT_RESTRICTION_ACTION_MAPPER = {
  [INSTRUMENT_RESTRICTION_START_DATE]: instrumentRestrictionStartDate,
  [INSTRUMENT_RESTRICTION_END_DATE]: instrumentRestrictionEndDate
};
