import { FormContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import type { OrderSettingsSchema } from '@app/data-access/offline/collections/user-preferences.collection';
import { createFormContractTemplateOptions } from '../../user-preferences.styling.util';
import { profilesField } from './order-settings.form-fields';

export const orderSettingsContract = FormContract.create<OrderSettingsSchema>()
  .fields({
    profiles: profilesField
  })
  .schema((f) => ({
    fields: [f.profiles]
  }))
  .template('simple', createFormContractTemplateOptions());

export type OrderSettingsContractType = typeof orderSettingsContract;
export type OrderSettingsFormValues = InferFormValuesFromFormContract<OrderSettingsContractType>;
