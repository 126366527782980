import AboutValstroWidget from '@app/widgets/system/about/about-valstro.widget';
import { MockUserSettings, MontageSettings, OrderSettings } from '@app/generated/sdk';
import type { UserPreferenceTab } from '../user-preferences.widget.config';
import { NotificationsSettingsWidget } from './notification-settings/notification-settings.widget';

export type UserPreferenceRegistryItem<ComponentType extends React.ComponentType<any>> = {
  label: string;
  component: ComponentType;
  props?: React.ComponentProps<ComponentType>;
};

interface UserPreferenceRegistry
  extends Record<UserPreferenceTab, UserPreferenceRegistryItem<React.ComponentType<any>>> {
  montage_settings: UserPreferenceRegistryItem<typeof MontageSettings>;
  market_data_settings: UserPreferenceRegistryItem<typeof MockUserSettings>;
  order_settings: UserPreferenceRegistryItem<typeof OrderSettings>;
  notification_settings: UserPreferenceRegistryItem<typeof NotificationsSettingsWidget>;
  my_profile: UserPreferenceRegistryItem<typeof MockUserSettings>;
  manage_layouts: UserPreferenceRegistryItem<typeof MockUserSettings>;
  appearance: UserPreferenceRegistryItem<typeof MockUserSettings>;
  about: UserPreferenceRegistryItem<typeof AboutValstroWidget>;
}

export const USER_PREFERENCE_REGISTRY: UserPreferenceRegistry = {
  montage_settings: {
    label: 'Montage settings',
    component: MontageSettings,
    props: {
      input: {}
    }
  },
  market_data_settings: {
    label: 'Market data settings',
    component: MockUserSettings
  },
  order_settings: {
    label: 'Order settings',
    component: OrderSettings,
    props: {
      input: {}
    }
  },
  notification_settings: {
    label: 'Notification settings',
    component: NotificationsSettingsWidget
  },
  my_profile: {
    label: 'My profile',
    component: MockUserSettings
  },
  manage_layouts: {
    label: 'Manage layouts',
    component: MockUserSettings
  },
  appearance: {
    label: 'Appearance',
    component: MockUserSettings
  },
  about: {
    label: 'About',
    component: AboutValstroWidget
  }
};
