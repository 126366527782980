import { ApolloClient } from '@apollo/client';
import type {
  FetchResult,
  NormalizedCacheObject,
  OperationVariables,
  SubscriptionOptions,
  WatchQueryOptions
} from '@apollo/client';
import { fromObservableQuery } from './from.observable.query';
import type { RxObservableQuery } from './from.observable.query';
import { fromApolloSub } from './from.apollo.sub';
import type { Observable } from 'rxjs';
import { singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class RxApolloClient extends ApolloClient<NormalizedCacheObject> {
  public rxWatchQuery<T = any, TVariables extends OperationVariables = OperationVariables>(
    options: WatchQueryOptions<TVariables, T>
  ): RxObservableQuery<T, TVariables> {
    const zenObservable = super.watchQuery<T, TVariables>(options);
    return fromObservableQuery(zenObservable);
  }

  public rxSubscribe<T = any, TVariables extends OperationVariables = OperationVariables>(
    options: SubscriptionOptions<TVariables, T>
  ): Observable<FetchResult<T>> {
    const zenSub = super.subscribe<T, TVariables>(options);
    return fromApolloSub(zenSub);
  }
}
