import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import { openConfirmation } from '@app/generated/sdk';
import type { ActionComponentConfig, ActionContext, ActionDefFactory } from '@oms/frontend-vgrid';
import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';
import { PROCESS_ID } from '@valstro/workspace';

type AcceptInvestorOrderActionArgs = {
  id: string;
  status?: string | InvestorOrderStatus;
};

function isVisible(rowData: AcceptInvestorOrderActionArgs[] | undefined) {
  const isOnlyOneRowSelected = !!rowData && rowData.length === 1;
  const selectedRow = !!rowData && rowData[0];
  return isOnlyOneRowSelected && selectedRow && selectedRow?.status === InvestorOrderStatus.Unaccepted;
}

export const acceptInvestorOrderOnChange = async <T extends AcceptInvestorOrderActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data } = ctx;

  const selectedRow = data[0];
  const isUnacceptedIO = isVisible(data);

  ctx.notify({ isDisabled: !isUnacceptedIO });

  if (lifecycle === 'change') {
    if (!selectedRow) {
      return;
    }

    const id = selectedRow.id;

    if (!id) {
      return;
    }

    const orderService = ctx.appContainer.resolve(InvestorOrdersService);

    try {
      ctx.notify({ isLoading: true, rowData: selectedRow });

      const resp = await orderService.acceptOrder(id);
      if (resp.isSuccess()) {
        ctx.notify({ isLoading: false, rowData: selectedRow });
      } else {
        const msgs = resp?.errors.map((e) => e.message).join(', ');
        await openConfirmation(ctx.workspace, PROCESS_ID.LEADER, {
          title: 'Error accepting order',
          componentProps: {
            message: `Error accepting order: ${msgs}`,
            autoClose: true,
            hideCancelButton: true,
            confirmButtonText: 'Ok'
          }
        });
        throw new Error(msgs);
      }
    } catch (e) {
      ctx.notify({ isLoading: false, rowData: selectedRow });
      console.error(e);
    }
  }
};

export const acceptInvestorOrderAction =
  <T extends AcceptInvestorOrderActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('accept_order')
      .toolbar((t) =>
        t.component('action-button').id('accept_order_button').location('HorizontalToolbarRight').props({
          isDisabled: true,
          content: 'Accept'
        })
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);

        return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
      })
      .customMenu((m) =>
        m
          .name('Accept')
          .visible(({ rowData }) => isVisible(rowData))
          .primary()
      )
      .onChange<ActionComponentConfig<T>>(acceptInvestorOrderOnChange);
