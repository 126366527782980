import type { ValidatorFunction } from '@data-driven-forms/react-form-renderer';
import type { ActionButtonLayoutValue } from './action-button-layout.field';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import omit from 'lodash/omit';
import { COMMANDS_TO_META } from '@app/actions/commands/command.registry.types';
import type { Action } from '@app/actions/types/action.types';
import { GET_ACTION_LABELS_TO_COMMANDS } from '../utils/button.action.schema';

export type ValidActionButtonLayout = {
  message: string;
};

type LayoutAction = NonNullable<ActionButtonLayoutValue['actions']>[number];
type RequiredActionProps = Pick<
  LayoutAction,
  | 'color'
  | 'commandId'
  | 'gridStateId'
  | 'id'
  | 'label'
  | 'locationId'
  | 'objectId'
  | 'order'
  | 'size'
  | 'widgetTypeId'
>;

const requiredProps: Record<keyof RequiredActionProps, true> = {
  color: true,
  id: true,
  widgetTypeId: true,
  objectId: true,
  commandId: true,
  locationId: true,
  label: true,
  size: true,
  order: true,
  gridStateId: true
};

export const validateActionButtonLayout: (opts: ValidActionButtonLayout) => ValidatorFunction =
  ({ message = 'Required' }) =>
  (value: ActionButtonLayoutValue, _allValues?: any, _meta?: object) => {
    if (!value || !value.actions?.length) return undefined;
    const { requiredFields, actions = [] } = value;

    for (let actionIdx = 0; actionIdx < actions.length; actionIdx++) {
      const action = actions[actionIdx];
      const commandMeta = COMMANDS_TO_META[GET_ACTION_LABELS_TO_COMMANDS()[action.commandId]];

      // true means that field is required.
      const commandMetaKeys: {
        [K in keyof Pick<Action, 'color' | 'size' | 'label'>]: boolean;
      } = {
        color: commandMeta ? commandMeta.allowsColor : true,
        label: commandMeta ? commandMeta.allowsLabel : true,
        size: commandMeta ? commandMeta.allowsSize : true
      };

      const shouldShowError = Object.entries(omit(action, 'allowedCommands')).some((actionProp) => {
        const [actionPropName, actionPropValue] = actionProp;
        const isMatchingRequiredFieldProp =
          (requiredFields[actionPropName as keyof ActionButtonLayoutValue['requiredFields']] &&
            actionPropValue ===
              requiredFields[actionPropName as keyof ActionButtonLayoutValue['requiredFields']]) ||
          !requiredFields[actionPropName as keyof ActionButtonLayoutValue['requiredFields']];
        const invalidRequiredField =
          commandMetaKeys[actionPropName as keyof Pick<Action, 'color' | 'size' | 'label'>] === false
            ? false
            : !!requiredProps[actionPropName as keyof RequiredActionProps] &&
              isEmpty(actionPropValue) &&
              !isNumber(actionPropValue) &&
              !isBoolean(actionPropValue);

        const showingError = invalidRequiredField || !isMatchingRequiredFieldProp;

        return showingError;
      });

      if (shouldShowError) {
        return message;
      }
    }

    return null;
  };
