import { openCancelInvestorOrderDialog } from '@app/common/types/orders/investor-order/open.cancel.investor-order';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { InvestorOrderStatus, ValstroEntitlements } from '@oms/generated/frontend';

type CancelInvestorOrderActionArgs = { id: string; status: string };

function getActiveCancelRows(rowData: CancelInvestorOrderActionArgs[]) {
  return rowData.filter((selectedRow) => selectedRow.status === InvestorOrderStatus.Active);
}

export const cancelInvestorOrderOnChange = async <T extends CancelInvestorOrderActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { lifecycle, data } = ctx;
  const activeRows = getActiveCancelRows(data);

  ctx.notify({ isDisabled: activeRows.length === 0 });

  if (lifecycle === 'change') {
    const activeIOIds = activeRows.map((row) => row.id);
    await openCancelInvestorOrderDialog({
      workspace: ctx.workspace,
      container: ctx.appContainer,
      windowId: ctx.windowActor.id,
      investorOrderIds: activeIOIds
    });
  }
};

export const cancelInvestorOrderAction =
  <T extends CancelInvestorOrderActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('cancel_investor_order')
      .toolbar((t) =>
        t
          .component('action-button')
          .id('cancel_investor_order_button')
          .location('HorizontalToolbarRight')
          .props({
            isDisabled: true,
            content: 'Cancel'
          })
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);

        return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
      })
      .customMenu((m) => m.name('Cancel').visible(({ rowData }) => getActiveCancelRows(rowData).length > 0))
      .onChange<ActionComponentConfig<T>>(cancelInvestorOrderOnChange);
