import { inject, injectable } from 'tsyringe';
import type { IServerSideGetRowsParams, RowDataUpdatedEvent } from '@ag-grid-community/core';
import type { Subscription } from 'rxjs';
import type {
  TableServerRow,
  TableServerRowSubscriptionVariables,
  TableServerSubscriptionShape
} from './table-server.datasource.contracts';
import { TableServerService } from './table-server.service';
import type { TableServerQueryOptions } from './table-server.service';
import {
  agFilterModelToTableServerFilterStr,
  agSortModelToTableServerSortStr
} from '@app/data-access/services/system/table-server/filters/ag-grid.table-server.transformer';
import { ServerSideDatasource } from '@oms/frontend-vgrid';

type TableServerDatasourceServiceOptions<
  TData extends TableServerRow,
  TSubscription extends TableServerSubscriptionShape<TData>
> = Omit<TableServerQueryOptions<TData, TSubscription>, 'variables'>;

@injectable()
export class TableServerDatasourceService {
  private querySubscriptions: Map<string, Subscription> = new Map();

  constructor(@inject(TableServerService) private tableServerService: TableServerService) {}

  public getSource<TData extends TableServerRow, TSubscription extends TableServerSubscriptionShape<TData>>(
    options: TableServerDatasourceServiceOptions<TData, TSubscription>
  ): ServerSideDatasource<TData> {
    return {
      getRows: (params: IServerSideGetRowsParams<TData>) => {
        const offset = params.request.startRow || 0;
        const endRow = params.request.endRow || 100;
        const limit = endRow - offset;
        const { filterModel, sortModel } = params.request;

        const variables: TableServerRowSubscriptionVariables = {
          filterBy: agFilterModelToTableServerFilterStr({ ...options.filter, ...filterModel }),
          sortBy: agSortModelToTableServerSortStr(sortModel),
          limit,
          offset
        };

        const key = this.getSubscriptionKey(variables);
        this.maybeResetSubscription(key);

        const subscription = this.tableServerService
          .query$<TData, TSubscription>({
            ...options,
            variables
          })
          .subscribe(({ totalCount, errors, rows }) => {
            if (errors?.length) {
              params.fail();
              console.error('Error fetching data', errors);
              return;
            }

            params.success({
              rowData: rows || [],
              rowCount: totalCount
            });

            // Dispatch a rowDataUpdated event to notify the grid action of the changes
            const updatedEvent: RowDataUpdatedEvent = {
              type: 'rowDataUpdated',
              api: params.api,
              context: {},
              columnApi: params.columnApi
            };
            params.api.dispatchEvent(updatedEvent);
          });

        this.querySubscriptions.set(key, subscription);
      },
      destroy: () => {
        this.dispose();
      }
    };
  }

  private getSubscriptionKey(vars: TableServerRowSubscriptionVariables): string {
    return `${vars.filterBy}-${vars.sortBy}-${vars.limit}-${vars.offset}`;
  }

  private maybeResetSubscription(key: string): void {
    const subscription = this.querySubscriptions.get(key);
    if (subscription) {
      subscription.unsubscribe();
      this.querySubscriptions.delete(key);
    }
  }

  private dispose(): void {
    this.querySubscriptions.forEach((sub) => sub.unsubscribe());
    this.querySubscriptions.clear();
  }
}
