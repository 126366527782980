import { mathOp } from '../number/MathOperations';

/**
 * Converts the first character of a string representation of a value to uppercase.
 *
 * @template Value - The type of the input value, which can be a string, number, or symbol.
 * @param {Value} value - The value to be converted to title case.
 * @returns {string} The title-cased string.
 */
export const toTitleCase = <Value extends string | number | symbol>(value: Value) => {
  const v = value.toString();
  return v.slice(0, 1).toUpperCase() + v.slice(1);
};

/**
 * Converts a string to camelCase.
 *
 * @param value The string to convert.
 * @returns The camelCase version of the string.
 */
export const toCamelCase = <Value extends string | number | symbol>(value: Value) => {
  const v = value.toString();
  return v.charAt(0).toLowerCase() + v.slice(1);
};

/**
 * Extending the string prototype
 * let example = 'this is a test'.toUpperSnakeCase()
 * console.log(example) = 'THIS_IS_A_TEST'
 */

declare global {
  interface String {
    toUpperSnakeCase(): string;
    toUpper(): string;
    toLower(): string;
    toTitleCase(): string;
    toCamelCase(): string;
    removeWhitespace(): string;
  }
}

/**
 * Format in UPPER_SNAKE_CASE
 */
String.prototype.toUpperSnakeCase = function () {
  return this.trim().replaceAll(/\s+/g, '_').toUpperCase();
};

/**
 * Removewhitespacefromstring
 */
String.prototype.removeWhitespace = function () {
  return this.trim().replaceAll(/\s+/g, '');
};

/**
 * Same as toUpperCase, but less to type
 */
String.prototype.toUpper = function () {
  return this.toUpperCase();
};

/**
 * Same, toLowerCase wrapper
 */
String.prototype.toLower = function () {
  return this.toLowerCase();
};

/**
 * Converts the first character of a string representation of a value to uppercase.
 */
String.prototype.toTitleCase = function () {
  return toTitleCase(this.toString());
};

/**
 * Converts a string to camelCase.
 */
String.prototype.toCamelCase = function () {
  return toCamelCase(this.toString());
};

export {};

type ConvertStringToNumberOptions = {
  thousandDelimiter?: string;
  decimalDelimiter?: string;
};

export const convertStringToNumber = (
  value: string | any,
  options?: ConvertStringToNumberOptions
): number => {
  const { thousandDelimiter, decimalDelimiter } = options || {};

  if (thousandDelimiter && decimalDelimiter) {
    const parts = value.toString().split(decimalDelimiter);
    parts[0] = parts[0].replace(new RegExp('\\' + thousandDelimiter, 'g'), '');

    return parseFloat(parts.join('.')) || value;
  }

  return typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;
};

export const convertStringToNumberAndCheckDecimalPlaces = (
  value: string | any,
  options?: ConvertStringToNumberOptions
): { numberValue: number; decimalLength: number } => {
  const { thousandDelimiter, decimalDelimiter } = options || {};

  if (thousandDelimiter && decimalDelimiter) {
    const parts = value.toString().split(decimalDelimiter);
    parts[0] = parts[0].replace(new RegExp('\\' + thousandDelimiter, 'g'), '');

    const numberValue = parseFloat(parts.join('.')) || value;
    const decimalLengthValue = parts[1].length;
    return { numberValue, decimalLength: decimalLengthValue };
  }
  const numberValue = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value;

  return { numberValue, decimalLength: mathOp.countDecimalPlaces(value.toString()) };
};

export const isNumeric = (value: string | number): boolean => {
  if (typeof value === 'string') {
    return /^-?\d+(?:\.\d+)?$/.test(value.replace(/,/g, ''));
  }

  return typeof value === 'number';
};

export function dedentString(string: string): string {
  const trimmedStr = string
    .replace(/^\n*/m, '') //  remove leading newline
    .replace(/[ \t\n]*$/, ''); // remove trailing spaces and tabs

  // fixes indentation by removing leading spaces and tabs from each line
  let indent = '';
  for (const char of trimmedStr) {
    if (char !== ' ' && char !== '\t') {
      break;
    }
    indent += char;
  }

  return trimmedStr.replaceAll(RegExp('^' + indent, 'mg'), ''); // remove indent
}

/**
 * An ES6 string tag that fixes indentation and also trims string.
 *
 * Example usage:
 * ```ts
 * const str = dedent`
 *   {
 *     test
 *   }
 * `;
 * str === "{\n  test\n}";
 * ```
 */
export function dedent(strings: ReadonlyArray<string>, ...values: ReadonlyArray<string>): string {
  let str = strings[0];

  for (let i = 1; i < strings.length; ++i) {
    str += values[i - 1] + strings[i]; // interpolation
  }
  return dedentString(str);
}
