import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Lifecycle, scoped, inject } from 'tsyringe';
import { EventHandler, GridEvent, EventSource } from '@oms/frontend-vgrid';
import { ApplyColumnStateParams, GridApi } from '@ag-grid-community/core';
import {
  NOTIFICATION_VISIBILITY,
  NOTIFICATION_VISIBILITY_VALUE,
  NotificationVisibility
} from '@app/notifications/notifications.contracts';
import type { SortType } from '../notifications.widget';
import { VGridInstance, VGridContextInstance } from '@oms/frontend-vgrid';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';

export function createNotificationsEventHandler(
  variant$: Observable<NotificationVisibility>,
  sort$: BehaviorSubject<SortType>
) {
  @scoped(Lifecycle.ContainerScoped)
  class NotificationsEventHandler implements EventHandler {
    private _api: GridApi | null = null;
    public name = 'notifications-event-handler';
    private subscriptions: Subscription[] = [];
    private authService: AuthService;

    constructor(@inject(VGridInstance.Context) context: VGridContextInstance) {
      this.authService = context.parentContainer.resolve(AuthService);
    }

    public addEvents(eventSource: EventSource<keyof GridEvent>): void {
      eventSource.add('onGridReady', (e) => {
        this._api = e.api;

        this.subscriptions.push(
          sort$.subscribe((e) => {
            this.api.resetColumnState();

            const updatedColumnState: ApplyColumnStateParams =
              e === 'priority'
                ? {
                    // When the sort order is "Priority", sort firstly by priority, secondly by createdAt.
                    state: [
                      { colId: 'priority', sort: 'asc', sortIndex: 0 },
                      { colId: 'sourceEventTimestamp', sort: 'desc', sortIndex: 1 }
                    ]
                  }
                : {
                    // When the sort order is "Event", sort firstly by priority, secondly by name, thirdly by createdAt.
                    state: [
                      { colId: 'priority', sort: 'asc', sortIndex: 0 },
                      { colId: 'name', sort: 'asc', sortIndex: 1 },
                      { colId: 'sourceEventTimestamp', sort: 'desc', sortIndex: 2 }
                    ]
                  };

            this.api.applyColumnState(updatedColumnState);
          })
        );
        this.subscriptions.push(
          variant$.subscribe((selectedKey: NotificationVisibility) => {
            const currentFilters = this.api.getFilterModel();
            this.api.setFilterModel({
              ...currentFilters,
              // Visibility is achieved via primaryAlertType: ALL_ALERT, MY_ALERT, TEAM_ALERT
              primaryAlertType:
                selectedKey === NOTIFICATION_VISIBILITY.ALL
                  ? {}
                  : {
                      filterType: 'set',
                      values: NOTIFICATION_VISIBILITY_VALUE[selectedKey]
                    }
            });
          })
        );
        // Set the addressedUserId filter once.
        if (this.authService && this.authService.getUserId()) {
          const currentFilters = this.api.getFilterModel();
          this.api.setFilterModel({
            ...currentFilters,
            isDisplayed: {
              filterType: 'set',
              values: ['true']
            },
            addressedUserId: {
              filterType: 'text',
              type: 'equals',
              filter: this.authService.getUserId()
            }
          });
        }
      });
    }

    public removeEvents(): void {
      this.subscriptions.forEach((s) => s.unsubscribe());
      this.subscriptions = [];
    }

    private get api(): GridApi {
      if (!this._api) {
        throw new Error('Grid API is not set');
      }
      return this._api;
    }
  }

  return NotificationsEventHandler;
}
