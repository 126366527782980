import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('BULK_REPAIR_TRADE_FORM_INPUT', {
  version: 0,
  schema: z
    .object({
      tradeRepairRequestIds: z.array(z.string())
    })
    .describe('Bulk repair failed trade repair requests (by providing trade IDs).')
});
