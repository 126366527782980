import { InvestorOrderStatus } from '@oms/generated/frontend';
import { agFilterModelToTableServerFilterStr } from '../system/table-server/filters/ag-grid.table-server.transformer';
import type { OrderSide, VisibleInvestorOrderFilter } from '@oms/generated/frontend';
import type { TableServerRowSubscriptionVariables } from '../system/table-server/table-server.datasource.contracts';
import type { InvestorOrderRow } from '@oms/generated/frontend';

export const getVisibleInvestorOrderFilter = (
  instrumentId: string,
  side: OrderSide,
  ownerId: string
): VisibleInvestorOrderFilter => {
  return {
    status: {
      equalTo: InvestorOrderStatus.Active
    },
    openQuantity: {
      greaterThan: 0
    },
    instrument: {
      id: {
        equalTo: instrumentId
      }
    },
    owner: {
      id: {
        equalTo: ownerId
      }
    },
    side: {
      equalTo: side
    }
  };
};

export const getInvestorOrderFilter = (
  instrumentId: string,
  side: string,
  ownerId: string
): TableServerRowSubscriptionVariables['filterBy'] => {
  return agFilterModelToTableServerFilterStr<keyof InvestorOrderRow>({
    status: {
      filterType: 'text',
      type: 'equals',
      filter: InvestorOrderStatus.Active
    },
    openQuantity: {
      filterType: 'number',
      type: 'greaterThan',
      filter: 0
    },
    instrument: {
      filterType: 'text',
      type: 'equals',
      filter: instrumentId
    },
    ownerID: {
      filterType: 'text',
      type: 'equals',
      filter: ownerId
    },
    side: {
      filterType: 'text',
      type: 'equals',
      filter: side
    }
  });
};
