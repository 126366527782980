import type { ComponentType } from 'react';
import { withUpdatedData } from './with-updated-data';
import type { UpdatedData, UpdatedDataProps } from './with-updated-data';
import { TradingOrdersSubscriptionService } from '@app/data-access/services/trading/trading-orders/trading-orders.subscriptions.service';
import type { TradingOrderRow } from '@oms/generated/frontend';

export type UpdatedTradingOrder = UpdatedData<TradingOrderRow>;

export const withUpdatedTradingOrder = (
  Component: ComponentType<UpdatedTradingOrder>,
  props: UpdatedDataProps = {}
) => {
  return withUpdatedData<TradingOrderRow>(Component, TradingOrdersSubscriptionService, props);
};
