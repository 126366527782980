import { isNumeric } from '../strings';
import { isDefined } from '../vars';
import {
  numericFormatTypes,
  type FormatType,
  type FormatterFunction,
  type FormatterFunctionParams,
  type NumericFormatType
} from './types';
import makeFormatEmailAddress from './email-address.formatter';
import makePhoneNumberFormatter from './phone-number.formatter';
import autoFormatLabelFromSnakeCaseValue from './auto-label-from-snake-case-value.formatter';
import {
  formatDate,
  formatDatetime,
  formatNumber,
  formatOrderPrice,
  formatPositionNumber,
  formatPositionPrice,
  formatPrice,
  formatQuantity,
  formatTime12,
  formatTradePrice,
  formatPercentage,
  formatTime,
  formatDecimalTwoFour,
  formatPositionPriceBasedOnOne,
  formatPriceChange,
  formatDecimalToPercentage,
  formatSegmentedRangeNumber,
  formatDecimal,
  formatText
} from './formatters';
import { isValidDate } from '@oms/shared/util-date';
import type { Optional } from '@oms/shared/util-types';
import { getNumberOfDecimalPlaces, withDelimiters } from './utils';

const dateTimeFormatTypes = new Set<FormatType>(['date', 'time', 'datetime']);

export const isNumericFormat = (type: FormatType) => numericFormatTypes.includes(type as NumericFormatType);
export const isDateTimeFormat = (type: FormatType) => dateTimeFormatTypes.has(type);

export const formatHandlerMapper: Record<FormatType, FormatterFunction> = {
  'auto-label-from-snake-case-value': autoFormatLabelFromSnakeCaseValue,
  date: formatDate,
  datetime: formatDatetime,
  'email-address': makeFormatEmailAddress(true),
  'email-address-strict': makeFormatEmailAddress(false),
  number: formatNumber,
  'number-position': formatPositionNumber,
  decimal: formatDecimal,
  'decimal-2-4': formatDecimalTwoFour,
  'phone-number-international': makePhoneNumberFormatter('INTERNATIONAL'),
  'phone-number-national': makePhoneNumberFormatter('NATIONAL'),
  price: formatPrice,
  'price-order': formatOrderPrice,
  'price-position': formatPositionPrice,
  'price-position-based-on-one': formatPositionPriceBasedOnOne,
  'price-trade': formatTradePrice,
  'price-change': formatPriceChange,
  percentage: formatPercentage,
  'decimal-to-percentage': formatDecimalToPercentage,
  quantity: formatQuantity,
  time: formatTime,
  time12: formatTime12,
  'charge-scale-range-number': formatSegmentedRangeNumber.charge,
  'price-scale-range-number': formatSegmentedRangeNumber.price,
  'volume-scale-range-number': formatSegmentedRangeNumber.volume,
  text: formatText
};

type FormatNumericSetup = {
  type: NumericFormatType;
} & FormatterFunctionParams;

export const formatNumeric = ({
  type,
  value,
  thousandDelimiter,
  decimalDelimiter,
  hasDelimiters,
  trimTrailingZeros,
  decimalPlaces: _decimalPlaces,
  negativeOneAsEmpty
}: FormatNumericSetup): string => {
  const decimalPlaces = ((): Optional<number> => {
    switch (type) {
      case 'number':
      case 'decimal-2-4':
        return _decimalPlaces;
      default:
        return _decimalPlaces ?? getNumberOfDecimalPlaces(value, trimTrailingZeros, decimalDelimiter);
    }
  })();
  return formatHandlerMapper[type]
    ? withDelimiters(formatHandlerMapper[type])({
        value,
        thousandDelimiter,
        decimalDelimiter,
        hasDelimiters,
        trimTrailingZeros,
        decimalPlaces,
        negativeOneAsEmpty
      })
    : `${value}`;
};

export type FormatOptions = {
  type: FormatType;
} & FormatterFunctionParams;

/**
 * Format value
 * @param type Format type
 * @param value Value to format
 * @deprecated Use format function from `@oms/shared-frontend/ui-design-system` instead
 */
export const format = (opts: FormatOptions): string => {
  const { value, type } = opts;
  if (!isDefined(value)) {
    return '';
  }

  if (isNumericFormat(type) && !isNumeric(value)) {
    return `${value}`;
  }

  if (isDateTimeFormat(type) && !isValidDate(value)) {
    console.warn('Value is not a valid date');
    return `${value}`;
  }

  return formatHandlerMapper[type] ? formatHandlerMapper[type](opts) : `${value}`;
};
