import { getOperationName } from '@apollo/client/utilities';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import type { DataSourceCommon } from '@oms/frontend-foundation';
import type {
  CreateExchangeMutation,
  CreateExchangeMutationVariables,
  DeleteExchangeMutation,
  DeleteExchangeMutationVariables,
  ExchangeFragment,
  ExchangesQuery,
  ExchangesQueryVariables,
  UpdateExchangeMutation,
  UpdateExchangeMutationVariables
} from '@oms/generated/frontend';
import {
  CreateExchangeDocument,
  DeleteExchangeDocument,
  ExchangesDocument,
  UpdateExchangeDocument
} from '@oms/generated/frontend';
import type { Observable } from 'rxjs';
import { catchError, map, of } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

const EXCHANGES_QUERY_NAME = getOperationName(ExchangesDocument);

@testScoped
@singleton()
export class ExchangesService {
  public static refetchQueryName = EXCHANGES_QUERY_NAME;
  public static refetchQueries = EXCHANGES_QUERY_NAME ? [EXCHANGES_QUERY_NAME] : [];

  constructor(
    @inject(RxApolloClient) private apolloClient: RxApolloClient,
    @inject(GQLResponse) private gqlResponse: GQLResponse
  ) {}

  /**
   * @param id - Lookup query by exchange ID, such as "NASQ" or "NYS"
   * @returns A list of `Exchange` objects matching your query filter
   */
  async getById(id: string): Promise<ExchangeFragment | null> {
    const result = await this.apolloClient.query<ExchangesQuery, ExchangesQueryVariables>({
      query: ExchangesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        offset: 0,
        first: 1000,
        filter: {
          id: { includesInsensitive: id }
        }
      }
    });
    const { exchanges } = result.data;
    const { nodes } = exchanges ?? {};
    return nodes && nodes.length ? nodes[0] : null;
  }

  watchAll$(id?: string): Observable<DataSourceCommon<ExchangeFragment>> {
    return this.apolloClient
      .rxWatchQuery<ExchangesQuery, ExchangesQueryVariables>({
        query: ExchangesDocument,
        variables: {
          offset: 0,
          first: 1000,
          filter: id
            ? {
                id: { includesInsensitive: id }
              }
            : undefined
        }
      })
      .pipe(
        map((res) => {
          const data = res.data;
          const results = data.exchanges?.nodes ? (data.exchanges.nodes as any[]) : [];
          return { isFetching: false, results };
        }),
        catchError((e) => {
          console.error(e);
          return of({ isFetching: false, results: [], error: e as Error });
        })
      );
  }

  create(record: ExchangeFragment) {
    const { __typename, ...exchange } = record;
    const mutation = this.gqlResponse.wrapMutate<CreateExchangeMutation, CreateExchangeMutationVariables>({
      mutation: CreateExchangeDocument,
      refetchQueries: ExchangesService.refetchQueries,
      variables: {
        exchange
      }
    });
    return mutation.exec();
  }

  update(record: ExchangeFragment) {
    const { __typename, ...exchange } = record;
    const mutation = this.gqlResponse.wrapMutate<UpdateExchangeMutation, UpdateExchangeMutationVariables>({
      mutation: UpdateExchangeDocument,
      variables: {
        id: record.id,
        patch: exchange
      }
    });
    return mutation.exec();
  }

  delete(ids: string[]) {
    return Promise.all(
      ids.map((id) =>
        this.gqlResponse
          .wrapMutate<DeleteExchangeMutation, DeleteExchangeMutationVariables>({
            mutation: DeleteExchangeDocument,
            refetchQueries: ExchangesService.refetchQueries,
            variables: {
              id
            }
          })
          .exec()
      )
    );
  }
}
