import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('REJECT_PENDING_MODIFICATION_FORM_INPUT', {
  version: 0,
  schema: z
    .object({
      modificationId: z.string()
    })
    .describe('Reject pending modification input schema')
});
