import { openTradingOrderView } from '@app/generated/sdk';
import { getOrderDialogTitle } from '../orders.utils';
import type { OrderDialogInfo } from '../orders.utils';
import { useCallback } from 'react';
import { useLeaderOrTabId } from '@app/common/workspace/workspace.hooks';

export interface IOpenViewTradingOrderArgs {
  windowId: string;
  dialogInfo: OrderDialogInfo;
}

export const openViewTradingOrder = async ({ windowId, dialogInfo }: IOpenViewTradingOrderArgs) => {
  await openTradingOrderView(windowId, {
    componentProps: {
      id: dialogInfo.id || ''
    },
    title: getOrderDialogTitle(dialogInfo, 'trading-order')
  });
};

export const useOpenViewTradingOrder = (dialogInfo?: OrderDialogInfo) => {
  const windowId = useLeaderOrTabId();

  return useCallback(() => {
    if (!dialogInfo) {
      console.error('dialogInfo is null or undefined');
      return;
    }
    openViewTradingOrder({ windowId, dialogInfo }).catch((e) => console.error(e));
  }, [windowId, dialogInfo]);
};
