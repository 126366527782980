import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('INVESTOR_ORDER_MONITOR_GRID', {
  version: 0,
  schema: z.object({
    filters: z.any(),
    nonfilterable: z.array(z.string()),
    excluded: z.array(z.string())
  })
});
