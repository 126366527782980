import type { ResolvePropsFunction } from '@data-driven-forms/react-form-renderer';
import type { OrderSettingsFormValues } from '../order-settings.form-contract';
import { OrderSizeRowUtil } from './order-size-row-util.class';

export const SET_STARTING_PRICE = 'setStartingPrice';

export const setStartingPrice = (): ResolvePropsFunction => (props, fieldApi, formOptions) => {
  const allValues = formOptions.getState().values as OrderSettingsFormValues;
  const { input } = fieldApi;

  const util = new OrderSizeRowUtil(allValues, fieldApi);

  if (util.rowIndex) {
    formOptions.change(input.name, util.previousRow?.to?.toString() || '0');
  } else {
    formOptions.change(input.name, '0');
  }

  return props;
};
