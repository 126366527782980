import { inject, injectable } from 'tsyringe';
import { scan } from 'rxjs';
import type { Observable } from 'rxjs';
import type {
  TableServerRow,
  TableServerSubscriptionShape,
  TableServerSubscriptionInnerShape,
  TableServerRowSubscriptionVariables
} from './table-server.datasource.contracts';
import type { DocumentNode } from '@apollo/client';
import type { GraphQLError } from 'graphql';
import { applyPatch } from 'fast-json-patch';
import type { AgGridFilterModelUnion } from './filters/ag-grid.filters.schema';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';

export interface TableServerQueryOptions<
  TData extends TableServerRow,
  TSubscription extends TableServerSubscriptionShape<TData>
> {
  /** The query to use for the table server */
  query: DocumentNode;
  /** Mapper function to find & map rows to inner data */
  getData: (result: TSubscription) => TableServerSubscriptionInnerShape<TData> | undefined;
  /** The variables to use for the table server query */
  variables: TableServerRowSubscriptionVariables;
  filter?: Partial<Record<keyof TData, AgGridFilterModelUnion>>;
}

export type TableServerQueryInnerData<TData> = {
  rows?: TData[];
  patch?: string;
  queryInfo: {
    totalCount: number;
    queryCount: number;
  };
};

export type TableServerQueryResult<TData> = {
  errors?: readonly GraphQLError[] | undefined;
  rows?: TData[];
  totalCount: number;
};

@injectable()
export class TableServerService {
  constructor(@inject(RxApolloClient) private apolloClient: RxApolloClient) {}

  /**
   * Observable that subscribes to a table server query
   *
   * @param options {TOptions extends TableServerQueryOptions<TSubscription, TData>}
   * @returns {Observable<TableServerQueryResult<TData>>}
   */
  public query$<TData extends TableServerRow, TSubscription extends TableServerSubscriptionShape<TData>>({
    query,
    variables,
    getData
  }: TableServerQueryOptions<TData, TSubscription>): Observable<TableServerQueryResult<TData>> {
    return this.apolloClient
      .rxSubscribe<TSubscription, TableServerRowSubscriptionVariables>({
        query,
        fetchPolicy: 'no-cache',
        variables
      })
      .pipe(
        scan((acc, result) => {
          // GraphQL error handling
          if (result.errors && result.errors.length) {
            return {
              ...TableServerService.EMPTY_RESULT,
              errors: result.errors
            };
          }

          // No data
          if (!result.data) {
            return TableServerService.EMPTY_RESULT;
          }

          try {
            const { patch, rows = [], queryInfo } = getData(result.data) || {};
            const totalCount = queryInfo?.totalCount || 0;

            // Apply patch to existing rows (if available)
            if (patch) {
              const prevRows = acc.rows || [];
              const patchObj = JSON.parse(patch);
              const newRows = applyPatch(prevRows, patchObj).newDocument;
              return {
                ...TableServerService.EMPTY_RESULT,
                rows: newRows,
                totalCount
              };
            }

            // Return initial rows
            return {
              ...TableServerService.EMPTY_RESULT,
              rows,
              totalCount
            };
          } catch (e) {
            // General error handling
            console.error(e);
            return TableServerService.EMPTY_RESULT;
          }
        }, TableServerService.EMPTY_RESULT as TableServerQueryResult<TData>)
      );
  }

  /**
   * Empty result object for table server queries
   */
  static EMPTY_RESULT = {
    rows: [],
    errors: [],
    totalCount: 0
  };
}
