import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import type {
  AcceptInvestorOrderModificationMutation,
  AcceptInvestorOrderModificationMutationVariables,
  GetVisibleOrderModificationsQuery,
  GetVisibleOrderModificationsQueryVariables,
  RejectInvestorOrderModificationMutation,
  RejectInvestorOrderModificationMutationVariables,
  VisibleModificationInfoFragment
} from '@oms/generated/frontend';
import {
  AcceptInvestorOrderModificationDocument,
  GetVisibleOrderModificationsDocument,
  ModificationStatus,
  RejectInvestorOrderModificationDocument
} from '@oms/generated/frontend';
import { map } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class PendingModificationsService {
  constructor(
    @inject(RxApolloClient) private apolloClient: RxApolloClient,
    @inject(GQLResponse) private gqlResponse: GQLResponse
  ) {}

  public getPendingModifications$(pollInterval = 5000) {
    return this.apolloClient
      .rxWatchQuery<GetVisibleOrderModificationsQuery, GetVisibleOrderModificationsQueryVariables>({
        query: GetVisibleOrderModificationsDocument,
        pollInterval,
        variables: {
          filter: {
            status: {
              in: [ModificationStatus.New, ModificationStatus.Accepting]
            }
          }
        }
      })
      .pipe(
        map(
          (res) =>
            (res.data.visibleModifications?.nodes as VisibleModificationInfoFragment[]) ||
            ([] as VisibleModificationInfoFragment[])
        )
      );
  }

  public acceptModification(modificationId: string) {
    const mutation = this.gqlResponse.wrapMutate<
      AcceptInvestorOrderModificationMutation,
      AcceptInvestorOrderModificationMutationVariables
    >({
      mutation: AcceptInvestorOrderModificationDocument,
      refetchQueries: [GetVisibleOrderModificationsDocument],
      variables: {
        modificationId
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public rejectModification(modificationId: string, rejectReason?: string) {
    const mutation = this.gqlResponse.wrapMutate<
      RejectInvestorOrderModificationMutation,
      RejectInvestorOrderModificationMutationVariables
    >({
      mutation: RejectInvestorOrderModificationDocument,
      refetchQueries: [GetVisibleOrderModificationsDocument],
      variables: {
        modificationId,
        rejectReason
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }
}
