import { MatchableInvestorOrdersSubscriptionService } from '@app/data-access/services/trading/investor-orders/matchable-investor-orders.subscriptions.service';
import { useService } from '@oms/frontend-foundation';
import { CheckboxButton, CheckboxButtonGroup, Spinner } from '@oms/shared-frontend/ui-design-system';
import { useState, useEffect } from 'react';
import type { FC } from 'react';
import { OrderType, TagCode } from '@oms/generated/frontend';
import type { OrderSide } from '@oms/generated/frontend';
import { formatOpenQuantityForMatchedOrders } from '@app/widgets/trading/montage/quantity-conversion.util';
import { InvestorOrdersService } from '@app/data-access/services/trading/investor-orders/investor-orders.service';
import type { InvestorOrderRow } from '@oms/generated/frontend';

export type MatchedInvestorOrdersPlacement = 'montage' | 'trading-order-entry';

export const PLEASE_SELECT_AN_INSTRUMENT = 'Please select an instrument';

type MatchedInvestorOrdersCheckboxGroupProps = {
  handleSelectOrders?: (orders: InvestorOrderRow[]) => void;
  isDisabled?: boolean;
  defaultCheckedValues?: string[];
  onError?: Function;
  maxOrdersToDisplay?: number;
  side: OrderSide;
  instrumentId: string;
  limitPrice?: number;
  placement: MatchedInvestorOrdersPlacement;
};

function formatMatchedOrder({
  openQuantity,
  orderType,
  limitPrice,
  investorAccountId,
  investorAccountName
}: Pick<
  InvestorOrderRow,
  'openQuantity' | 'orderType' | 'limitPrice' | 'investorAccountId' | 'investorAccountName'
>) {
  if (!investorAccountId) {
    throw new Error('investorAccount not found');
  }

  if (typeof openQuantity !== 'number') {
    throw new Error('openQuantity is not a number');
  }

  const formattedOpenQuantity = formatOpenQuantityForMatchedOrders(openQuantity);
  const price = orderType === OrderType.Limit ? limitPrice : orderType?.slice(0, 1);
  const abbreviatedInvestorAccountName = investorAccountName?.slice(0, 4);
  return `${formattedOpenQuantity} ${price || ''} ${abbreviatedInvestorAccountName || ''}`;
}

export const MatchedInvestorOrdersCheckboxGroup: FC<MatchedInvestorOrdersCheckboxGroupProps> = ({
  handleSelectOrders,
  onError,
  maxOrdersToDisplay,
  instrumentId,
  side,
  limitPrice,
  placement
}) => {
  const [allInvestorOrders, setAllInvestorOrders] = useState<InvestorOrderRow[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [checkboxesStatus, setCheckboxesStatus] = useState<{
    [key: string]: { isDisabled?: boolean; isChecked?: boolean };
  }>({});

  const datasourceService = useService(MatchableInvestorOrdersSubscriptionService);
  const investorOrderService = useService(InvestorOrdersService);

  useEffect(() => {
    if (!instrumentId) return;
    setIsLoading(true);
    const sub = datasourceService
      .queryMatchableInvestorOrders$({
        side,
        instrumentId
      })
      .subscribe((data) => {
        setIsLoading(false);

        if (data.errors) {
          onError && onError(data.errors);
        }
        if (data.rows) {
          setAllInvestorOrders(data.rows);
          const ordersWithoutTag = data.rows.filter(
            (order) => !order.orderTagIds?.some((tag) => tag === TagCode.Wab)
          );

          setCheckboxesStatus(
            ordersWithoutTag.reduce((acc, order) => {
              return {
                ...acc,
                [order.id]: { isDisabled: false, isChecked: true } // using the response from BE we set only those without the WAB tag as checked.
              };
            }, {})
          );

          if (!handleSelectOrders) {
            return;
          }

          handleSelectOrders(ordersWithoutTag);
        }
      });
    return () => {
      sub.unsubscribe();
    };
  }, [datasourceService, instrumentId, side, onError]);

  if (!instrumentId) {
    return <p>{PLEASE_SELECT_AN_INSTRUMENT}</p>;
  }

  const { compatibleOrders } = datasourceService.clientSideFilterCompatibleAndIncompatibleOrders(
    allInvestorOrders,
    {
      instrumentId,
      limitPrice,
      side
    },
    placement
  );

  const onChange = (v: (string | number | undefined)[]) => {
    compatibleOrders.forEach((order) => {
      if (v.includes(order.id)) {
        if (order.orderTagIds.some((tag) => tag === TagCode.Wab)) {
          investorOrderService.modifyInvestorOrderTags(order.id, [], [{ id: TagCode.Wab }]);
          setCheckboxesStatus((prev) => ({ ...prev, [order.id]: { isDisabled: true } }));
        }
      }

      if (!v.includes(order.id)) {
        if (!order.orderTagIds.some((tag) => tag === TagCode.Wab)) {
          investorOrderService.modifyInvestorOrderTags(order.id, [{ id: TagCode.Wab }], []);
          setCheckboxesStatus((prev) => ({ ...prev, [order.id]: { isDisabled: true } }));
        }
      }
    });
  };

  if (isLoading) {
    return <Spinner style={{ opacity: 0.33 }} />;
  }

  return compatibleOrders.length > 0 ? (
    <CheckboxButtonGroup
      onChange={onChange}
      defaultCheckedValues={Object.keys(checkboxesStatus).filter((key) => checkboxesStatus[key].isChecked)}
    >
      {compatibleOrders
        .slice(0, maxOrdersToDisplay) // limit the number of orders to display. Note: if maxOrderToDisplay is undefined, all orders will be displayed
        .map(({ id, limitPrice, investorAccountId, investorAccountName, openQuantity, orderType }) => (
          <CheckboxButton
            isDisabled={checkboxesStatus[id]?.isDisabled}
            key={id}
            value={id}
            data-testid={id}
            id={id}
          >
            {formatMatchedOrder({
              openQuantity,
              investorAccountId,
              investorAccountName,
              limitPrice,
              orderType
            })}
          </CheckboxButton>
        ))}
    </CheckboxButtonGroup>
  ) : (
    <p>No investor orders match</p>
  );
};
