import type { FetchPolicy } from '@apollo/client';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import type { DataSourceCommon, ICrudService } from '@oms/frontend-foundation';
import { toGqlDatasource } from '@oms/frontend-foundation';
import type {
  GetIoRepairRequestByIdQuery,
  GetIoRepairRequestByIdQueryVariables,
  GetIoRepairRequestsQuery,
  GetIoRepairRequestsQueryVariables,
  IoRepairRequestFragment,
  RejectIoRepairRequestInput,
  RejectIoRepairRequestMutation,
  RejectIoRepairRequestMutationVariables,
  RepairCreateInvestorOrderRequest,
  RepairCreateInvestorOrderRequestMutation,
  RepairCreateInvestorOrderRequestMutationVariables,
  RepairModifyInvestorOrderRequestMutation,
  RepairModifyInvestorOrderRequestMutationVariables,
  RepairsOrderInputWithCharges,
  RetryInvestorOrdersRequestMutation,
  RetryInvestorOrdersRequestMutationVariables
} from '@oms/generated/frontend';
import {
  GetIoRepairRequestByIdDocument,
  GetIoRepairRequestsDocument,
  RejectIoRepairRequestDocument,
  RepairCreateInvestorOrderRequestDocument,
  RepairModifyInvestorOrderRequestDocument,
  RetryInvestorOrdersRequestDocument
} from '@oms/generated/frontend';
import { cleanMaybe, Logger } from '@oms/shared/util';
import type { Observable } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class InvestorOrderRepairRequestService implements ICrudService<IoRepairRequestFragment> {
  protected name: string = 'InvestorOrderRepairRequestService';
  protected logger: Logger;

  protected fetchPolicy: FetchPolicy = 'cache-first';

  // 🏗️ Constructor ------------------------------------------------------- /

  constructor(
    @inject(RxApolloClient) private apolloClient: RxApolloClient,
    @inject(GQLResponse) private gqlResponse: GQLResponse
  ) {
    this.logger = Logger.labeled(this.name);
  }

  // 🔍 Queries --------------------------------------------------------- /

  public watchAll$ = (): Observable<DataSourceCommon<IoRepairRequestFragment>> => {
    const result = this.apolloClient.rxWatchQuery<
      GetIoRepairRequestsQuery,
      GetIoRepairRequestsQueryVariables
    >({
      query: GetIoRepairRequestsDocument,
      fetchPolicy: this.fetchPolicy,
      pollInterval: 5000
    });

    return result.pipe(
      toGqlDatasource(
        ({ getIoRepairRequests }) => cleanMaybe(getIoRepairRequests, []) as IoRepairRequestFragment[]
      )
    );
  };

  public getRepairRequestById(repairId: string) {
    const query = this.gqlResponse.wrapQuery<
      GetIoRepairRequestByIdQuery,
      GetIoRepairRequestByIdQueryVariables
    >({
      query: GetIoRepairRequestByIdDocument,
      variables: {
        requestId: repairId
      }
    });
    return query.exec();
  }

  //  Mutations --------------------------------------------------------- /

  public rejectWithReason(id: string, rejectText: string) {
    const mutation = this.gqlResponse.wrapMutate<
      RejectIoRepairRequestMutation,
      RejectIoRepairRequestMutationVariables
    >({
      mutation: RejectIoRepairRequestDocument,
      variables: {
        input: {
          id,
          rejectText
        } as RejectIoRepairRequestInput
      },
      refetchQueries: [GetIoRepairRequestsDocument]
    });

    return mutation.exec();
  }

  public repairIOCreate(ioRepairId: string, repairOrderWithCharges: RepairsOrderInputWithCharges) {
    const mutation = this.gqlResponse.wrapMutate<
      RepairCreateInvestorOrderRequestMutation,
      RepairCreateInvestorOrderRequestMutationVariables
    >({
      mutation: RepairCreateInvestorOrderRequestDocument,
      variables: {
        input: {
          ioRepairRequestId: ioRepairId,
          orderInputWithCharges: repairOrderWithCharges
        } as RepairCreateInvestorOrderRequest
      }
    });

    return mutation.exec();
  }
  public retryIOs(ioRetryIds: string[]) {
    const mutation = this.gqlResponse.wrapMutate<
      RetryInvestorOrdersRequestMutation,
      RetryInvestorOrdersRequestMutationVariables
    >({
      mutation: RetryInvestorOrdersRequestDocument,
      variables: {
        input: {
          ioRepairRequestIds: ioRetryIds
        }
      }
    });

    return mutation.exec();
  }

  public repairIOModify(ioRepairId: string, repairOrderWithCharges: RepairsOrderInputWithCharges) {
    const mutation = this.gqlResponse.wrapMutate<
      RepairModifyInvestorOrderRequestMutation,
      RepairModifyInvestorOrderRequestMutationVariables
    >({
      mutation: RepairModifyInvestorOrderRequestDocument,
      variables: {
        input: {
          ioRepairRequestId: ioRepairId,
          orderInputWithCharges: repairOrderWithCharges
        }
      }
    });

    return mutation.exec();
  }
}

export default InvestorOrderRepairRequestService;
