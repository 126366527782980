import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('ROUTE_ORDER_FORM_INPUT', {
  version: 0,
  schema: z.object({
    mode: z
      .union([
        z.object({
          type: z.literal('route'),
          investorOrderId: z.string()
        }),
        z.object({
          type: z.literal('modify'),
          tradingOrderId: z.string(),
          location: z.literal('montage').optional()
        }),
        z.object({ type: z.literal('create'), location: z.literal('montage').optional() })
      ])
      .optional()
  })
});
