import {
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  useVGrid,
  VGrid
} from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { tradingOrderMarketData, buildTradingOrderMonitorColumnDefs } from './trading-order-monitor.columns';
import { useTradingOrderMonitorToolbarActions } from './toolbar-actions';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import { inlineTradingOrderActions } from './grid-actions/inline.trading-order.actions';
import { cancelTradingOrderAction } from './grid-actions/cancel.trading-order.action';
import { forceCancelTradingOrderAction } from './grid-actions/force-cancel.trading-order.action';
import { modifyTradingOrderAction } from './grid-actions/modify.trading-order.action';
import { viewTradingOrderAction } from './grid-actions/view.trading-order.action';
import { openEntryTradingOrderAction } from './grid-actions/open-entry.trading-order.action';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { createInstrumentFollowEventHandler } from '@app/data-access/services/system/instrument-follow/instrument-follow.grid.event-handler';
import { TOHasExecutedQuantity, TONoExecutedQuantity, TOPending } from '../utils/row-state-rule-utils';
import { TsTradingOrdersWithFilterDocument } from '@oms/generated/frontend';
import type { TradingOrderRow, TsTradingOrdersWithFilterSubscription } from '@oms/generated/frontend';
import { OrderMonitorVisibilityEventHander } from '../investor-order-monitor/grid-services/order-monitor.visibility.event-handler';

export const TradingOrderMonitorWidgetComponent = () => {
  useTradingOrderMonitorToolbarActions();

  const datasourceService = useService(TableServerDatasourceService);

  const gridProps = useVGrid<TradingOrderRow>(
    'trading-order-monitor',
    (builder) =>
      builder
        .tableServerColumnLibrary(buildTradingOrderMonitorColumnDefs())
        .rowSelection((c) => c.multiple())
        .datasource((d) =>
          d
            .source(
              datasourceService.getSource<TradingOrderRow, TsTradingOrdersWithFilterSubscription>({
                query: TsTradingOrdersWithFilterDocument,
                getData: (r) => r.tsTradingOrdersWithFilter
              })
            )
            .rowId((r) => r.data.id)
            .cacheBlockSize(100)
        )
        .rowStateRules({
          pending: (params) => TOPending(params.data),
          noExecutedQuantity: (params) => TONoExecutedQuantity(params.data),
          hasExecutedQuantity: (params) => TOHasExecutedQuantity(params.data)
        })
        .marketData(tradingOrderMarketData)
        .injectEvents([
          OrderMonitorVisibilityEventHander,
          GridConfigEventHandler,
          createInstrumentFollowEventHandler<TradingOrderRow>({
            sourceType: 'TRADING_ORDER_MONITOR_GRID',
            getId: (data) => data.instrument,
            getDisplayCode: (data) => data.instrumentDisplayCode,
            colId: 'instrumentDisplayCode'
          })
        ])
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(openEntryTradingOrderAction())
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(viewTradingOrderAction())
                .action(modifyTradingOrderAction())
                .action(cancelTradingOrderAction())
                .action(forceCancelTradingOrderAction)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(inlineTradingOrderActions())
                .action(refreshServersideCustomMenuAction)
            )
            .settings((s) => s.fromFile({}))
        )
        .sideBar()
        .reactiveCustomComponents(),
    [datasourceService, buildTradingOrderMonitorColumnDefs]
  );

  return <VGrid {...gridProps} />;
};

export default TradingOrderMonitorWidgetComponent;
