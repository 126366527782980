import { FormSummary } from '@oms/frontend-foundation';
import { useMemo } from 'react';
import { orderSummaryData } from '@app/common/summaries/order-fields';
import type { OrderSummaryFieldName } from '@app/common/summaries/order-fields';
import type { InvestorOrderRow } from '@oms/generated/frontend';

const orderFields: OrderSummaryFieldName[] = [
  'id',
  'clientOrderId',
  'side',
  'locate',
  'totalQuantity',
  'notionalAmount',
  'investorAccountName',
  'investorAccountType',
  'limitPrice',
  'stopPrice',
  'orderType',
  'orderEntryType',
  'workflow',
  'timeInForce',
  'expiryDateTime',
  'updatedTime',
  'lastExecutionTime',
  'orderTagIds',
  'status',
  'transmittedTimestamp',
  'captureTimestamp',
  'validatedTimestamp',
  'createdTimestamp',
  'receivedTimestamp',
  'enteredByEmail',
  'ownerEmail',
  'workingQuantity',
  'openQuantity',
  'executedQuantity',
  'leavesQuantity',
  'averagePrice',
  'instrumentDisplayCode',
  'customerNotes',
  'representativeCode',
  'fixWorkflowId',
  'todayExecutedQuantity',
  'todayAveragePrice'
];

const settleFields: OrderSummaryFieldName[] = ['tradeCurrency', 'settleCurrency', 'settleType', 'settleDate'];

export const allOrderFields = [...orderFields, ...settleFields];

export const InvestorOrderDetailsSummary = ({ investorOrder }: { investorOrder: InvestorOrderRow }) => {
  const data = useMemo(() => {
    return [
      orderSummaryData('ORDER', orderFields, investorOrder),
      orderSummaryData('SETTLE', settleFields, investorOrder)
    ];
  }, [investorOrder]);

  return <FormSummary labeledData={data} />;
};
