import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import type { MontageSettingsSchema } from '@app/data-access/offline/collections/user-preferences.collection';
import { fromUserPrefererencesForm, toUserPreferencesForm } from '../../user-preferences.util';
import { montageSettingsContract } from './montage-settings.form-contract';
import type { MontageSettingsContractType } from './montage-settings.form-contract';
import { createTransformToFormValuesFn, tranformFromFormValues } from './montage-settings.util';

export const montageSettingsBuilder = FormBuilder.create<{}, MontageSettingsSchema>('montage-settings-form')
  .contract<MontageSettingsContractType>(montageSettingsContract)
  .type('montage-settings')
  .sanitizer((s) =>
    s
      .input(async (_, { container }) => {
        const transformToFormValues = await createTransformToFormValuesFn(container);
        return await toUserPreferencesForm('montageSettings', container, transformToFormValues);
      })
      .output(tranformFromFormValues)
  )
  .change(async (event, { container, notify }) => {
    const { type } = event;

    switch (type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const { output } = event.payload;
        await fromUserPrefererencesForm('montageSettings', output, container);
        break;
      }
      case FORM_EVENT_TYPE.RESET: {
        notify({
          type: 'RESET'
        });
      }
    }
  });

export type MontageSettingsBuilderType = typeof montageSettingsBuilder;

export default montageSettingsBuilder;
