/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */

/**
 * Generates a UUID
 */
export function uuid() {
  return crypto.randomUUID();
}

/**
 * Checks if a property on an object is non-writable
 *
 * @param obj - Any object
 * @param key - string
 * @returns {boolean}
 */
export function isNonWritable(obj: any, key: string): boolean {
  const descriptor = Object.getOwnPropertyDescriptor(obj, key);
  return !!descriptor && descriptor.writable === false;
}

/**
 * Checks if a property on an object is writable
 *
 * @param obj - Any object
 * @param key - string
 * @returns {boolean}
 */
export function isWritable(obj: any, key: string): boolean {
  return !isNonWritable(obj, key);
}

/**
 * Checks if a value is an object
 *
 * @param obj - Any object
 * @returns {boolean}
 */
function isObject(value: any): value is Record<string, any> {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
}

/**
 * Checks if a value is non-serializable
 *
 * @param obj - Any object
 * @param key - string
 * @returns {boolean}
 */
export function isNonSerializable(value: any): boolean {
  return (
    value instanceof Date ||
    value instanceof Map ||
    value instanceof Set ||
    typeof value === 'undefined' ||
    typeof value === 'function' ||
    typeof value === 'symbol'
  );
}

/**
 * Maybe Serialize? Currently doesn't work
 */
export function maybeSerialize(obj: any, ignoreProperties: string[] = [], autoSerialize = true) {
  return autoSerialize
    ? serialize(obj, ignoreProperties)
    : Array.isArray(obj)
      ? obj
      : typeof obj === 'object'
        ? omit(obj, ...ignoreProperties)
        : obj;
}

/**
 * Serializes an object or array
 *
 * @param obj - any
 * @returns - any
 */
export function serialize(obj: any, ignoreProperties: string[] = [], level = 0): any {
  if (isNonSerializable(obj)) {
    return undefined;
  }

  if (Array.isArray(obj)) {
    return obj.map((v) => serialize(v, ignoreProperties, level + 1)).filter((val) => !isNonSerializable(val));
  }

  if (typeof obj === 'object' && obj !== null) {
    const result: any = {};
    for (const key in obj) {
      if (
        typeof obj === 'object' &&
        key in obj &&
        !isNonSerializable(obj[key]) &&
        !ignoreProperties.includes(key)
      ) {
        result[key] = serialize(obj[key], ignoreProperties, level + 1);
      }
    }
    return result;
  }

  return obj;
}

/**
 * Clever way to detect if an object is a proxy
 * Source: https://stackoverflow.com/a/51418844
 */
export const IS_PROXY = Symbol('IS_PROXY');

export function isProxy(obj: any) {
  return obj && obj[IS_PROXY] === true;
}

export function deepResolveProxy(input: any, doNotCheck = false): any {
  if (isProxy(input) && doNotCheck === false) {
    const resolved = Proxy.revocable(input, {}).proxy;
    return deepResolveProxy(resolved, typeof input === 'object');
  }

  if (Array.isArray(input)) {
    return input.map((item) => deepResolveProxy(item));
  }

  if (input !== null && typeof input === 'object') {
    const output = {};
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        (output as any)[key] = deepResolveProxy(input[key]);
      }
    }
    return output;
  }

  return input;
}

/**
 * Deeply merges two values and preserves the properties that are not serializable & read-only
 * If the values are not objects or arrays, the second value will be returned.
 * If the values are arrays, the second array will be returned regardless of serializability / read-only.
 * If the values are objects, the properties which are not serializable from the first will be preserved. Everything else will be overwritten.
 *
 * @param obj - any
 * @param newObj - any
 */
export function deepMergePreserve(obj: any, newObj: any): any {
  // If the values are not objects, return the second value.
  if (!isObject(obj) || !isObject(newObj)) {
    return newObj;
  }

  // Create a new object to accumulate merged results.
  const result: any = {};

  // First, iterate over the properties of the first object.
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // If the key exists in both objects and both are objects themselves, merge recursively.
      if (newObj.hasOwnProperty(key) && isObject(obj[key]) && isObject(newObj[key])) {
        result[key] = deepMergePreserve(obj[key], newObj[key]);
      } else if (!newObj.hasOwnProperty(key) && (isNonSerializable(obj[key]) || isNonWritable(obj, key))) {
        // If the key is not present in the second object and the property from the first object is non-serializable or non-writable, preserve it.
        result[key] = obj[key];
      }
    }
  }

  // Now, iterate over the properties of the second object.
  for (const key in newObj) {
    if (newObj.hasOwnProperty(key)) {
      // If the key is already processed, skip it.
      if (result[key]) continue;

      result[key] = isObject(newObj[key]) ? deepMergePreserve({}, newObj[key]) : newObj[key];
    }
  }

  return result;
}

/**
 * Returns the index of the last matching element in two paths
 *
 * @param pathOne - The first path (e.g. ['a', 'b', 'c'])
 * @param pathTwo - The second path (e.g. ['a', 'b', 'd'])
 * @returns {number | null}
 */
export function getMatchingIndex(pathOne: string[], pathTwo: string[]): number | null {
  let lastIndex: number | null = null;

  for (let i = 0; i < Math.min(pathOne.length, pathTwo.length); i++) {
    if (pathOne[i] === pathTwo[i]) {
      lastIndex = i;
    } else {
      break;
    }
  }

  // If no matches were found, return null
  if (lastIndex === null) {
    return null;
  }

  return lastIndex;
}

type Omit<T, K extends keyof T = never> = Pick<T, Exclude<keyof T, K>>;

/**
 * Creates a new object with the specified keys omitted.
 *
 * @param {T} obj - The source object.
 * @param {...K[]} keys - The keys to omit from the source object.
 * @returns {Omit<T, K>} A new object with the specified keys omitted.
 */
export function omit<T, K extends keyof T = never>(obj: T, ...keys: K[]): Omit<T, K> {
  const result: any = { ...obj };
  keys.forEach((key) => {
    delete result[key];
  });
  return result;
}
