import { useEffect, useMemo } from 'react';
import { Subject } from 'rxjs';
import type { Observable } from 'rxjs';
import { Logger } from '@oms/shared/util';
import type { Level2IntegrationEvent } from '@app/data-access/services/trading/montage/montage.types';
import { Level2WebSocketSubject } from '@app/data-access/services/marketdata/level2-websocket.subjects';
import { useMontageInstrumentDetails } from '@app/data-access/services/trading/montage/hooks/use-montage-instrument-details.hook';
import { createFactSetQuery } from '@app/data-access/services/marketdata/level2-websocket.utils';

const logger = Logger.labeled('useLevel2Data');

export const useLevel2Data = (): Observable<Level2IntegrationEvent> => {
  const instrument = useMontageInstrumentDetails();
  const instrumentId = instrument?.id;
  const sharedSubject$ = useMemo(() => new Subject<Level2IntegrationEvent>(), []);

  const webSocketSubject$ = useMemo(() => new Level2WebSocketSubject('standard'), [instrumentId]);

  useEffect(() => {
    if (!instrumentId) return;
    const identifier = instrument.mappings?.displayCode?.split('-')[0] || instrument.id;
    const events$ = webSocketSubject$.multiplex(
      () => [createFactSetQuery(identifier)],
      () => [],
      () => true
    );

    const subscription = events$.subscribe({
      next: (event) => {
        sharedSubject$.next(event);
      },
      error: logger.error
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [instrumentId]);

  return useMemo(() => sharedSubject$.asObservable(), [sharedSubject$]);
};
