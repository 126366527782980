import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('TRADE_MODIFY_FORM_INPUT', {
  version: 0,
  schema: z
    .object({
      id: z.string(),
      entryType: z.union([z.literal('trade-modify'), z.literal('trade-repair')])
    })
    .deepPartial()
    .describe('Trade modify form input schema')
});
