import { z } from 'zod';
import {
  OrderType,
  OrderSideType,
  InvestorOrderStatus,
  OrderEntryType,
  TimeInForce,
  OrderSide,
  OrderSettleType,
  TradingOrderCategory
} from '@oms/generated/frontend';

const zOrderType: z.ZodType<OrderType> = z.nativeEnum(OrderType);

export const zOrderSideType: z.ZodType<OrderSideType> = z.nativeEnum(OrderSideType);

const zOrderSideOnlyType: z.ZodType<OrderSide> = z.nativeEnum(OrderSide);

export const zTimeInForce: z.ZodType<TimeInForce> = z.nativeEnum(TimeInForce);

// this is missing 'system' - is that intentional?
const zOrderEntryType: z.ZodType<OrderEntryType> = z.enum([OrderEntryType.Manual, OrderEntryType.Electronic]);

// this is missing 'execution cancelled' - is that intentional?
const zInvestorOrderStatus: z.ZodType<InvestorOrderStatus> = z.enum([
  InvestorOrderStatus.Active,
  InvestorOrderStatus.CancellationPending,
  InvestorOrderStatus.Cancelled,
  InvestorOrderStatus.Expirable,
  InvestorOrderStatus.Expired,
  InvestorOrderStatus.ExpiryPending,
  InvestorOrderStatus.FullyFilled,
  InvestorOrderStatus.Inactive,
  InvestorOrderStatus.Live,
  InvestorOrderStatus.ModificationPending,
  InvestorOrderStatus.ModifyRequested,
  InvestorOrderStatus.Rejected,
  InvestorOrderStatus.Unaccepted
]);

const zOrderSettlementType: z.ZodType<OrderSettleType> = z.nativeEnum(OrderSettleType);

export const Z_INVESTOR_ORDER = {
  ORDER_TYPE: zOrderType,
  SIDE_TYPE: zOrderSideType,
  TIME_IN_FORCE: zTimeInForce,
  ORDER_ENTRY_TYPE: zOrderEntryType,
  INVESTOR_ORDER_STATUS: zInvestorOrderStatus,
  ORDER_SETTLEMENT_TYPE: zOrderSettlementType
} as const;

const zTradingOrderCategory: z.ZodType<TradingOrderCategory> = z.nativeEnum(TradingOrderCategory);

export const Z_TRADING_ORDER = {
  CATEGORY: zTradingOrderCategory
} as const;

export const zOrderTitleDetailsSchema = z.object({
  sideType: zOrderSideType,
  quantity: z.number(),
  symbol: z.string(),
  price: z.union([z.number(), z.string()]), // Allow either number or string for price
  account: z.optional(z.string())
});

export const zTradeDescriptionSchema = z.object({
  side: zOrderSideOnlyType,
  quantity: z.number(),
  symbol: z.string(),
  price: z.union([z.number(), z.string()]), // Allow either number or string for price
  account: z.optional(z.string())
});
