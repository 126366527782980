import { merge } from 'lodash';
import type { Identifiable } from '@oms/shared/util-types';
import { Result } from '@oms/shared/util';
import type { FoundationWorkspace } from '@oms/frontend-foundation';
import { t } from '@oms/codegen/translations';
import { openInvestorOrderEntry, PartialFormDefinition } from '@app/generated/sdk';
import { FORM_MAP } from '@app/generated/mappers';
import { getLeaderOrTabId } from '@app/common/workspace/workspace.util';
import type { Form } from '@app/common/registry/form.open';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import { INVESTOR_ORDER_MONITOR_GRID_NAME } from './investor-order-entry.constants';

// Types -------------------------------------------------------------------------- /

type InvestorOrderEntryFormType = Form<(typeof FORM_MAP)['INVESTOR_ORDER_ENTRY']>;

type FormOverrides<HasFormValuesSchema extends boolean = false> = PartialFormDefinition<
  (typeof FORM_MAP)['INVESTOR_ORDER_ENTRY'],
  HasFormValuesSchema
>;

type ParentActorOrId = Parameters<typeof openInvestorOrderEntry>[0];

type ErrorTuple = [message: string, workspace: AppWorkspace | FoundationWorkspace];

// Util -------------------------------------------------------------------------- /

export const openInvestorOrderEntryFromActionButton = async (
  workspace: AppWorkspace | FoundationWorkspace,
  { id }: Identifiable,
  overrides: FormOverrides<true> = {},
  parentActorOrId?: ParentActorOrId
): Promise<Result<InvestorOrderEntryFormType, ErrorTuple>> => {
  const defaults: NonNullable<typeof overrides> = {
    title: t('app.commands.createInvestorOrder.formTitle'),
    form: {
      formId: id,
      input: {
        entryType: 'create',
        id
      }
    }
  };
  try {
    const { container } = workspace.meta;
    if (!container) {
      return Result.failure(['Workspace container not found', workspace]);
    }
    const parentId = parentActorOrId || getLeaderOrTabId(container);
    const allOverides = merge(defaults, overrides);
    const { windowOptions, ...otherOverrides } = allOverides;
    const { meta, ...otherWindowOptions } = windowOptions || {};
    const { widgetType = INVESTOR_ORDER_MONITOR_GRID_NAME, ...otherMeta } = meta || {};
    const form = await openInvestorOrderEntry(parentId, {
      ...otherOverrides,
      windowOptions: {
        ...otherWindowOptions,
        meta: {
          ...otherMeta,
          widgetType
        }
      }
    } as FormOverrides<false>);
    return Result.success(form);
  } catch (e) {
    const message = e instanceof Error ? e.message : `${e as string}`;
    return Result.failure([message, workspace]);
  }
};
