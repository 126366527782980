import type {
  AdvancedSelectQueryFn,
  AdvancedSelectQueryPayload,
  ComboBoxItem
} from '@oms/frontend-foundation';
import { map } from 'rxjs';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { MarketsDocument } from '@oms/generated/frontend';
import type { MarketsQuery } from '@oms/generated/frontend';

export const watchActiveMarketsQuery: AdvancedSelectQueryFn = (container) => {
  const apolloClient = container.resolve(RxApolloClient);
  return {
    type: 'watchAll',
    query: () => {
      const observable = apolloClient
        .rxWatchQuery<MarketsQuery>({
          query: MarketsDocument,
          fetchPolicy: 'cache-and-network'
        })
        .pipe(
          map((data) => {
            const comboBoxItems = data?.data?.markets?.nodes.map((market) => {
              const comboItem: ComboBoxItem = {
                id: market.exchangeMic || '',
                label: market.exchangeMic || '',
                type: 'item',
                value: market.exchangeMic || ''
              };
              return comboItem;
            });
            const shape: AdvancedSelectQueryPayload = {
              isFetching: false,
              results: comboBoxItems
            };
            return shape;
          })
        );
      return observable;
    }
  };
};

export default watchActiveMarketsQuery;
