import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('MONTAGE_FILTERS_FORM_FORM_INPUT', {
  version: 0,
  schema: z
    .object({
      instrumentTrackingEnabled: z.boolean().optional(),
      instrument: z.string().optional(),
      investorAccount: z.string().optional(),
      layout: z.string().optional()
    })
    .describe('Montage filters form input schema')
});
