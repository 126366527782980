import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import type { DataSourceCommon, ICrudService } from '@oms/frontend-foundation';
import { asDataSource } from '@oms/frontend-foundation';
import type {
  TradeCounterpartiesQuery,
  TradeCounterpartiesQueryVariables,
  TradeCounterpartyInfoFragment
} from '@oms/generated/frontend';
import { TradeCounterpartiesDocument } from '@oms/generated/frontend';
import type { Observable } from 'rxjs';
import { catchError, map, of, startWith } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class TradeCounterpartyService implements ICrudService<TradeCounterpartyInfoFragment> {
  private _apolloClient: RxApolloClient;

  constructor(@inject(RxApolloClient) apolloClient: RxApolloClient) {
    this._apolloClient = apolloClient;
  }

  watchAll$(): Observable<DataSourceCommon<TradeCounterpartyInfoFragment>> {
    return this._apolloClient
      .rxWatchQuery<TradeCounterpartiesQuery, TradeCounterpartiesQueryVariables>({
        query: TradeCounterpartiesDocument,
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map((res) => {
          const results = res.data.tradeCounterparties ? res.data.tradeCounterparties.nodes : [];
          return asDataSource(results);
        }),
        catchError((e) => {
          console.error(e);
          return of(asDataSource([]));
        }),
        startWith(asDataSource([], { isFetching: true }))
      );
  }
}
