import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('ORDER_VWAP', {
  version: 0,
  schema: z.object({
    trackingEnabled: z
      .boolean()
      .optional()
      .describe('Current status of instrument tracking set by the toggle button in the toolbar'),
    investorOrderId: z.string()
  })
});
