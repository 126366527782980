import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import type {
  AcceptClientOrderMutation,
  AcceptClientOrderMutationVariables,
  CancelInvestorOrderMutation,
  CancelInvestorOrderMutationVariables,
  CancelInvestorOrdersInput,
  CancelInvestorOrdersMutation,
  CancelInvestorOrdersMutationVariables,
  GenerateInvestorOrderSummaryMutation,
  GenerateInvestorOrderSummaryMutationVariables,
  GetOrderByIdQuery,
  GetOrderByIdQueryVariables,
  GetOrdersQuery,
  GetOrdersQueryVariables,
  OrderTagInput,
  RejectClientOrderMutation,
  RejectClientOrderMutationVariables,
  SendDoneForDayMutation,
  SendDoneForDayMutationVariables,
  SendUpdateInvestorOrderTagsMutation,
  SendUpdateInvestorOrderTagsMutationVariables,
  VisibleInvestorOrderInfoWithAllocationsFragment
} from '@oms/generated/frontend';
import {
  AcceptClientOrderDocument,
  CancelInvestorOrderDocument,
  CancelInvestorOrdersDocument,
  GenerateInvestorOrderSummaryDocument,
  GetOrderByIdDocument,
  GetOrdersDocument,
  ReactivateInvestorOrdersDocument,
  ReactivateInvestorOrdersMutation,
  ReactivateInvestorOrdersMutationVariables,
  RejectClientOrderDocument,
  SendDoneForDayDocument,
  SendUpdateInvestorOrderTagsDocument
} from '@oms/generated/frontend';
import { from, map } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class InvestorOrdersService {
  constructor(
    @inject(RxApolloClient) private apolloClient: RxApolloClient,
    @inject(GQLResponse) private gqlResponse: GQLResponse
  ) {}

  public getById(investorOrderId: string) {
    return this.gqlResponse
      .wrapQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>({
        query: GetOrderByIdDocument,
        variables: {
          id: investorOrderId
        }
      })
      .exec();
  }

  public async getInstrumnentById(investorOrderId: string) {
    const result = await this.getById(investorOrderId);
    if (result.isSuccess() && result.value.data.visibleInvestorOrder) {
      return result.value.data.visibleInvestorOrder.instrument;
    }
    return null;
  }

  public getOrders(variables?: GetOrdersQueryVariables) {
    return this.gqlResponse
      .wrapQuery<GetOrdersQuery, GetOrdersQueryVariables>({
        query: GetOrdersDocument,
        variables,
        fetchPolicy: 'network-only'
      })
      .exec();
  }

  public pollById$(investorOrderId: string, pollInterval = 5000) {
    return this.apolloClient
      .rxWatchQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>({
        query: GetOrderByIdDocument,
        variables: {
          id: investorOrderId
        },
        pollInterval
      })
      .pipe(
        map((result) => result.data?.visibleInvestorOrder as VisibleInvestorOrderInfoWithAllocationsFragment)
      );
  }

  public acceptOrder(id: string) {
    const mutation = this.gqlResponse.wrapMutate<
      AcceptClientOrderMutation,
      AcceptClientOrderMutationVariables
    >({
      mutation: AcceptClientOrderDocument,
      variables: {
        id
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public rejectOrder(id: string, rejectText?: string) {
    const mutation = this.gqlResponse.wrapMutate<
      RejectClientOrderMutation,
      RejectClientOrderMutationVariables
    >({
      mutation: RejectClientOrderDocument,
      variables: {
        id,
        rejectText
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public cancelOrder(orderId: string) {
    const mutation = this.gqlResponse.wrapMutate<
      CancelInvestorOrderMutation,
      CancelInvestorOrderMutationVariables
    >({
      mutation: CancelInvestorOrderDocument,
      variables: {
        orderId
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public cancelOrders(ordersToCancel: CancelInvestorOrdersInput) {
    const mutation = this.gqlResponse.wrapMutate<
      CancelInvestorOrdersMutation,
      CancelInvestorOrdersMutationVariables
    >({
      mutation: CancelInvestorOrdersDocument,
      variables: {
        ordersToCancel
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public sendDoneForDay(orderId: string) {
    const mutation = this.gqlResponse.wrapMutate<SendDoneForDayMutation, SendDoneForDayMutationVariables>({
      mutation: SendDoneForDayDocument,
      variables: {
        orderId
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public generateInvestorOrderSummary(id: string) {
    const mutation = this.gqlResponse.wrapMutate<
      GenerateInvestorOrderSummaryMutation,
      GenerateInvestorOrderSummaryMutationVariables
    >({
      mutation: GenerateInvestorOrderSummaryDocument,
      variables: {
        id
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }

  public modifyInvestorOrderTags(orderId: string, addTags: OrderTagInput[], removeTags: OrderTagInput[]) {
    const mutation = this.gqlResponse.wrapMutate<
      SendUpdateInvestorOrderTagsMutation,
      SendUpdateInvestorOrderTagsMutationVariables
    >({
      mutation: SendUpdateInvestorOrderTagsDocument,
      variables: {
        orderId,
        addTags,
        removeTags
      }
    });
    return from(mutation.awaitAsyncResponse().exec());
  }

  public reactivateInvestorOrders(orderIds: string[], dryRun: boolean) {
    const mutation = this.gqlResponse.wrapMutate<
      ReactivateInvestorOrdersMutation,
      ReactivateInvestorOrdersMutationVariables
    >({
      mutation: ReactivateInvestorOrdersDocument,
      variables: {
        orderIds,
        dryRun
      }
    });

    return mutation.awaitAsyncResponse().exec();
  }
}
