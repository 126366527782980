import type { GridsDocType } from '@app/data-access/offline/collections/grids.collection';
import type { ComponentEnumKey } from '@app/generated/common';
import type { ActionCommands } from '../commands/command.registry.types';
import type { FORM_MAP } from '@app/generated/mappers';
import type { ButtonProps } from '@oms/shared-frontend/ui-design-system';
import type { AnyRecord } from '@oms/frontend-foundation';
import type { CollectionWithSchemaPayload } from '@oms/frontend-schemas';
import { ShareableCollectionType } from '@app/data-access/offline/collections/snapshots/snapshots.collection';

// All enum types are derived from
// https://valstro.sharepoint.com/:x:/s/ProductManagement/ESSkQaTFSN5Gh7MXA4M1BG0BkNqH6N4slpkSBS25pkIJEQ?e=ywGx1w

export const COMMANDS_TO_FORMS: Partial<Record<ActionCommands, keyof typeof FORM_MAP>> = {
  create_investor_order: 'INVESTOR_ORDER_ENTRY',
  create_unbound_to: 'ROUTE_ORDER',
  route_order: 'ROUTE_ORDER',
  montage_bump_price: 'MONTAGE_BUMP_PRICE'
};

export type ActionSize = 'S' | 'M' | 'L' | 'XS' | 'XL';

export const ACTION_SIZE_TO_WIDTH: Record<ActionSize, ButtonProps['style']> = {
  S: { width: '50px' },
  M: { width: '80px' },
  L: { width: '110px' },
  XS: { width: '140px' },
  XL: { width: '170px' }
};

export type Action<TLocation extends string = string, P extends AnyRecord = AnyRecord> = {
  id: string;
  lastUpdatedAt: string;
  widgetTypeId: ComponentEnumKey;
  objectId: string;
  commandId: ActionCommands;
  confirmation?: boolean;
  locationId: TLocation;
  label: string;
  color: { backgroundColor: string; color: string };
  size: ActionSize;
  order: number;
  separator: boolean; // below this element
  gridStateId: GridsDocType['id'];
  parentId?: Action['id'];
} & CollectionWithSchemaPayload<P> &
  ShareableCollectionType;
