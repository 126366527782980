import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('CANCEL_EXECUTIONS_FORM_INPUT', {
  version: 0,
  schema: z
    .object({
      orderId: z.string(),
      executionIds: z.array(z.string())
    })
    .describe('Cancel executions input schema')
});
