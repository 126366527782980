import type { AppWindowActorSchema } from '@app/app-config/workspace.config';
import { RouteOrderService } from '@app/data-access/services/trading/routing/route-order.service';
import type { AtdlFieldPartial } from '@app/forms/fixatdl/components/ddf-transformer/controls/control.transformer.types';
import { transformToDdf } from '@app/forms/fixatdl/components/ddf-transformer/ddf.transformer';
import { testScoped } from '@app/workspace.registry';
import type { StrategyControlInput, StrategyParameterInput } from '@oms/generated/frontend';
import type { Strategy } from '@valstro/fixatdl-core';
import { Actor } from '@valstro/workspace';
import { inject, singleton } from 'tsyringe';
import { strategyValidationErrors$ } from './fixatdl-strategy-field.state';
import { getStrategyWindowId, transformParamsAndControlsToDdfValues } from './fixatdl-strategy-field.util';
@testScoped
@singleton()
export class StrategyService {
  constructor(@inject(RouteOrderService) private routingService: RouteOrderService) {}

  public async fetchFIXATDLJSON(
    venueId: string,
    strategyName: string,
    strategyParameters: StrategyParameterInput[] = [],
    strategyControls: StrategyControlInput[] = []
  ): Promise<AtdlFieldPartial[]> {
    const res = await this.routingService.getAtdlForExecutionVenueFilterByStrategy(venueId, strategyName);

    if (res.isSuccess()) {
      const strategy = (res.value.data.getATDLForExecutionVenueFilterByStrategy?.strategies?.[0] ||
        {}) as Strategy;

      return transformToDdf(
        strategy,
        transformParamsAndControlsToDdfValues(strategyParameters, strategyControls)
      );
    }

    return [];
  }

  public closeStrategyDialog(formId: string, strategyName?: string) {
    strategyValidationErrors$.next([]);
    Actor.get<AppWindowActorSchema>(getStrategyWindowId(formId, strategyName))
      .then((actor) => {
        actor.operations.close().catch(() => {
          // ignore, might be already closed
        });
      })
      .catch(() => {
        // ignore, might be already closed
      });
  }
}
