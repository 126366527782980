import type { ChargesRollupInfoFragment, CompositeChargeInfoFragment } from '@oms/generated/frontend';
import gql from 'graphql-tag';
import type { InvestorOrderColumnData } from './investor-order-monitor.columns';

// Charges are currently not available through Table Server
// Temporarily we have to manually define the types and queries
export type InvestorOrderWithChargesRow = InvestorOrderColumnData & {
  compositeCharges: [CompositeChargeInfoFragment];
  chargesRollup: ChargesRollupInfoFragment;
};

const ChargesRollup = [
  'chargeName',
  'scheduleName',
  'chargeGroup',
  'tradingDay',
  'amount',
  'aggregateId',
  'aggregateType'
].join(' ');

const charges = `
  compositeCharges {
    id
    amount
    chargeGroup
    chargeName
    chargeType
    date
    rateType
    rateValue
    scheduleName
    figuration {
      id
      chargeSchedule {
        id
        name
        charge {
          id
          name
          description
          chargeGroup
          applyTo
          type
        }
        scaleSchedule {
          name
          scale {
            id
            type
            basis
            rateType
            rows {
              id
              from
              to
              rateValue
              costValue
              plusValue
            }
          }
        }
        rateType
        rateValue
        rateBasis
        minimumChargeValue
        maximumChargeValue
      }
      isManual
      manualCharge {
        id
        name
        description
        chargeGroup
        applyTo
        type
      }
      manualRateType
      manualRateValue
      manualCostValue
      manualPlusValue
    }
  }
  chargesRollup {
    aggregateId
    aggregateType
    today {
      ${ChargesRollup}
    }
    total {
      ${ChargesRollup}
    }
    daily {
      tradingDay
      charges {
        ${ChargesRollup}
      }
    }
  }`;

export const TsInvestorOrdersWithChargesDocument = gql`
  subscription OnTsInvestorOrdersWithFilter(
    $filterBy: String!
    $sortBy: String!
    $limit: Int!
    $offset: Int!
  ) {
    tsInvestorOrdersWithFilter(filterBy: $filterBy, limit: $limit, offset: $offset, sortBy: $sortBy) {
      ... on TsInvestorOrderQueryResult {
        rows {
          ${charges}
          id
          instrument
          instrumentDisplayCode
          instrumentLongName
          instrumentCusip
          instrumentExchCode
          side
          locate
          orderType
          totalQuantity
          leavesQuantity
          openQuantity
          executedQuantity
          workingQuantity
          stopPrice
          investorAccountId
          investorAccountName
          validatedTimestamp
          receivedTimestamp
          gtdTimestamp
          commissionRateType
          commissionRateValue
          feesTotal
          marketChargeTotal
          todayExecutedQuantity
          todayAveragePrice
          clientOrderId
          orderEntryType
          orderComments
          sendingDesk
          underlyingAccount
          lastExecutionTime
          workflow
          fixWorkflowId
          settleCurrency
          settleType
          settleDate
          tradeCurrency
          timeInForce
          status
          averagePrice
          createdTimestamp
          transmittedTimestamp
          visibilityReason
          customerNotes
          representativeCodeId
          representativeCode
          limitPrice
          orderTagIds
          orderTagNames
          expiryDateTime
          ownerID
          ownerName
          ownerEmail
          enteredBy
          enteredByEmail
          updatedTime
          investorAccountType
          parentTradingAccountId
          tradingOrderIds
          parentTradingOrderId
          captureTimestamp
          notionalAmount
        }
        queryInfo {
          totalCount
          queryCount
        }
      }
      ... on TsInvestorOrderPatch {
        patch
        queryInfo {
          totalCount
          queryCount
        }
      }
    }
  }
`;

export type TsInvestorOrdersWithChargesSubscription = {
  tsInvestorOrdersWithFilter: {
    rows?: InvestorOrderWithChargesRow[];
    patch?: string;
    queryInfo?: {
      totalCount: number;
      queryCount: number;
    };
  };
};
