import { createVersionedSchema } from '@oms/frontend-schemas';
import type { VersionedSchemaExport, VersionedSchemaOptions } from '@oms/frontend-schemas';
import { z } from 'zod';
import type { AppSubSchemaType } from './sub-schema.registry';

export function createAppVersionedSchema<
  T extends z.ZodType<any>,
  TFrom extends VersionedSchemaExport<any, any> = any
>(key: AppSubSchemaType, info: VersionedSchemaOptions<T, TFrom>) {
  return createVersionedSchema<T, TFrom>(key, info);
}
