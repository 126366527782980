import { AppDatabase } from '@app/data-access/offline/app-database';
import { testScoped } from '@app/workspace.registry';
import omit from 'lodash/omit';
import type { MangoQuerySelector } from 'rxdb-v15';
import type { Observable } from 'rxjs';
import { firstValueFrom, map } from 'rxjs';
import { delay, inject, singleton } from 'tsyringe';
import type { Action } from '../types/action.types';

export type ActionsBy = MangoQuerySelector<Action>;

@testScoped
@singleton()
export class ActionConfigService {
  constructor(@inject(delay(() => AppDatabase)) private db: AppDatabase) {}

  /**
   * Query object for actions from rxdb.
   * @returns An mutable query for actions
   */
  public get actions(): AppDatabase['memoryDb']['collections']['actions'] {
    return this.db.memory.actions_temporary;
  }

  public static idOf(a: Action): string {
    return `${a.widgetTypeId}|${a.commandId}|${a.objectId}|${a.order}|${a.locationId}|${a.gridStateId}`;
  }

  public static idsOf(...actions: Action[]): string[] {
    return actions.map((a) => ActionConfigService.idOf(a));
  }

  /**
   * Convert flat list of actions in parent / child tree structure.
   * @param actions actions with possible parent / child relationship
   * @returns actions in a parent / child tree structure.
   */
  public static byGroup(actions: Action[]): (Action & { children: Action[] })[] {
    const actionsByGroup: Record<string, Action[]> = {};

    actions.forEach((a) => {
      const lookupId = a.parentId || a.id;
      actionsByGroup[lookupId] = actionsByGroup[lookupId] || [];
      actionsByGroup[lookupId].push(a);
    });

    return Object.entries(actionsByGroup).reduce(
      (result, curr) => {
        const [actionId, actions] = curr;

        if (actions.length > 1) {
          const parentIdx = actions.findIndex((a) => a.id === actionId);
          const parent = actions.splice(parentIdx, 1);
          const groupId = ActionConfigService.idOf(parent[0]);

          result.push({
            ...omit(parent[0], 'children'),
            id: groupId,
            children: actions.map((a) => ({
              ...omit(a, 'children'),
              parentId: groupId,
              id: ActionConfigService.idOf(a)
            }))
          });
        } else {
          result.push({ ...actions[0], id: ActionConfigService.idOf(actions[0]), children: [] });
        }

        return result;
      },
      [] as ReturnType<typeof ActionConfigService.byGroup>
    );
  }

  /**
   * Queries Actions for a particular filter.
   * @param filter Used to filter actions by columns.
   * @returns An array of Actions.
   */
  public async actionsBy(filter: ActionsBy): Promise<Action[]> {
    const queryResult = await firstValueFrom(
      this.actionsBy$({
        ...filter,
        subSchemaInvalid: { $ne: true }
      })
    );
    return queryResult;
  }

  /**
   * Creates an observable that updates when the actions tied to your filter change.
   * @param filter Used to filter actions by columns.
   * @returns An observable of any actions that meet the filter criteria.
   */
  public actionsBy$(filter: ActionsBy): Observable<Action[]> {
    const query = this.actions;

    return query
      .find({
        selector: {
          ...filter,
          subSchemaInvalid: { $ne: true }
        },
        sort: [{ order: 'asc' }]
      })
      .$.pipe(map((actions) => actions.map((a) => a.toMutableJSON())));
  }

  /**
   * Figures out which actions need to be upserted or deleted
   * @param by Query to filter actions by; Actions in the list that do not match will be ignored.
   * @param actions Actions to be upserted or deleted
   * @returns An object that contains a list of rows to be upserted and deleted.
   */
  public async merge(by: ActionsBy, ...actions: Action[]): Promise<{ upserts: Action[]; deletes: Action[] }> {
    const { widgetTypeId, locationId, objectId } = by;
    const upserts: Action[] = [];
    const deletes: Action[] = [];
    const uniqueActionMap: Map<string, Action> = new Map();
    const existingActions = await this.actionsBy(by);

    actions.forEach((a) => {
      if (locationId === a.locationId && objectId === a.objectId && widgetTypeId === a.widgetTypeId) {
        uniqueActionMap.set(ActionConfigService.idOf(a), a);
      }
    });

    existingActions.forEach((a) => {
      const actionId = ActionConfigService.idOf(a);
      if (!uniqueActionMap.has(actionId)) {
        deletes.push(a);
      }
    });

    upserts.push(...uniqueActionMap.values());

    return { upserts, deletes };
  }

  /**
   * Updates & inserts actions into rxdb collection.
   * @param actions Actions to insert / update.
   * @returns The new / modified docs.
   */
  public async upsert(...actions: Action[]): ReturnType<typeof this.actions.bulkUpsert> {
    return await this.actions.bulkUpsert(actions.map((a) => ({ ...a, id: ActionConfigService.idOf(a) })));
  }

  /**
   * Bulk deletes actions
   * @param actions Actions to delete
   * @returns Which actions were deleted successfully and which ones had errors.
   */
  public async delete(...actions: Action[]): ReturnType<typeof this.actions.bulkRemove> {
    return await this.actions.bulkRemove(ActionConfigService.idsOf(...actions));
  }
}
