import type { SubSchemaRegistry } from '@oms/frontend-schemas';
import { SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI } from './snapshot.schema-registry.cli';
import { VERSIONED_SCHEMAS as timeAndSalesVersionedSchemas } from '@app/widgets/marketdata/time-and-sales/schema.time-and-sales.widget';
import { VERSIONED_SCHEMAS as currenciesGridVersionedSchemas } from '@app/widgets/reference-data/currencies/schema.currencies-grid.widget';
import { VERSIONED_SCHEMAS as exchangesGridVersionedSchemas } from '@app/widgets/reference-data/exchanges/schema.exchanges-grid.widget';
import { VERSIONED_SCHEMAS as instrumentRestrictionsGridVersionedSchemas } from '@app/widgets/reference-data/instrument-restrictions/schema.instrument-restrictions.widget';
import { VERSIONED_SCHEMAS as instrumentsVersionedSchemas } from '@app/widgets/reference-data/instruments/schema.instruments-grid.widget';
import { VERSIONED_SCHEMAS as aboutValstroVersionedSchemas } from '@app/widgets/system/about/schema.about-valstro.widget';
import { VERSIONED_SCHEMAS as systemLauncherVersionedSchemas } from '@app/widgets/system/launcher/schema.launcher.widget';
import { VERSIONED_SCHEMAS as systemPlaceholderVersionedSchemas } from '@app/widgets/system/placeholder/schema.placeholder.widget';
import { VERSIONED_SCHEMAS as systemRemoteFormVersionedSchemas } from '@app/widgets/system/remote-form/schema.remote-form.widget';
import { VERSIONED_SCHEMAS as systemImportSnapshotVersionedSchemas } from '@app/widgets/system/snapshots/schema.import-snaphot.widget';
import { VERSIONED_SCHEMAS as systemLayoutsVersionedSchemas } from '@app/widgets/system/snapshots/schema.layouts.widget';
import { VERSIONED_SCHEMAS as systemSnapshotsVersionedSchemas } from '@app/widgets/system/snapshots/schema.snapshots.widget';
import { VERSIONED_SCHEMAS as eventDetailsVersionedSchemas } from '@app/widgets/trading/event-details/schema.event-details.widget';
import { VERSIONED_SCHEMAS as investorOrderMonitorGridVersionedSchemas } from '@app/widgets/trading/investor-order-monitor/schema.investor-order-monitor.widget';
import { VERSIONED_SCHEMAS as ioViewOrderInformationVersionedSchemas } from '@app/widgets/trading/investor-order-view/schema.investor-order-view.order-information.widget';
import { VERSIONED_SCHEMAS as ioViewSidePanelVersionedSchemas } from '@app/widgets/trading/investor-order-view/schema.investor-order-view.side-panel.widget';
import { VERSIONED_SCHEMAS as ioViewTabbedGridsVersionedSchemas } from '@app/widgets/trading/investor-order-view/schema.investor-order-view.tabbed-grids.widget';
import { VERSIONED_SCHEMAS as ioViewTabbedSummariesVersionedSchemas } from '@app/widgets/trading/investor-order-view/schema.investor-order-view.tabbed-summaries.widget';
import { VERSIONED_SCHEMAS as mappableOrdersVersionedSchemas } from '@app/widgets/trading/mappable-orders/schema.mappable-orders.widget';
import { VERSIONED_SCHEMAS as montageDataPanelVersionedSchemas } from '@app/widgets/trading/montage/schema.montage.data.panel.widget';
import { VERSIONED_SCHEMAS as montageSidePanelVersionedSchemas } from '@app/widgets/trading/montage/schema.montage.side-panel.widget';
import { VERSIONED_SCHEMAS as newOrdersGridVersionedSchemas } from '@app/widgets/trading/new-orders/schema.new-orders.grid.widget';
import { VERSIONED_SCHEMAS as newOrdersSidePanelVersionedSchemas } from '@app/widgets/trading/new-orders/schema.new-orders.side-panel.widget';
import { VERSIONED_SCHEMAS as notificationsVersionedSchemas } from '@app/widgets/trading/notifications/schema.notifications.widget';
import { VERSIONED_SCHEMAS as orderVwapVersionedSchemas } from '@app/widgets/trading/order-vwap/schema.order-vwap.widget';
import { VERSIONED_SCHEMAS as pendingModificationsGridVersionedSchemas } from '@app/widgets/trading/pending-modifications/schema.pending-modifications.grid.widget';
import { VERSIONED_SCHEMAS as pendingModificationsSidePanelVersionedSchemas } from '@app/widgets/trading/pending-modifications/schema.pending-modifications.side-panel.widget';
import { VERSIONED_SCHEMAS as repairQueueInvestorOrdersGridVersionedSchemas } from '@app/widgets/trading/repair-queue/schema.repair-queue.investor-orders.widget';
import { VERSIONED_SCHEMAS as repairQueueTradesGridVersionedSchemas } from '@app/widgets/trading/repair-queue/schema.repair-queue.trades.widget';
import { VERSIONED_SCHEMAS as tradesMonitorGridVersionedSchemas } from '@app/widgets/trading/trades-monitor/schema.trades-monitor.widget';
import { VERSIONED_SCHEMAS as tradingOrderMonitorGridVersionedSchemas } from '@app/widgets/trading/trading-order-monitor/schema.trading-order-monitor.widget';
import { VERSIONED_SCHEMAS as toViewOrderInformationVersionedSchemas } from '@app/widgets/trading/trading-order-view/schema.trading-order-view.order-information.widget';
import { VERSIONED_SCHEMAS as toViewSidePanelVersionedSchemas } from '@app/widgets/trading/trading-order-view/schema.trading-order-view.side-panel.widget';
import { VERSIONED_SCHEMAS as toViewTabbedGridsVersionedSchemas } from '@app/widgets/trading/trading-order-view/schema.trading-order-view.tabbed-grids.widget';
import { VERSIONED_SCHEMAS as toViewTabbedSummariesVersionedSchemas } from '@app/widgets/trading/trading-order-view/schema.trading-order-view.tabbed-summaries.widget';
import { VERSIONED_SCHEMAS as userPreferencesVersionedSchemas } from '@app/widgets/user/user-preferences/schema.user-preferences.widget';
import { VERSIONED_SCHEMAS as montageToolbarVersionedSchemas } from '@app/widgets/trading/montage/montage-toolbar/schema.montage-toolbar.widget';
import { VERSIONED_SCHEMAS as strategyParametersVersionedSchemas } from '@app/widgets/trading/route-order/fixatdl-strategy-field/schema.fixatdl-strategy-parameters.widget';
import { VERSIONED_SCHEMAS as positionsAccountGridVersionedSchemas } from '@app/widgets/trading/positions/grids/account/schema.positions-account-grid.widget';
import { VERSIONED_SCHEMAS as positionsInstrumentGridVersionedSchemas } from '@app/widgets/trading/positions/grids/instrument/schema.positions-instrument-grid.widget';
import { VERSIONED_SCHEMAS as notificationsSettingsVersionedSchemas } from '@app/widgets/user/user-preferences/preferences/notification-settings/schema.notification-settings.widget';
import { VERSIONED_SCHEMAS as investorOrderMonitorVersionedSchemas } from '@app/widgets/trading/investor-order-monitor/schema.investor-order-monitor.layout';
import { VERSIONED_SCHEMAS as investorOrderViewVersionedSchemas } from '@app/widgets/trading/investor-order-view/schema.investor-order-view.layout';
import { VERSIONED_SCHEMAS as montageVersionedSchemas } from '@app/widgets/trading/montage/schema.montage.layout';
import { VERSIONED_SCHEMAS as newOrdersVersionedSchemas } from '@app/widgets/trading/new-orders/schema.new-orders.layout';
import { VERSIONED_SCHEMAS as pendingModificationsVersionedSchemas } from '@app/widgets/trading/pending-modifications/schema.pending-modifications.layout';
import { VERSIONED_SCHEMAS as repairQueueVersionedSchemas } from '@app/widgets/trading/repair-queue/schema.repair-queue.layout';
import { VERSIONED_SCHEMAS as tradesMonitorVersionedSchemas } from '@app/widgets/trading/trades-monitor/schema.trades-monitor.layout';
import { VERSIONED_SCHEMAS as tradingOrderMonitorVersionedSchemas } from '@app/widgets/trading/trading-order-monitor/schema.trading-order-monitor.layout';
import { VERSIONED_SCHEMAS as tradingOrderViewVersionedSchemas } from '@app/widgets/trading/trading-order-view/schema.trading-order-view.layout';
import { VERSIONED_SCHEMAS as positionsVersionedSchemas } from '@app/widgets/trading/positions/layouts/schema.positions.layout';
import { VERSIONED_SCHEMAS as actionButtonFormInputVersionedSchemas } from '@app/actions/forms/action-button/schema.action-button.form-builder.input';
import { VERSIONED_SCHEMAS as actionButtonFormValuesVersionedSchemas } from '@app/actions/forms/action-button/schema.action-button.form-builder.values';
import { VERSIONED_SCHEMAS as actionButtonLayoutFormInputVersionedSchemas } from '@app/actions/forms/action-button-layout/schema.action-button-layout.form-builder.input';
import { VERSIONED_SCHEMAS as actionButtonLayoutFormValuesVersionedSchemas } from '@app/actions/forms/action-button-layout/schema.action-button-layout.form-builder.values';
import { VERSIONED_SCHEMAS as currencyFormFormInputVersionedSchemas } from '@app/widgets/reference-data/currencies/schema.currency.form-builder.input';
import { VERSIONED_SCHEMAS as currencyFormFormValuesVersionedSchemas } from '@app/widgets/reference-data/currencies/schema.currency.form-builder.values';
import { VERSIONED_SCHEMAS as exchangeFormFormInputVersionedSchemas } from '@app/widgets/reference-data/exchanges/schema.exchange.form-builder.input';
import { VERSIONED_SCHEMAS as exchangeFormFormValuesVersionedSchemas } from '@app/widgets/reference-data/exchanges/schema.exchange.form-builder.values';
import { VERSIONED_SCHEMAS as instrumentRestrictionEntryFormInputVersionedSchemas } from '@app/widgets/reference-data/instrument-restriction-entry/schema.instrument-restriction-entry.form-builder.input';
import { VERSIONED_SCHEMAS as instrumentRestrictionEntryFormValuesVersionedSchemas } from '@app/widgets/reference-data/instrument-restriction-entry/schema.instrument-restriction-entry.form-builder.values';
import { VERSIONED_SCHEMAS as saveSnapshotFormFormInputVersionedSchemas } from '@app/widgets/system/snapshots/schema.save-snapshot.form-builder.input';
import { VERSIONED_SCHEMAS as saveSnapshotFormFormValuesVersionedSchemas } from '@app/widgets/system/snapshots/schema.save-snapshot.form-builder.values';
import { VERSIONED_SCHEMAS as crossPrincipalFillFormInputVersionedSchemas } from '@app/widgets/trading/cross-principal-fill/schema.cross-principal-fill.form-builder.input';
import { VERSIONED_SCHEMAS as crossPrincipalFillFormValuesVersionedSchemas } from '@app/widgets/trading/cross-principal-fill/schema.cross-principal-fill.form-builder.values';
import { VERSIONED_SCHEMAS as investorOrderEntryFormInputVersionedSchemas } from '@app/widgets/trading/investor-order-entry/schema.investor-order-entry.form-builder.input';
import { VERSIONED_SCHEMAS as investorOrderEntryFormValuesVersionedSchemas } from '@app/widgets/trading/investor-order-entry/schema.investor-order-entry.form-builder.values';
import { VERSIONED_SCHEMAS as principleFillFormFormInputVersionedSchemas } from '@app/widgets/trading/principal-fill/schema.principal-fill.form-builder.input';
import { VERSIONED_SCHEMAS as principleFillFormFormValuesVersionedSchemas } from '@app/widgets/trading/principal-fill/schema.principal-fill.form-builder.values';
import { VERSIONED_SCHEMAS as routeOrderFormInputVersionedSchemas } from '@app/widgets/trading/route-order/schema.route-order.form-builder.input';
import { VERSIONED_SCHEMAS as routeOrderFormValuesVersionedSchemas } from '@app/widgets/trading/route-order/schema.route-order.form-builder.values';
import { VERSIONED_SCHEMAS as tradeModifyFormInputVersionedSchemas } from '@app/widgets/trading/trade-modify/schema.trade-modify.form-builder.input';
import { VERSIONED_SCHEMAS as tradeModifyFormValuesVersionedSchemas } from '@app/widgets/trading/trade-modify/schema.trade-modify.form-builder.values';
import { VERSIONED_SCHEMAS as rejectInvestorOrderFormInputVersionedSchemas } from '@app/widgets/trading/investor-order-monitor/grid-forms/schema.reject-investor-order.form-builder.input';
import { VERSIONED_SCHEMAS as rejectInvestorOrderFormValuesVersionedSchemas } from '@app/widgets/trading/investor-order-monitor/grid-forms/schema.reject-investor-order.form-builder.values';
import { VERSIONED_SCHEMAS as applySettlementFxRateFormInputVersionedSchemas } from '@app/widgets/trading/investor-order-view/grid-forms/schema.apply-settlement-fx-rate.form-builder.input';
import { VERSIONED_SCHEMAS as applySettlementFxRateFormValuesVersionedSchemas } from '@app/widgets/trading/investor-order-view/grid-forms/schema.apply-settlement-fx-rate.form-builder.values';
import { VERSIONED_SCHEMAS as cancelExecutionsFormInputVersionedSchemas } from '@app/widgets/trading/investor-order-view/grid-forms/schema.cancel-executions.form-builder.input';
import { VERSIONED_SCHEMAS as cancelExecutionsFormValuesVersionedSchemas } from '@app/widgets/trading/investor-order-view/grid-forms/schema.cancel-executions.form-builder.values';
import { VERSIONED_SCHEMAS as orderVwapFormFormInputVersionedSchemas } from '@app/widgets/trading/order-vwap/form/schema.order-vwap.form-builder.input';
import { VERSIONED_SCHEMAS as orderVwapFormFormValuesVersionedSchemas } from '@app/widgets/trading/order-vwap/form/schema.order-vwap.form-builder.values';
import { VERSIONED_SCHEMAS as rejectPendingModificationFormInputVersionedSchemas } from '@app/widgets/trading/pending-modifications/grid-forms/schema.reject-pending-modification.form-builder.input';
import { VERSIONED_SCHEMAS as rejectPendingModificationFormValuesVersionedSchemas } from '@app/widgets/trading/pending-modifications/grid-forms/schema.reject-pending-modification.form-builder.values';
import { VERSIONED_SCHEMAS as bulkRepairTradeFormInputVersionedSchemas } from '@app/widgets/trading/repair-queue/bulk-repair-trade/schema.bulk-repair-trade.form-builder.input';
import { VERSIONED_SCHEMAS as bulkRepairTradeFormValuesVersionedSchemas } from '@app/widgets/trading/repair-queue/bulk-repair-trade/schema.bulk-repair-trade.form-builder.values';
import { VERSIONED_SCHEMAS as enableQuoteFormInputVersionedSchemas } from '@app/widgets/trading/montage/depth-grid/forms/schema.enable-quote.form-builder.input';
import { VERSIONED_SCHEMAS as enableQuoteFormValuesVersionedSchemas } from '@app/widgets/trading/montage/depth-grid/forms/schema.enable-quote.form-builder.values';
import { VERSIONED_SCHEMAS as montageFiltersFormFormInputVersionedSchemas } from '@app/widgets/trading/montage/montage-toolbar/montage-filters.form/schema.montage-filters.form-builder.input';
import { VERSIONED_SCHEMAS as montageFiltersFormFormValuesVersionedSchemas } from '@app/widgets/trading/montage/montage-toolbar/montage-filters.form/schema.montage-filters.form-builder.values';
import { VERSIONED_SCHEMAS as mockUserSettingsFormInputVersionedSchemas } from '@app/widgets/user/user-preferences/preferences/mock-user-settings/schema.mock-user-settings.form-builder.input';
import { VERSIONED_SCHEMAS as mockUserSettingsFormValuesVersionedSchemas } from '@app/widgets/user/user-preferences/preferences/mock-user-settings/schema.mock-user-settings.form-builder.values';
import { VERSIONED_SCHEMAS as montageSettingsFormInputVersionedSchemas } from '@app/widgets/user/user-preferences/preferences/montage-settings/schema.montage-settings.form-builder.input';
import { VERSIONED_SCHEMAS as montageSettingsFormValuesVersionedSchemas } from '@app/widgets/user/user-preferences/preferences/montage-settings/schema.montage-settings.form-builder.values';
import { VERSIONED_SCHEMAS as orderSettingsFormInputVersionedSchemas } from '@app/widgets/user/user-preferences/preferences/order-settings/schema.order-settings.form-builder.input';
import { VERSIONED_SCHEMAS as orderSettingsFormValuesVersionedSchemas } from '@app/widgets/user/user-preferences/preferences/order-settings/schema.order-settings.form-builder.values';
import { VERSIONED_SCHEMAS as montageBumpPriceFormInputVersionedSchemas } from '@app/widgets/trading/montage/commands/bump-price/form/schema.bump-price.form-builder.input';
import { VERSIONED_SCHEMAS as montageBumpPriceFormValuesVersionedSchemas } from '@app/widgets/trading/montage/commands/bump-price/form/schema.bump-price.form-builder.values';

export const SNAPSHOT_SUB_SCHEMA_REGISTRY: SubSchemaRegistry = {
  TIME_AND_SALES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.TIME_AND_SALES!,
    versionedSchemas: timeAndSalesVersionedSchemas
  },
  CURRENCIES_GRID: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.CURRENCIES_GRID!,
    versionedSchemas: currenciesGridVersionedSchemas
  },
  EXCHANGES_GRID: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.EXCHANGES_GRID!,
    versionedSchemas: exchangesGridVersionedSchemas
  },
  INSTRUMENT_RESTRICTIONS_GRID: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.INSTRUMENT_RESTRICTIONS_GRID!,
    versionedSchemas: instrumentRestrictionsGridVersionedSchemas
  },
  INSTRUMENTS: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.INSTRUMENTS!,
    versionedSchemas: instrumentsVersionedSchemas
  },
  ABOUT_VALSTRO: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ABOUT_VALSTRO!,
    versionedSchemas: aboutValstroVersionedSchemas
  },
  SYSTEM_LAUNCHER: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.SYSTEM_LAUNCHER!,
    versionedSchemas: systemLauncherVersionedSchemas
  },
  SYSTEM_PLACEHOLDER: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.SYSTEM_PLACEHOLDER!,
    versionedSchemas: systemPlaceholderVersionedSchemas
  },
  SYSTEM_REMOTE_FORM: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.SYSTEM_REMOTE_FORM!,
    versionedSchemas: systemRemoteFormVersionedSchemas
  },
  SYSTEM_IMPORT_SNAPSHOT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.SYSTEM_IMPORT_SNAPSHOT!,
    versionedSchemas: systemImportSnapshotVersionedSchemas
  },
  SYSTEM_LAYOUTS: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.SYSTEM_LAYOUTS!,
    versionedSchemas: systemLayoutsVersionedSchemas
  },
  SYSTEM_SNAPSHOTS: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.SYSTEM_SNAPSHOTS!,
    versionedSchemas: systemSnapshotsVersionedSchemas
  },
  EVENT_DETAILS: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.EVENT_DETAILS!,
    versionedSchemas: eventDetailsVersionedSchemas
  },
  INVESTOR_ORDER_MONITOR_GRID: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.INVESTOR_ORDER_MONITOR_GRID!,
    versionedSchemas: investorOrderMonitorGridVersionedSchemas
  },
  IO_VIEW_ORDER_INFORMATION: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.IO_VIEW_ORDER_INFORMATION!,
    versionedSchemas: ioViewOrderInformationVersionedSchemas
  },
  IO_VIEW_SIDE_PANEL: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.IO_VIEW_SIDE_PANEL!,
    versionedSchemas: ioViewSidePanelVersionedSchemas
  },
  IO_VIEW_TABBED_GRIDS: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.IO_VIEW_TABBED_GRIDS!,
    versionedSchemas: ioViewTabbedGridsVersionedSchemas
  },
  IO_VIEW_TABBED_SUMMARIES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.IO_VIEW_TABBED_SUMMARIES!,
    versionedSchemas: ioViewTabbedSummariesVersionedSchemas
  },
  MAPPABLE_ORDERS: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.MAPPABLE_ORDERS!,
    versionedSchemas: mappableOrdersVersionedSchemas
  },
  MONTAGE_DATA_PANEL: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.MONTAGE_DATA_PANEL!,
    versionedSchemas: montageDataPanelVersionedSchemas
  },
  MONTAGE_SIDE_PANEL: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.MONTAGE_SIDE_PANEL!,
    versionedSchemas: montageSidePanelVersionedSchemas
  },
  NEW_ORDERS_GRID: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.NEW_ORDERS_GRID!,
    versionedSchemas: newOrdersGridVersionedSchemas
  },
  NEW_ORDERS_SIDE_PANEL: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.NEW_ORDERS_SIDE_PANEL!,
    versionedSchemas: newOrdersSidePanelVersionedSchemas
  },
  NOTIFICATIONS: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.NOTIFICATIONS!,
    versionedSchemas: notificationsVersionedSchemas
  },
  ORDER_VWAP: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ORDER_VWAP!,
    versionedSchemas: orderVwapVersionedSchemas
  },
  PENDING_MODIFICATIONS_GRID: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.PENDING_MODIFICATIONS_GRID!,
    versionedSchemas: pendingModificationsGridVersionedSchemas
  },
  PENDING_MODIFICATIONS_SIDE_PANEL: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.PENDING_MODIFICATIONS_SIDE_PANEL!,
    versionedSchemas: pendingModificationsSidePanelVersionedSchemas
  },
  REPAIR_QUEUE_INVESTOR_ORDERS_GRID: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.REPAIR_QUEUE_INVESTOR_ORDERS_GRID!,
    versionedSchemas: repairQueueInvestorOrdersGridVersionedSchemas
  },
  REPAIR_QUEUE_TRADES_GRID: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.REPAIR_QUEUE_TRADES_GRID!,
    versionedSchemas: repairQueueTradesGridVersionedSchemas
  },
  TRADES_MONITOR_GRID: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.TRADES_MONITOR_GRID!,
    versionedSchemas: tradesMonitorGridVersionedSchemas
  },
  TRADING_ORDER_MONITOR_GRID: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.TRADING_ORDER_MONITOR_GRID!,
    versionedSchemas: tradingOrderMonitorGridVersionedSchemas
  },
  TO_VIEW_ORDER_INFORMATION: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.TO_VIEW_ORDER_INFORMATION!,
    versionedSchemas: toViewOrderInformationVersionedSchemas
  },
  TO_VIEW_SIDE_PANEL: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.TO_VIEW_SIDE_PANEL!,
    versionedSchemas: toViewSidePanelVersionedSchemas
  },
  TO_VIEW_TABBED_GRIDS: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.TO_VIEW_TABBED_GRIDS!,
    versionedSchemas: toViewTabbedGridsVersionedSchemas
  },
  TO_VIEW_TABBED_SUMMARIES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.TO_VIEW_TABBED_SUMMARIES!,
    versionedSchemas: toViewTabbedSummariesVersionedSchemas
  },
  USER_PREFERENCES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.USER_PREFERENCES!,
    versionedSchemas: userPreferencesVersionedSchemas
  },
  MONTAGE_TOOLBAR: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.MONTAGE_TOOLBAR!,
    versionedSchemas: montageToolbarVersionedSchemas
  },
  STRATEGY_PARAMETERS: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.STRATEGY_PARAMETERS!,
    versionedSchemas: strategyParametersVersionedSchemas
  },
  POSITIONS_ACCOUNT_GRID: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.POSITIONS_ACCOUNT_GRID!,
    versionedSchemas: positionsAccountGridVersionedSchemas
  },
  POSITIONS_INSTRUMENT_GRID: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.POSITIONS_INSTRUMENT_GRID!,
    versionedSchemas: positionsInstrumentGridVersionedSchemas
  },
  NOTIFICATIONS_SETTINGS: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.NOTIFICATIONS_SETTINGS!,
    versionedSchemas: notificationsSettingsVersionedSchemas
  },
  INVESTOR_ORDER_MONITOR: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.INVESTOR_ORDER_MONITOR!,
    versionedSchemas: investorOrderMonitorVersionedSchemas
  },
  INVESTOR_ORDER_VIEW: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.INVESTOR_ORDER_VIEW!,
    versionedSchemas: investorOrderViewVersionedSchemas
  },
  MONTAGE: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.MONTAGE!,
    versionedSchemas: montageVersionedSchemas
  },
  NEW_ORDERS: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.NEW_ORDERS!,
    versionedSchemas: newOrdersVersionedSchemas
  },
  PENDING_MODIFICATIONS: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.PENDING_MODIFICATIONS!,
    versionedSchemas: pendingModificationsVersionedSchemas
  },
  REPAIR_QUEUE: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.REPAIR_QUEUE!,
    versionedSchemas: repairQueueVersionedSchemas
  },
  TRADES_MONITOR: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.TRADES_MONITOR!,
    versionedSchemas: tradesMonitorVersionedSchemas
  },
  TRADING_ORDER_MONITOR: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.TRADING_ORDER_MONITOR!,
    versionedSchemas: tradingOrderMonitorVersionedSchemas
  },
  TRADING_ORDER_VIEW: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.TRADING_ORDER_VIEW!,
    versionedSchemas: tradingOrderViewVersionedSchemas
  },
  POSITIONS: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.POSITIONS!,
    versionedSchemas: positionsVersionedSchemas
  },
  ACTION_BUTTON_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ACTION_BUTTON_FORM_INPUT!,
    versionedSchemas: actionButtonFormInputVersionedSchemas
  },
  ACTION_BUTTON_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ACTION_BUTTON_FORM_VALUES!,
    versionedSchemas: actionButtonFormValuesVersionedSchemas
  },
  ACTION_BUTTON_LAYOUT_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ACTION_BUTTON_LAYOUT_FORM_INPUT!,
    versionedSchemas: actionButtonLayoutFormInputVersionedSchemas
  },
  ACTION_BUTTON_LAYOUT_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ACTION_BUTTON_LAYOUT_FORM_VALUES!,
    versionedSchemas: actionButtonLayoutFormValuesVersionedSchemas
  },
  CURRENCY_FORM_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.CURRENCY_FORM_FORM_INPUT!,
    versionedSchemas: currencyFormFormInputVersionedSchemas
  },
  CURRENCY_FORM_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.CURRENCY_FORM_FORM_VALUES!,
    versionedSchemas: currencyFormFormValuesVersionedSchemas
  },
  EXCHANGE_FORM_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.EXCHANGE_FORM_FORM_INPUT!,
    versionedSchemas: exchangeFormFormInputVersionedSchemas
  },
  EXCHANGE_FORM_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.EXCHANGE_FORM_FORM_VALUES!,
    versionedSchemas: exchangeFormFormValuesVersionedSchemas
  },
  INSTRUMENT_RESTRICTION_ENTRY_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.INSTRUMENT_RESTRICTION_ENTRY_FORM_INPUT!,
    versionedSchemas: instrumentRestrictionEntryFormInputVersionedSchemas
  },
  INSTRUMENT_RESTRICTION_ENTRY_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.INSTRUMENT_RESTRICTION_ENTRY_FORM_VALUES!,
    versionedSchemas: instrumentRestrictionEntryFormValuesVersionedSchemas
  },
  SAVE_SNAPSHOT_FORM_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.SAVE_SNAPSHOT_FORM_FORM_INPUT!,
    versionedSchemas: saveSnapshotFormFormInputVersionedSchemas
  },
  SAVE_SNAPSHOT_FORM_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.SAVE_SNAPSHOT_FORM_FORM_VALUES!,
    versionedSchemas: saveSnapshotFormFormValuesVersionedSchemas
  },
  CROSS_PRINCIPAL_FILL_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.CROSS_PRINCIPAL_FILL_FORM_INPUT!,
    versionedSchemas: crossPrincipalFillFormInputVersionedSchemas
  },
  CROSS_PRINCIPAL_FILL_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.CROSS_PRINCIPAL_FILL_FORM_VALUES!,
    versionedSchemas: crossPrincipalFillFormValuesVersionedSchemas
  },
  INVESTOR_ORDER_ENTRY_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.INVESTOR_ORDER_ENTRY_FORM_INPUT!,
    versionedSchemas: investorOrderEntryFormInputVersionedSchemas
  },
  INVESTOR_ORDER_ENTRY_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.INVESTOR_ORDER_ENTRY_FORM_VALUES!,
    versionedSchemas: investorOrderEntryFormValuesVersionedSchemas
  },
  PRINCIPLE_FILL_FORM_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.PRINCIPLE_FILL_FORM_FORM_INPUT!,
    versionedSchemas: principleFillFormFormInputVersionedSchemas
  },
  PRINCIPLE_FILL_FORM_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.PRINCIPLE_FILL_FORM_FORM_VALUES!,
    versionedSchemas: principleFillFormFormValuesVersionedSchemas
  },
  ROUTE_ORDER_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ROUTE_ORDER_FORM_INPUT!,
    versionedSchemas: routeOrderFormInputVersionedSchemas
  },
  ROUTE_ORDER_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ROUTE_ORDER_FORM_VALUES!,
    versionedSchemas: routeOrderFormValuesVersionedSchemas
  },
  TRADE_MODIFY_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.TRADE_MODIFY_FORM_INPUT!,
    versionedSchemas: tradeModifyFormInputVersionedSchemas
  },
  TRADE_MODIFY_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.TRADE_MODIFY_FORM_VALUES!,
    versionedSchemas: tradeModifyFormValuesVersionedSchemas
  },
  REJECT_INVESTOR_ORDER_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.REJECT_INVESTOR_ORDER_FORM_INPUT!,
    versionedSchemas: rejectInvestorOrderFormInputVersionedSchemas
  },
  REJECT_INVESTOR_ORDER_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.REJECT_INVESTOR_ORDER_FORM_VALUES!,
    versionedSchemas: rejectInvestorOrderFormValuesVersionedSchemas
  },
  APPLY_SETTLEMENT_FX_RATE_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.APPLY_SETTLEMENT_FX_RATE_FORM_INPUT!,
    versionedSchemas: applySettlementFxRateFormInputVersionedSchemas
  },
  APPLY_SETTLEMENT_FX_RATE_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.APPLY_SETTLEMENT_FX_RATE_FORM_VALUES!,
    versionedSchemas: applySettlementFxRateFormValuesVersionedSchemas
  },
  CANCEL_EXECUTIONS_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.CANCEL_EXECUTIONS_FORM_INPUT!,
    versionedSchemas: cancelExecutionsFormInputVersionedSchemas
  },
  CANCEL_EXECUTIONS_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.CANCEL_EXECUTIONS_FORM_VALUES!,
    versionedSchemas: cancelExecutionsFormValuesVersionedSchemas
  },
  ORDER_VWAP_FORM_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ORDER_VWAP_FORM_FORM_INPUT!,
    versionedSchemas: orderVwapFormFormInputVersionedSchemas
  },
  ORDER_VWAP_FORM_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ORDER_VWAP_FORM_FORM_VALUES!,
    versionedSchemas: orderVwapFormFormValuesVersionedSchemas
  },
  REJECT_PENDING_MODIFICATION_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.REJECT_PENDING_MODIFICATION_FORM_INPUT!,
    versionedSchemas: rejectPendingModificationFormInputVersionedSchemas
  },
  REJECT_PENDING_MODIFICATION_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.REJECT_PENDING_MODIFICATION_FORM_VALUES!,
    versionedSchemas: rejectPendingModificationFormValuesVersionedSchemas
  },
  BULK_REPAIR_TRADE_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.BULK_REPAIR_TRADE_FORM_INPUT!,
    versionedSchemas: bulkRepairTradeFormInputVersionedSchemas
  },
  BULK_REPAIR_TRADE_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.BULK_REPAIR_TRADE_FORM_VALUES!,
    versionedSchemas: bulkRepairTradeFormValuesVersionedSchemas
  },
  ENABLE_QUOTE_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ENABLE_QUOTE_FORM_INPUT!,
    versionedSchemas: enableQuoteFormInputVersionedSchemas
  },
  ENABLE_QUOTE_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ENABLE_QUOTE_FORM_VALUES!,
    versionedSchemas: enableQuoteFormValuesVersionedSchemas
  },
  MONTAGE_FILTERS_FORM_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.MONTAGE_FILTERS_FORM_FORM_INPUT!,
    versionedSchemas: montageFiltersFormFormInputVersionedSchemas
  },
  MONTAGE_FILTERS_FORM_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.MONTAGE_FILTERS_FORM_FORM_VALUES!,
    versionedSchemas: montageFiltersFormFormValuesVersionedSchemas
  },
  MOCK_USER_SETTINGS_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.MOCK_USER_SETTINGS_FORM_INPUT!,
    versionedSchemas: mockUserSettingsFormInputVersionedSchemas
  },
  MOCK_USER_SETTINGS_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.MOCK_USER_SETTINGS_FORM_VALUES!,
    versionedSchemas: mockUserSettingsFormValuesVersionedSchemas
  },
  MONTAGE_SETTINGS_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.MONTAGE_SETTINGS_FORM_INPUT!,
    versionedSchemas: montageSettingsFormInputVersionedSchemas
  },
  MONTAGE_SETTINGS_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.MONTAGE_SETTINGS_FORM_VALUES!,
    versionedSchemas: montageSettingsFormValuesVersionedSchemas
  },
  ORDER_SETTINGS_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ORDER_SETTINGS_FORM_INPUT!,
    versionedSchemas: orderSettingsFormInputVersionedSchemas
  },
  ORDER_SETTINGS_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.ORDER_SETTINGS_FORM_VALUES!,
    versionedSchemas: orderSettingsFormValuesVersionedSchemas
  },
  MONTAGE_BUMP_PRICE_FORM_INPUT: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.MONTAGE_BUMP_PRICE_FORM_INPUT!,
    versionedSchemas: montageBumpPriceFormInputVersionedSchemas
  },
  MONTAGE_BUMP_PRICE_FORM_VALUES: {
    ...SNAPSHOT_SUB_SCHEMA_REGISTRY_CLI.MONTAGE_BUMP_PRICE_FORM_VALUES!,
    versionedSchemas: montageBumpPriceFormValuesVersionedSchemas
  }
} as const;
