import type { SummaryGridField } from '@oms/frontend-foundation';
import type { InvestorOrderRow, Strategy, StrategyParameter, TradingOrderRow } from '@oms/generated/frontend';
import { getDurationInMMSS } from '@app/forms/common/validators/validate-duration-input/validate-duration-input.validator';
import { mapSettleType } from '../mappers/map-settle-type';
import { get } from 'lodash';
import { AtdlFormats } from '@valstro/fixatdl-core';
import type { FormatType } from '@oms/shared-frontend/ui-design-system';

type FieldMetadata = {
  label: string;
  format?: FormatType;
  mapper?: (value: any) => string | undefined;
};

const ORDER_SUMMARY_FIELDS_MAP: Partial<
  Record<keyof TradingOrderRow | keyof InvestorOrderRow, FieldMetadata>
> = {
  id: { label: 'Order ID' },
  averagePrice: { label: 'Average price' },
  captureTimestamp: { label: 'Capture timestamp', format: 'datetime' },
  clientOrderId: { label: 'Client order ID', format: 'text' },
  createdTimestamp: { label: 'Created timestamp', format: 'datetime' },
  updatedTime: { label: 'Updated time', format: 'datetime' },
  lastExecutionTime: { label: 'Last execution time', format: 'datetime' },
  customerNotes: { label: 'Customer notes' },
  venueNotes: { label: 'Venue notes' },
  displayQuantity: { label: 'Display quantity', format: 'quantity' },
  enteredByEmail: { label: 'Entered By' },
  executedQuantity: { label: 'Executed quantity', format: 'quantity' },
  expiryDateTime: { label: 'Expiry dateTime', format: 'datetime' },
  fixMessage: { label: 'Fix message' },
  instrument: { label: 'Instrument ID' },
  instrumentDisplayCode: { label: 'Symbol' },
  investorAccountId: { label: 'Account ID' },
  investorAccountName: { label: 'Account name' },
  investorAccountType: { label: 'Account type' },
  tradingAccountName: { label: 'Account name' },
  tradingAccountType: { label: 'Account type' },
  leavesQuantity: { label: 'Leaves quantity', format: 'quantity' },
  limitPrice: { label: 'Limit price' },
  locate: { label: 'Locate' },
  minQuantity: { label: 'Min quantity', format: 'quantity' },
  notionalAmount: { label: 'Notional amount' },
  openQuantity: { label: 'Open quantity', format: 'quantity' },
  commissionRateType: { label: 'Commission rate type' },
  commissionRateValue: { label: 'Commission rate value' },
  feesTotal: { label: 'Fees total', format: 'price' },
  marketChargeTotal: { label: 'Market charges total', format: 'price' },
  orderComments: { label: 'Order comments' },
  orderEntryType: { label: 'Order entry type' },
  orderTagIds: { label: 'Order tags' },
  orderType: { label: 'Order type' },
  ownerEmail: { label: 'Owner' },
  totalQuantity: { label: 'Quantity', format: 'quantity' },
  representativeCode: { label: 'Representative code' },
  sendingDesk: { label: 'Sending desk' },
  settleCurrency: { label: 'Settle currency' },
  settleDate: { label: 'Settle date', format: 'date' },
  settleType: { label: 'Settle type', mapper: mapSettleType },
  side: { label: 'Side' },
  status: { label: 'Status' },
  stopPrice: { label: 'Stop price' },
  timeInForce: { label: 'Time in force' },
  tifDuration: { label: 'TIF Duration', mapper: getDurationInMMSS },
  tradeCurrency: { label: 'Trade currency' },
  transmittedTimestamp: { label: 'Transmitted timestamp', format: 'datetime' },
  updatedTimestamp: { label: 'Updated timestamp', format: 'datetime' },
  receivedTimestamp: { label: 'Received timestamp', format: 'datetime' },
  underlyingAccount: { label: 'Underlying account' },
  validatedTimestamp: { label: 'Validated timestamp', format: 'datetime' },
  workingQuantity: { label: 'Working quantity', format: 'quantity' },
  capacity: { label: 'Capacity' },
  venue: { label: 'Route to' },
  targetExchangeId: { label: 'Target exchange' },
  workflow: { label: 'Workflow' },
  fixWorkflowId: { label: 'FIX workflow ID' },
  todayExecutedQuantity: { label: "Today's executed quantity" },
  todayAveragePrice: { label: "Today's average price" },
  category: { label: 'Category' }
} as const;

export type OrderSummaryFieldName = keyof typeof ORDER_SUMMARY_FIELDS_MAP;

const orderSummaryFields = (fieldNames: OrderSummaryFieldName[]): SummaryGridField[] => {
  return fieldNames.map((fieldName) => {
    return {
      fieldName,
      ...(ORDER_SUMMARY_FIELDS_MAP[fieldName] as FieldMetadata)
    };
  });
};

const orderSummaryValues = (fieldNames: OrderSummaryFieldName[], order: Partial<TradingOrderRow>) => {
  return [
    fieldNames.reduce(
      (acc, fieldName) => {
        // Using const on the field name definition ensures we get type protection when specifying
        // the fields as a user, but using explicit types here under the hood is way to complex to appease
        // typescript :/ so I'm treating it as an explicit any
        const fieldDef = ORDER_SUMMARY_FIELDS_MAP[fieldName] as Record<string, any>;
        const mapper = fieldDef.mapper;
        // Using lodash's get instead of reimplementing nested fields with . again
        const fieldValue = get(order, fieldName, undefined);
        if (fieldValue === null || fieldValue === undefined) {
          return { ...acc, [fieldName]: null };
        }
        return { ...acc, [fieldName]: (mapper ? mapper(fieldValue) : '') || String(fieldValue) };
      },
      {} as Record<string, string>
    )
  ];
};

export const orderSummaryData = (
  label: string,
  fields: (keyof typeof ORDER_SUMMARY_FIELDS_MAP)[],
  order: Partial<TradingOrderRow>
) => {
  return {
    label,
    value: orderSummaryValues(fields, order),
    fields: orderSummaryFields(fields)
  };
};

export const orderStrategyParametersSummaryData = (label: string, order: TradingOrderRow) => {
  const strategy = order.strategyDetails
    ? (JSON.parse(order.strategyDetails as string) as Strategy)
    : undefined;

  const strategyName = strategy?.name || 'strategy-name';
  const fields: SummaryGridField[] = [
    {
      fieldName: strategyName,
      label: 'Strategy'
    }
  ];
  const data: Record<string, any> = { [strategyName]: strategy?.uirep || strategyName };

  (strategy?.parameters || []).forEach((param, index) => {
    const { name, label, value, uiRep } = param as StrategyParameter;
    const fieldValue = uiRep ?? AtdlFormats.coerceEmpty(value) ?? '-';

    const fieldName = name || `${strategyName}-${index}`;
    fields.push({
      fieldName,
      label: label || fieldName
    });
    data[fieldName] = fieldValue;
  }, [] as SummaryGridField[]);

  return { label, fields, value: [data] };
};
