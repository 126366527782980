import { Lifecycle, scoped, container, inject } from 'tsyringe';
import type { CellValueChangedEvent } from '@ag-grid-community/core';
import type { EventHandler, EventSource, GridEvent, VGridContextInstance } from '@oms/frontend-vgrid';
import { VGridInstance } from '@oms/frontend-vgrid';
import { NotificationSettingsService } from '@app/data-access/services/user/notification-settings.service';
import { openConfirmation } from '@app/generated/sdk';
import { AppWorkspace } from '@app/app-config/workspace.config';

@scoped(Lifecycle.ContainerScoped)
export class NotificatioSettingsGridEventHander implements EventHandler {
  public name = 'notification-settings-grid-events';

  constructor(
    @inject(VGridInstance.Context) private context: VGridContextInstance,
    @inject(AppWorkspace) private appWorkspace: AppWorkspace
  ) {}

  public addEvents(eventSource: EventSource<keyof GridEvent>): void {
    eventSource.add('onCellValueChanged', (e) => {
      this.onCellValueChanged(e).catch(console.error);
    });
  }

  private async onCellValueChanged(e: CellValueChangedEvent) {
    const notificationSettingsService = container.resolve(NotificationSettingsService);
    const updateResponse = await notificationSettingsService.updateDefaultNotificationSettings({
      notificationName: e.data.notificationName,
      [e.colDef.field as string]: e.newValue
    });

    if (updateResponse.isFailure()) {
      // trigger a refresh of the data
      notificationSettingsService.triggerFetch();

      openConfirmation(this.context.workspace, this.appWorkspace.getLeaderProcessId(), {
        title: 'Edit Notification Setting',
        componentProps: {
          message: 'Notification setting did not save. The latest saved results are being shown.',
          autoClose: true,
          hideCancelButton: true,
          confirmButtonText: 'Ok'
        }
      }).catch(console.error);
    }
  }

  public removeEvents(): void {}
}
