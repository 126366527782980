import {
  TimeInForce,
  InvestorOrderStatus,
  OrderSide,
  OrderCapacity,
  OrderType,
  OrderDiscretionaryType,
  OrderSettleType,
  HandlingInstruction,
  TradingOrderStatus,
  VisibilityReason,
  RestrictionTypeEnum,
  ChargeGroup,
  RateType,
  ScaleBasis,
  ScaleRateType,
  RateBasis,
  OrderSideType,
  OrderEntryType,
  DestinationType,
  CommissionType,
  PrimaryBenchmark,
  ModificationType,
  TradingOrderCategory
} from '@oms/generated/frontend';

/**
 * Mapping for commission types
 */
export const commissionTypeMapping = {
  [CommissionType.Absolute]: 'Absolute',
  [CommissionType.AmountPerUnit]: 'Per Unit',
  [CommissionType.BasicPoints]: 'Basic Points',
  [CommissionType.Percent]: 'Percent'
};

export const primaryBenchmarkMapping = {
  [PrimaryBenchmark.ArrivalPrice]: 'Arrival Price',
  [PrimaryBenchmark.DayVwap]: 'Day VWAP',
  [PrimaryBenchmark.Last]: 'Last',
  [PrimaryBenchmark.OpenPrice]: 'Open Price',
  [PrimaryBenchmark.OrderVwap]: 'Order VWAP',
  [PrimaryBenchmark.PreviousClose]: 'Previous Close',
  [PrimaryBenchmark.Unspecified]: 'Unspecified'
};

/**
 * Mapping for order types
 */
export const orderTypeMapping = {
  [OrderType.Market]: 'Market',
  [OrderType.Limit]: 'Limit',
  None: 'None'
};

/**
 * Mapping for Capacity
 */
export const capacityMapping = {
  [OrderCapacity.Agency]: 'Agency',
  [OrderCapacity.RisklessPrincipal]: 'Riskless Principal',
  [OrderCapacity.Principal]: 'Principal'
};

/**
 * Mapping for Capacity (Unbound TO)
 */
export const unboundCapacityMapping = {
  [OrderCapacity.Agency]: 'Agency',
  [OrderCapacity.Principal]: 'Principal'
};

/**
 * Mapping for SideType
 */
export const sideTypeMapping = {
  [OrderSideType.Btc]: 'BTC',
  [OrderSideType.Buy]: 'Buy',
  [OrderSideType.Exempt]: 'Exempt',
  [OrderSideType.Short]: 'Short',
  [OrderSideType.Sell]: 'Sell'
};

/**
 * Mapping for Destination Type
 */
export const destinationTypeMapping = {
  [DestinationType.PrimaryTrader]: 'Primary Trader',
  [DestinationType.Trader]: 'Trader',
  [DestinationType.InternalizationVenue]: 'Short',
  [DestinationType.Venue]: 'Short'
};

/**
 * Mapping for Order Status display text
 */
export const orderStatusMapping: Record<InvestorOrderStatus, string> = {
  [InvestorOrderStatus.Unaccepted]: 'Unaccepted',
  [InvestorOrderStatus.Active]: 'Active',
  [InvestorOrderStatus.FullyFilled]: 'Fully Filled',
  [InvestorOrderStatus.Rejected]: 'Rejected',
  [InvestorOrderStatus.Expired]: 'Expired',
  [InvestorOrderStatus.ExpiryPending]: 'Expiry Pending',
  [InvestorOrderStatus.CancellationPending]: 'Pending Cancel',
  [InvestorOrderStatus.ModificationPending]: 'Pending Modification',
  [InvestorOrderStatus.Cancelled]: 'Cancelled',
  [InvestorOrderStatus.Expirable]: 'Expirable',
  [InvestorOrderStatus.Inactive]: 'Inactive',
  [InvestorOrderStatus.Live]: 'Live',
  [InvestorOrderStatus.ModifyRequested]: 'Modification Requested',
  [InvestorOrderStatus.ExecutionCancelled]: 'Execution Cancelled'
};

/**
 * Mapping for Order Status display text
 */
export const tradingOrderStatusMapping: Record<TradingOrderStatus, string> = {
  [TradingOrderStatus.Active]: 'Active',
  [TradingOrderStatus.FullyFilled]: 'Fully Filled',
  [TradingOrderStatus.Expired]: 'Expired',
  [TradingOrderStatus.ExpiryPending]: 'Expiration Request Pending',
  [TradingOrderStatus.ExpiryRejected]: 'Expiration Rejected',
  [TradingOrderStatus.ExpiryRequested]: 'Expiration Requested',
  [TradingOrderStatus.Rejected]: 'Rejected',
  [TradingOrderStatus.RouteRequested]: 'Route Requested',
  [TradingOrderStatus.Inactive]: 'Inactive',
  [TradingOrderStatus.PendingCancel]: 'Pending Cancel',
  [TradingOrderStatus.Cancelled]: 'Cancelled',
  [TradingOrderStatus.ModifyRequested]: 'Modification Requested',
  [TradingOrderStatus.ModifyPending]: 'Modification Request Pending',
  [TradingOrderStatus.TradeCancelled]: 'Trade Cancelled'
};

/**
 * Mapping for Order Category display text
 */
export const tradingOrderCategoryMapping = {
  [TradingOrderCategory.Iso]: 'ISO',
  [TradingOrderCategory.Internalization]: 'Internalization',
  [TradingOrderCategory.Montage]: 'Montage',
  [TradingOrderCategory.Mmpo]: 'Market Maker Pegged Order',
  [TradingOrderCategory.Standard]: 'Standard'
};

/**
 * Mapping for modification types
 */
export const modificationTypeMapping = {
  [ModificationType.Cancel]: 'Cancel',
  [ModificationType.Modify]: 'Modify',
  [ModificationType.Expire]: 'Expire'
};

/**
 * Mapping for Time in Force
 */
export const tifMapping = {
  [TimeInForce.Day]: 'Day',
  [TimeInForce.Duration]: 'Duration',
  [TimeInForce.Gtc]: 'GTC',
  [TimeInForce.Gtd]: 'GTD',
  [TimeInForce.Ioc]: 'IOC',
  [TimeInForce.Open]: 'Open',
  [TimeInForce.Close]: 'Close',
  [TimeInForce.TifNotSupported]: 'TIF not supported'
};

/**
 * Mapping for Visibility Reason
 */
export const visibilityReasonMapping = {
  [VisibilityReason.AccountBackup]: 'Acc. Backup',
  [VisibilityReason.AccountManager]: 'Acc. Manager',
  [VisibilityReason.AccountPrimary]: 'Acc. Primary',
  [VisibilityReason.AccountSupport]: 'Acc. Support',
  [VisibilityReason.Owner]: 'Owner',
  [VisibilityReason.RestrictedOwner]: 'Restricted Owner',
  [VisibilityReason.UserManager]: 'User Manager',
  [VisibilityReason.UserPrimary]: 'User Primary',
  [VisibilityReason.UserTeamAccess]: 'User Team Access',
  [VisibilityReason.UserTeamMember]: 'User Team Member',
  [VisibilityReason.RestrictedPrimary]: 'Restr. Primary',
  [VisibilityReason.RestrictedBackup]: 'Restr. Backup',
  [VisibilityReason.RestrictedSupport]: 'Restr. Support'
};

/**
 * Mapping for handling instructions
 */
export const handlingInstMapping = {
  [HandlingInstruction.DirectMarketAccess]: 'DMA',
  [HandlingInstruction.HighTouch]: 'High Touch',
  [HandlingInstruction.LowTouch]: 'Low Touch'
};

/**
 * Mapping for Discretionary Type
 */
export const discretionaryTypeMapping = {
  [OrderDiscretionaryType.Held]: 'Held',
  [OrderDiscretionaryType.NotHeld]: 'Not Held'
};

/**
 * Mapping for Settle Type
 */
export const settleTypeMapping = {
  [OrderSettleType.Cash]: 'Cash',
  [OrderSettleType.NextDay]: 'Next Day',
  [OrderSettleType.TPlus_2]: 'T+2',
  [OrderSettleType.TPlus_3]: 'T+3',
  [OrderSettleType.TPlus_4]: 'T+4',
  [OrderSettleType.TPlus_5]: 'T+5',
  [OrderSettleType.Future]: 'Future',
  [OrderSettleType.WhenIssued]: 'When Issued',
  [OrderSettleType.Regular]: 'Regular'
};

export const instrumentRestrictionRestrictionTypeMapping = {
  [RestrictionTypeEnum.Restricted]: 'Restricted',
  [RestrictionTypeEnum.NoPrincipalActivityAllowed]: 'No Principal Activity Allowed',
  [RestrictionTypeEnum.NoShortSellOrdersAllowed]: 'No Short Sell Orders Allowed'
};

export const chargesMapping = {
  [ChargeGroup.Fee]: 'Fee',
  [ChargeGroup.Market]: 'Market',
  [ChargeGroup.NoSelection]: 'No Selection',
  [ChargeGroup.Other]: 'Other'
};

export const rateTypeMapping = {
  [RateType.BasisPoint]: 'Basis Point',
  [RateType.CostPlus]: 'Cost Plus',
  [RateType.Flat]: 'Flat',
  [RateType.PerUnit]: 'Per Unit',
  [RateType.Scale]: 'Scale',
  [RateType.Percentage]: 'Percentage'
};

export const scaleBasisMapping = {
  [ScaleBasis.OrderValue]: 'Order Value',
  [ScaleBasis.Price]: 'Price',
  [ScaleBasis.Quantity]: 'Quantity'
};

export const scaleRateTypeMapping = {
  [ScaleRateType.BasisPoint]: 'Basis Point',
  [ScaleRateType.PerUnit]: 'Per Unit',
  [ScaleRateType.Percentage]: 'Percentage'
};

export const rateBasisMapping = {
  [RateBasis.Blank]: 'Blank',
  [RateBasis.ExecutedQuantity]: 'Executed Quantity',
  [RateBasis.OrderValue]: 'Order Value'
};

export const orderSideTypeToOrderSideMapping = {
  [OrderSideType.Btc]: OrderSide.Buy,
  [OrderSideType.Buy]: OrderSide.Buy,
  [OrderSideType.Sell]: OrderSide.Sell,
  [OrderSideType.Short]: OrderSide.Sell,
  [OrderSideType.Exempt]: OrderSide.Sell
};

export const orderEntryTypeMapping = {
  [OrderEntryType.Electronic]: 'Electronic',
  [OrderEntryType.Manual]: 'Manual',
  [OrderEntryType.System]: 'System'
};
