import { FieldDefinition, FormContract } from '@oms/frontend-foundation';
import type { InferFormValuesFromFormContract } from '@oms/frontend-foundation';
import type { OrderSizeRow } from '@app/data-access/offline/collections/user-preferences.collection';
import { fromField, toField, orderSizeField, idField } from './order-settings.row.form-fields';

export const orderSizeRowContract = FormContract.create<OrderSizeRow>()
  .fields({
    id: idField,
    from: fromField,
    to: toField,
    orderSize: orderSizeField
  })
  .schema((f) => ({
    fields: [
      FieldDefinition.simpleGrid('rows-wrapper', 3, [f.id, f.from, f.to, f.orderSize], {
        isInArray: 'rows'
      })
    ]
  }));

export type OrderSizeRowContractType = typeof orderSizeRowContract;
export type OrderSizeRowFormValues = InferFormValuesFromFormContract<OrderSizeRowContractType>;
