import { FORM_EVENT_TYPE, FormBuilder } from '@oms/frontend-foundation';
import type { OrderSettingsSchema } from '@app/data-access/offline/collections/user-preferences.collection';
import { fromUserPrefererencesForm, toUserPreferencesForm } from '../../user-preferences.util';
import type { OrderSettingsContractType } from './order-settings.form-contract';
import { orderSettingsContract } from './order-settings.form-contract';
import { tranformFromFormValues, transformToFormValues } from './order-settings.util';

export const orderSettingsBuilder = FormBuilder.create<{}, OrderSettingsSchema>('order-settings-form')
  .contract<OrderSettingsContractType>(orderSettingsContract)
  .type('order-settings')
  .sanitizer((s) =>
    s
      .input(
        async (_, { container }) =>
          await toUserPreferencesForm('orderSettings', container, transformToFormValues)
      )
      .output(tranformFromFormValues)
  )
  .change(async (event, { container, notify }) => {
    const { type } = event;

    switch (type) {
      case FORM_EVENT_TYPE.SUBMIT: {
        const { output } = event.payload;
        await fromUserPrefererencesForm('orderSettings', output, container);
        break;
      }
      case FORM_EVENT_TYPE.RESET: {
        notify({
          type: 'RESET'
        });
      }
    }
  });

export type OrderSettingsBuilderType = typeof orderSettingsBuilder;

export default orderSettingsBuilder;
