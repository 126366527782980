import type { FetchPolicy } from '@apollo/client';
import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import { GQLResponse } from '@app/data-access/api/graphql/graphql-response';
import type { DataSourceCommon } from '@oms/frontend-foundation';
import { asObservableDataSource } from '@oms/frontend-foundation';
import type {
  GetWorkingOpenQuantityByInstrumentAndOwnerIdQuery,
  GetWorkingOpenQuantityByInstrumentAndOwnerIdQueryVariables,
  WorkingOpenAggregate,
  WorkingOpenQuantityUpdatedSubscription,
  WorkingOpenQuantityUpdatedSubscriptionVariables
} from '@oms/generated/frontend';
import {
  GetWorkingOpenQuantityByInstrumentAndOwnerIdDocument,
  WorkingOpenQuantityUpdatedDocument
} from '@oms/generated/frontend';
import { Logger, compactMap } from '@oms/shared/util';
import type { Observable } from 'rxjs';
import { map, merge } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class WorkingOpenQuantityService {
  protected name: string = 'WorkingOpenQuantityService';
  protected logger: Logger;
  protected fetchPolicy: FetchPolicy = 'network-only';

  constructor(
    @inject(RxApolloClient) protected apolloClient: RxApolloClient,
    @inject(GQLResponse) protected gqlResponse: GQLResponse
  ) {
    this.logger = Logger.labeled(this.name);
  }

  public watchAll$ = (
    variables?: GetWorkingOpenQuantityByInstrumentAndOwnerIdQueryVariables
  ): Observable<DataSourceCommon<WorkingOpenAggregate>> => {
    return merge(
      this._watchQuery_GetWorkingOpenQuantityByInstrumentAndOwnerId$(variables),
      this._subscribe_WorkingOpenQuantityUpdatedSubscription$()
    ).pipe(
      asObservableDataSource({
        onError: (e) => {
          this.logger.scope('watchAll$').error(e);
        }
      })
    );
  };

  protected _watchQuery_GetWorkingOpenQuantityByInstrumentAndOwnerId$(
    variables?: GetWorkingOpenQuantityByInstrumentAndOwnerIdQueryVariables
  ): Observable<WorkingOpenAggregate[]> {
    return this.apolloClient
      .rxWatchQuery<
        GetWorkingOpenQuantityByInstrumentAndOwnerIdQuery,
        GetWorkingOpenQuantityByInstrumentAndOwnerIdQueryVariables
      >({
        query: GetWorkingOpenQuantityByInstrumentAndOwnerIdDocument,
        variables,
        fetchPolicy: this.fetchPolicy
      })
      .pipe(map(({ data }) => compactMap([data.getWorkingOpenQuantityByInstrumentAndOwnerId])));
  }

  protected _subscribe_WorkingOpenQuantityUpdatedSubscription$(): Observable<WorkingOpenAggregate[]> {
    return this.apolloClient
      .rxSubscribe<WorkingOpenQuantityUpdatedSubscription, WorkingOpenQuantityUpdatedSubscriptionVariables>({
        query: WorkingOpenQuantityUpdatedDocument,
        fetchPolicy: this.fetchPolicy
      })
      .pipe(map(({ data }) => compactMap([data?.workingOpenQuantityUpdated?.workingOpenAggregate])));
  }
}

export default WorkingOpenQuantityService;
