import type { Field, FormOptions, UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';
import { StateRules, StrategyQuery, AtdlFormats } from '@valstro/fixatdl-core';
import type { ControlState, Strategy } from '@valstro/fixatdl-core';

type FieldConfig = { prop: string; invert?: boolean; defaultValue?: any };

export interface StateRuleActionOptions {
  strat: Strategy;
  fieldConfig: Record<keyof ControlState, FieldConfig>;
}

export const stateRulesAction: (
  opts: StateRuleActionOptions
) => (_props: Field, field: UseFieldApiConfig, formOptions: FormOptions) => any =
  ({ strat, fieldConfig }) =>
  (_props: Field, field: UseFieldApiConfig, formOptions: FormOptions) => {
    const { getState } = formOptions;
    const query = StrategyQuery.from(strat);
    const formState = getState();
    const control = query.findControl(field.input.name);
    const newControlState = StateRules.evaluate(control, query.values(formState.values));
    //We will only need to change the field's input value if the ControlState tells us to
    const isValueChanging = newControlState.value !== undefined;

    const result = {} as { [key: string]: any };
    (Object.keys(fieldConfig) as Array<keyof ControlState>).forEach((c) => {
      const { prop, invert = false, defaultValue } = fieldConfig[c] as FieldConfig;
      result[prop] =
        invert && AtdlFormats.isBoolean(newControlState[c]) ? !newControlState[c] : newControlState[c];
      result[prop] = AtdlFormats.isEmpty(result[prop]) ? defaultValue : result[prop];
    });

    if (isValueChanging) {
      formOptions.change(field.input.name, result.value);
    }

    return result;
  };
