import type { LeftClickSettings } from '@app/data-access/offline/collections/user-preferences.collection';
import { OrderSideType, PriceType, QuantityType, TimeInForce } from '@oms/generated/frontend';

export const DEFAULT_TIME_IN_FORCE = TimeInForce.Duration;

export const LEFT_CLICK_SETTING_DEFAULTS: {
  ask: LeftClickSettings<'ask'>;
  bid: LeftClickSettings<'bid'>;
} = {
  ask: {
    initiateOrder: false,
    priceType: PriceType.AskInside,
    quantityType: QuantityType.Blank,
    sideType: OrderSideType.Buy,
    timeInForceType: DEFAULT_TIME_IN_FORCE
  },
  bid: {
    initiateOrder: false,
    priceType: PriceType.BidInside,
    quantityType: QuantityType.Blank,
    sideType: OrderSideType.Sell,
    timeInForceType: DEFAULT_TIME_IN_FORCE
  }
} as const;
