import { z } from 'zod';
import { createAppVersionedSchema } from '@app/schemas/create-versioned-schema';

export default createAppVersionedSchema('SYSTEM_REMOTE_FORM', {
  version: 0,
  schema: z.object({
    inputVersion: z.number().optional(),
    inputSubSchemaInvalid: z.boolean().optional(),
    input: z.record(z.any()),
    schema: z.record(z.any()),
    formId: z.string(),
    formType: z.string().optional(),
    initialValuesVersion: z.number().optional(),
    initialValuesSubSchemaInvalid: z.boolean().optional(),
    initialValues: z.record(z.any()).optional(),
    initialFeedback: z
      .array(
        z.object({
          code: z.string(),
          level: z.enum(['Error', 'Info', 'Warning']),
          message: z.string()
        })
      )
      .optional(),
    triggerValidationOnOpen: z.boolean().optional(),
    template: z.string().optional(),
    templateProps: z.record(z.any()).optional(),
    formSaveType: z.string(),
    formBuilderId: z.string()
  })
});
