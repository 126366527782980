import { FormSummary } from '@oms/frontend-foundation';
import React, { useMemo } from 'react';
import { orderSummaryData, orderStrategyParametersSummaryData } from '@app/common/summaries/order-fields';
import type { OrderSummaryFieldName } from '@app/common/summaries/order-fields';
import type { TradingOrderRow } from '@oms/generated/frontend';

const orderFields: OrderSummaryFieldName[] = [
  'totalQuantity',
  'side',
  'locate',
  'id',
  'tradingAccountName',
  'tradingAccountType',
  'limitPrice',
  'orderType',
  'capacity',
  'timeInForce',
  'tifDuration',
  'expiryDateTime',
  'orderTagIds',
  'status',
  'createdTimestamp',
  'updatedTimestamp',
  'enteredByEmail',
  'ownerEmail',
  'executedQuantity',
  'averagePrice',
  'minQuantity',
  'displayQuantity',
  'venue',
  'targetExchangeId',
  'category',
  'instrumentDisplayCode',
  'fixMessage',
  'customerNotes',
  'venueNotes',
  'representativeCode'
];

const settleFields: OrderSummaryFieldName[] = ['tradeCurrency', 'settleCurrency', 'settleType', 'settleDate'];

export const TradingOrderDetailsSummary = React.memo(
  ({ tradingOrder }: { tradingOrder: TradingOrderRow }) => {
    const data = useMemo(() => {
      const sections = [
        orderSummaryData('ORDER', orderFields, tradingOrder),
        orderSummaryData('SETTLE', settleFields, tradingOrder)
      ];
      if (tradingOrder.strategyDetails) {
        sections.push(orderStrategyParametersSummaryData('STRATEGY PARAMETERS', tradingOrder));
      }
      return sections;
    }, [tradingOrder]);

    return <FormSummary labeledData={data} />;
  }
);
