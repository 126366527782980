import InvestorOrderRepairRequestService from '@app/data-access/services/trading/repair-requests/investor-order-repair-requests.service';
import { openConfirmation } from '@app/generated/sdk';
import { openMessageDialog } from '@app/common/dialog/dialog.common';
import { DIALOG_EVENT_TYPE } from '@app/common/registry/dialog.open';
import { t } from '@oms/codegen/translations';
import type { ActionContext, ActionDefFactory, ActionComponentConfig } from '@oms/frontend-vgrid';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { ValstroEntitlements } from '@oms/generated/frontend';
import { Maybe } from '@oms/shared/util-types';

type RetryInvestorOrdersActionArgs = {
  id?: Maybe<string>;
};

function isVisible(data: RetryInvestorOrdersActionArgs[]) {
  return data.length >= 1;
}

export const retryInvestorOrdersOnChange = async <T extends RetryInvestorOrdersActionArgs>(
  ctx: ActionContext<T, ActionComponentConfig<T>>
) => {
  const { appContainer, lifecycle, data, workspace } = ctx;

  ctx.notify({ isDisabled: !isVisible(data) });

  if (lifecycle === 'change') {
    const ioRepairRequestService = appContainer.resolve(InvestorOrderRepairRequestService);
    const selectedRowsId = data.map((row) => row.id!);
    const count = selectedRowsId.length;

    try {
      const [_, api] = await openConfirmation(workspace, workspace.getLeaderProcessId(), {
        title: `Retry ${count} investor order${count > 1 ? 's' : ''}`,
        componentProps: {
          autoClose: true,
          message: `Are you sure you want to retry ${count} investor order${count > 1 ? 's' : ''}?`,
          confirmButtonText: t('app.common.yes'),
          confirmButtonProps: {
            variant: 'primary',
            palette: 'standard'
          },
          cancelButtonText: t('app.common.no')
        }
      });
      const event = await api.awaitFirstEvent;
      switch (event.type) {
        case DIALOG_EVENT_TYPE.OK: {
          const result = await ioRepairRequestService.retryIOs(selectedRowsId);
          ctx.notify({ isLoading: false });

          if (result.isFailure()) {
            const msgs = result?.errors.map((e) => e.message).join(', ');
            openMessageDialog(`Error: ${msgs}`, workspace).catch(console.error);
            throw new Error(msgs);
          }
          break;
        }
      }
    } catch (e) {
      openMessageDialog(`Error: ${String(e)}`, workspace).catch(console.error);
      console.error(e);
    }
  }
};

export const retryInvestorOrdersAction =
  <T extends RetryInvestorOrdersActionArgs>(): ActionDefFactory<T> =>
  (builder) =>
    builder
      .name('retry_investor_order')
      .toolbar((t) =>
        t
          .component('action-button')
          .id('retry_investor_order_button')
          .location('HorizontalToolbarRight')
          .props({
            isDisabled: true,
            content: 'Retry'
          })
      )
      .access(({ appContainer }) => {
        const authService = appContainer.resolve(AuthService);

        return authService.hasEntitlement([ValstroEntitlements.RepairQueueManage]);
      })
      .customMenu((m) => m.name('Retry').visible(({ rowData }) => isVisible(rowData)))
      .onChange<ActionComponentConfig<T>>(retryInvestorOrdersOnChange);
