import { Actor, COMMON_ACTOR_TYPE } from '@valstro/workspace';
import type { RegistryDefinitionFormWithFormKey } from '@app/app-config/registry.config';
import { formBuilderRemoteEvent$, FORM_SAVE_TYPE } from '@oms/frontend-foundation';
import type {
  RemoteFormBuilderDefinition,
  InferInfoFromFormBuilder as InferB,
  FormBuilderEvent,
  AnyFormBuilder
} from '@oms/frontend-foundation';
import { Observable, filter, map } from 'rxjs';
import { UUID } from '@oms/shared/util';
import { COMPONENT_ENUM } from '@app/generated/common';
import type { AppWindowActorSchema } from '@app/app-config/workspace.config';
import { createWidgetActorDef, getVersionNumber } from './common.open';
import { RemoteFormComponentProps } from '@app/widgets/system/remote-form/remote-form.widget.config';

/**
 * Form
 * -----------------------------------------------------------------------------
 */
export class Form<T extends AnyFormBuilder> {
  constructor(
    private _actor: Actor<AppWindowActorSchema>,
    public events$: Observable<FormBuilderEvent<InferB<T>['outputContract'], InferB<T>['fieldValues']>>
  ) {}

  /**
   * Open a form using the form definition.
   *
   * @param definition - Form definition
   * @param parentActorOrId - Parent actor or parent actor id
   * @returns Form
   */
  static async open<B extends AnyFormBuilder>(
    definition: RegistryDefinitionFormWithFormKey<InferB<B>['inputContract']>,
    parentActorOrId: Actor | string,
    initialValues?: InferB<B>['fieldValues'],
    lazy?: boolean
  ) {
    const parentActor =
      parentActorOrId instanceof Actor
        ? parentActorOrId
        : lazy
          ? Actor.getSyncLazy(parentActorOrId)
          : await Actor.get(parentActorOrId);
    const { title, ...windowDefinition } = definition;
    const remoteDef = await Form.interpret(definition, initialValues);
    const windowId = definition.windowId ? definition.windowId : `form-window-${remoteDef.formId}`;
    const inputVersion = getFormInputVersionNumber(definition.key);
    const initialValuesVersion = getFormValuesVersionNumber(definition.key);

    const remoteFormProps = {
      ...remoteDef,
      inputVersion,
      initialValuesVersion
    } as RemoteFormComponentProps; // z.record(z.any()) does not match the more specific types of RemoteFormComponentProps RemoteFormBuilderDefinition

    const [_, actor] = await parentActor.spawnChild<AppWindowActorSchema>({
      type: COMMON_ACTOR_TYPE.WINDOW,
      id: windowId,
      context: {
        title: title || 'Form',
        width: 600,
        height: 400,
        isBrowserTab: !!definition.openTabInBrowser,
        ...windowDefinition.windowOptions,
        meta: {
          requiredEntitlements: definition.hasEntitlement,
          widgetCategory: definition.objectCategory,
          widgetType: definition.key,
          ...windowDefinition.windowOptions?.meta
        }
      },
      children: [
        createWidgetActorDef(
          COMPONENT_ENUM.SYSTEM_REMOTE_FORM,
          remoteFormProps,
          getVersionNumber(COMPONENT_ENUM.SYSTEM_REMOTE_FORM),
          {
            widgetType: definition.key
          }
        )
      ]
    });

    if (actor.id === undefined) {
      throw new Error('Actor is undefined');
    }

    const events$ = formBuilderRemoteEvent$.pipe(
      filter((e) => e.meta.formId === remoteDef.formId),
      map((e) => e as FormBuilderEvent<InferB<B>['outputContract'], InferB<B>['fieldValues']>)
    );

    return new Form<B>(actor, events$);
  }

  /**
   * Intepret the dictionary form definition and return the remote form definition
   * to be passed to the form builder.
   */
  static async interpret<B extends AnyFormBuilder>(
    definition: RegistryDefinitionFormWithFormKey<B>,
    initialValues?: InferB<B>['fieldValues']
  ): Promise<RemoteFormBuilderDefinition<InferB<B>['fieldValues'], InferB<B>['inputContract']>> {
    const { key, form, formKey } = definition;

    const {
      input,
      initialFeedback,
      triggerValidationOnOpen,
      formType,
      formSaveType = FORM_SAVE_TYPE.UNKNOWN,
      formId = `form-${key}-${UUID()}`,
      schema,
      template,
      templateProps
    } = form || {};

    const remoteDef: RemoteFormBuilderDefinition<InferB<B>['fieldValues'], InferB<B>['inputContract']> = {
      initialFeedback,
      triggerValidationOnOpen,
      schema,
      template,
      templateProps,
      formId,
      input: input as InferB<B>['inputContract'],
      initialValues,
      formSaveType,
      formType,
      formBuilderId: formKey
    };

    return remoteDef;
  }

  /**
   * Get the actor to get state & perform operations on the window itself.
   */
  public get window() {
    return this._actor;
  }

  /**
   * Get the ID which is the window ID.
   */
  public get id() {
    return this._actor.id;
  }

  /**
   * Get the parent ID which is the window parent ID.
   */
  public get parentId() {
    return this._actor.parentId;
  }

  /**
   * Close the window.
   *
   * @returns - Promise that resolves when the form is closed.
   */
  public close() {
    return this._actor.operations.close();
  }

  /**
   * Dispose of the window.
   * This will close the window and remove it from the workspace.
   */
  public dispose() {
    this.close().catch(() => {
      // ignore because the window may have been closed already, and we don't care
    });
  }
}

/**
 * Get the version number of a form input version
 *
 * @param key - The definition key
 * @returns - The version number
 */
export function getFormInputVersionNumber(key: string): number | undefined {
  return getVersionNumber(`${key}_FORM_INPUT`);
}

/**
 * Get the version number of a form values version
 *
 * @param key - The definition key
 * @returns - The version number
 */
export function getFormValuesVersionNumber(key: string): number | undefined {
  return getVersionNumber(`${key}_FORM_VALUES`);
}
