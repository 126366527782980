import type { FieldApi } from '@data-driven-forms/react-form-renderer';
import type { AnyRecord, Optional, Segmented } from '@oms/shared/util-types';
import type { BaseFieldType } from './field.utils';

/**
 * Utility class for managing segmented range rows in form fields.
 *
 * @template RowFieldType - Type of the row fields.
 * @template AllFormValuesType - Type of the form values.
 */
export abstract class RangeSegmentedRowUtil<
  RowFieldType extends BaseFieldType = Segmented<number>,
  AllFormValuesType extends AnyRecord = AnyRecord
> {
  public allFormValues: AllFormValuesType;
  public readonly fieldApi: FieldApi<unknown, HTMLInputElement>;

  // 🏗️ Constructor -------------------------------------------------- /

  /**
   * Constructs a new RangeSegmentedRowUtil instance.
   *
   * @param allFormValues - All form values.
   * @param fieldApi - Field API for accessing field properties.
   */
  public constructor(allFormValues: AllFormValuesType, fieldApi: FieldApi<unknown, HTMLInputElement>) {
    this.allFormValues = allFormValues;
    this.fieldApi = fieldApi;
  }

  // ⁉️ Abstract -------------------------------------------------- /
  // Implement all of these in the concrete subclass ------------- /

  /**
   * Provide the logic to extract the rows from the form data.
   *
   * @param allFormValues - Takes explicit form values, in case values are modified from the current stored values.
   * @returns Possible row array, if found.
   */
  protected abstract getRows(allFormValues: AllFormValuesType): Optional<RowFieldType[]>;

  /**
   * If rows are currently undefined, provide the logic to return updated form values with rows array initialized.
   * **Do not update the stored `allFormValues` property in the implementation. The logic that calls this method will do so.**
   *
   * @param allFormValues - Takes explicit form values, in case values are modified from the current stored values.
   * @param initialRows - Takes optional initial rows (or empty array if undefined).
   * @returns The updated form values with rows initialized.
   */
  protected abstract initRows(
    allFormValues: AllFormValuesType,
    initialRows?: RowFieldType[]
  ): AllFormValuesType;

  /**
   * Provide the logic to get the current row index based on the field API.
   *
   * @returns Possible row index, if applicable.
   */
  protected abstract getRowIndex(): Optional<number>;

  // 📢 Public -------------------------------------------------- /

  /**
   * Gets the field name.
   */
  public get fieldName(): string {
    return this.fieldApi.input.name;
  }

  /**
   * Gets the rows array from the form values.
   */
  public get rows(): RowFieldType[] {
    return this.getRows(this.allFormValues) || [];
  }

  /**
   * Gets the row index from the form API, if applicable.
   */
  public get rowIndex(): Optional<number> {
    return this.getRowIndex();
  }

  /**
   * Gets the count of rows in the array.
   */
  public get rowCount(): number {
    return this.rows.length;
  }

  /**
   * Gets the current row based on the row index.
   */
  public get currentRow(): Optional<RowFieldType> {
    return typeof this.rowIndex === 'number' ? this.rows[this.rowIndex] : undefined;
  }

  /**
   * Gets the previous row in the rows array based on the row index.
   */
  public get previousRow(): Optional<RowFieldType> {
    return typeof this.rowIndex === 'number' ? this.rows[this.rowIndex - 1] : undefined;
  }

  /**
   * Checks if the rows array is empty.
   */
  public get isEmpty(): boolean {
    return this.rowCount === 0;
  }

  /**
   * Checks if the current row is the final row in the array.
   */
  public get isFinalRow(): boolean {
    return typeof this.rowIndex === 'number' && this.rowIndex === this.rowCount - 1;
  }

  /**
   * Determines whether a new row should be added.
   */
  public get shouldAddNextRow(): boolean {
    if (!this.isFinalRow) return false;
    const { currentRow } = this;
    return typeof currentRow?.to === 'number' && currentRow.to < Number.POSITIVE_INFINITY;
  }

  /**
   * Updates the form values with a new blank row.
   *
   * @param rest - Additional fields for the new row.
   * @returns The updated form values.
   */
  public addNewRow(rest: Omit<RowFieldType, 'from' | 'to'>): AllFormValuesType {
    const clonedValues = structuredClone(this.allFormValues);
    const rows = this.getRows(clonedValues);
    if (!Array.isArray(rows)) {
      const updatedValues = this.initRows(clonedValues, [this.createNewRow(rest)]);
      this.allFormValues = updatedValues;
      return updatedValues;
    }
    rows.push(this.createNewRow(rest));
    this.allFormValues = clonedValues;
    return clonedValues;
  }

  // 🔒 Protected -------------------------------------------------- /

  /**
   * Creates a new blank row with the given additional fields.
   *
   * @param rest - Additional fields for the new row.
   * @returns The new row.
   */
  protected createNewRow(rest: Omit<RowFieldType, 'from' | 'to'>): RowFieldType {
    return { from: this.currentRow?.to || 0, ...rest } as RowFieldType;
  }
}
