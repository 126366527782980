import type { Field } from '@data-driven-forms/react-form-renderer';
import type { FormOptions } from '@oms/frontend-foundation';
import { InstrumentRestrictionFormValues } from '../instrument-restriction-entry.form-contract';
import { IDatePickerField } from 'libs/frontend/foundation/src/forms/components/date-picker/date-picker.type';

export const INSTRUMENT_RESTRICTION_START_DATE = 'instrumentRestrictionStartDate';

export const instrumentRestrictionStartDate =
  () =>
  (_props: Field, _field: Field, formOptions: FormOptions): Partial<IDatePickerField> => {
    const formValues: Partial<InstrumentRestrictionFormValues> = formOptions.getState().values;
    const { restrictionExpiryDate } = formValues || {};
    if (restrictionExpiryDate) {
      return {
        maxDate: new Date(restrictionExpiryDate)
      };
    } else {
      return {};
    }
  };
