import { ProcessState } from '@app/data-access/memory/process-id.subject';
import { inject, singleton } from 'tsyringe';
import type { NotificationSignal } from './notifications.contracts';
import { NOTIFICATION_VISIBILITY } from './notifications.contracts';
import { testScoped } from '@app/workspace.registry';
import { AbstractSignal } from '@app/data-access/memory/signal';

// TODO: Extend to include error state & rename.
// By adding a new signal for error state.
/*
type NotificationSignalState = {
  visibility: NotificationVisibility;
  errorMessage?: string;
};
*/
@testScoped
@singleton()
export class NotificationVisiblitySignal extends AbstractSignal<NotificationSignal> {
  // TODO: If you want a retry button, use a subect here,..... see auth.signal.ts

  constructor(@inject(ProcessState) processState: ProcessState) {
    super(
      processState,
      'notifications',
      {
        initialize$: processState.isLeaderProcess$,
        initializeOnce: false
      },
      NOTIFICATION_VISIBILITY.MINE
    );
  }
}
