import { useCallback, useState } from 'react';
import { withUpdatedInvestorOrder } from '@app/data-access/hoc/with-updated-investor-order';
import type { UpdatedInvestorOrder } from '@app/data-access/hoc/with-updated-investor-order';
import { Box, Flex, TabContent, TabTrigger, Tabs, TabsList } from '@oms/shared-frontend/ui-design-system';
import {
  marketDataToggleBorder,
  marketDataToggleText,
  tabContentInner
} from './investor-order-view.side-panel.css';
import { MarketDataWidget } from '@app/data-access/services/marketdata/components/market-data.widget';
import { InvestorOrderDetailsSummary } from './investor-order-view.order-details.widget';
import { paneStyle } from './investor-order-view.style';

export const InvestorOrderViewSidePanelWidget = ({ data: investorOrder }: UpdatedInvestorOrder) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const handleToggle = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <Tabs
      defaultValue="tabInstrumentData"
      sx={{
        ...paneStyle,
        height: 'full',
        flexDirection: 'column',
        display: 'flex'
      }}
    >
      <TabsList sx={{ paddingLeft: 4, marginBottom: 'medium' }}>
        <TabTrigger value="tabInstrumentData">Instrument data</TabTrigger>
        <TabTrigger value="tabOrderDetails">Order details</TabTrigger>
      </TabsList>

      <TabContent value="tabInstrumentData" sx={{ flexDirection: 'column', overflowY: 'auto' }}>
        <Box className={tabContentInner}>
          {investorOrder.instrument && (
            <Flex direction="column">
              <Flex align="center" justify="center" sx={{ marginBottom: 'medium' }}>
                <div className={marketDataToggleBorder} />
                <div className={marketDataToggleText} onClick={handleToggle}>
                  Show {isExpanded ? 'Less' : 'More'}
                </div>
                <div className={marketDataToggleBorder} />
              </Flex>
              <MarketDataWidget
                instrumentId={investorOrder.instrument}
                type={isExpanded ? 'om-grid' : 'om-grid-short'}
                orderQuantity={investorOrder.totalQuantity}
              />
            </Flex>
          )}
        </Box>
      </TabContent>
      <TabContent value="tabOrderDetails" sx={{ flexDirection: 'column', overflowY: 'auto' }}>
        <Box className={tabContentInner}>
          <InvestorOrderDetailsSummary investorOrder={investorOrder} />
        </Box>
      </TabContent>
    </Tabs>
  );
};

export default withUpdatedInvestorOrder(InvestorOrderViewSidePanelWidget, {
  sx: paneStyle
});
