import type { ICellRendererParams } from '@ag-grid-community/core';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { OrderSideType } from '@oms/generated/frontend';
import { VStack, Flex, Text } from '@oms/shared-frontend/ui-design-system';

export const SIDE_TYPE_CELL_RENDERER = 'SideTypeCellRenderer';

const positivePriceTypes = [OrderSideType.Buy, OrderSideType.Btc];

export const SideTypeCellRenderer: React.FC<ICellRendererParams> = ({ value }, showPlaceholder: boolean) => {
  if (showPlaceholder && (value === null || value === '')) {
    return (
      <VStack justify="center" style={{ height: '100%' }}>
        <Flex justify="space-between">
          <Text type="gridBase" as="div">
            Side
          </Text>
          <ChevronDownIcon />
        </Flex>
      </VStack>
    );
  }

  return (
    <Text
      sx={{
        color: positivePriceTypes.includes(value as OrderSideType) ? 'price.positive' : 'price.negative'
      }}
    >
      {value}
    </Text>
  );
};
