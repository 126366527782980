import { useRef } from 'react';
import { useVGrid, VGrid, CLEAR_GRID_FILTER_ACTION_TYPE, toClientsideGridData } from '@oms/frontend-vgrid';
// import type { NotificationSettingsFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { NotificationSettingsService } from '@app/data-access/services/user/notification-settings.service';
import { notificationsSettingsColumnLibrary } from './notification-settings.columns';
import { NotificatioSettingsGridEventHander } from './notification-settings.grid.event-handler';
import type { MappedNotificationSettings } from '@app/widgets/user/user-preferences/preferences/notification-settings/notification-settings.contracts';

export const NOTIFICATION_SETTINGS = 'notification-settings';

export const NotificationsSettingsGrid = () => {
  const datasourceService = useService(NotificationSettingsService);
  const query$ = useRef(datasourceService.watch$().pipe(toClientsideGridData()));

  const gridProps = useVGrid<MappedNotificationSettings>(
    NOTIFICATION_SETTINGS,
    (b) =>
      b
        .columnLibrary(notificationsSettingsColumnLibrary)
        .datasource((d) => d.source(query$.current).rowId(({ data }) => `${data?.notificationName}`))
        .rowSelection((c) => c.single())
        .injectEvents([NotificatioSettingsGridEventHander])
        .actions((a) =>
          a.schema((s) => s.action(CLEAR_GRID_FILTER_ACTION_TYPE)).settings((s) => s.fromFile({}))
        ),
    [query$]
  );

  return <VGrid {...gridProps} />;
};

export default NotificationsSettingsGrid;
