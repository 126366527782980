import type { RxCollection, RxCollectionCreator } from 'rxdb-v15';
import type { Action } from '../types/action.types';
import { GRIDS_COLLECTION_NAME } from '@app/data-access/offline/collections/grids.collection';
import {
  COMMON_RXDB_COLLECTION_WITH_SUB_SCHEMA,
  COMMON_SHAREABLE_RXDB_COLLECTION
} from '@oms/frontend-schemas';
import { LATEST_VERSION_NUMBER, MIGRATION_STRATEGIES } from './migrations';

export const ACTION_COLLECTION: RxCollectionCreator<Action> = {
  schema: {
    keyCompression: false, // set this to true, to enable the keyCompression
    version: LATEST_VERSION_NUMBER,
    title: 'action button schema with composite primary',
    primaryKey: {
      key: 'id',
      fields: ['widgetTypeId', 'commandId', 'objectId', 'order', 'locationId', 'gridStateId'],
      separator: '|'
    },
    type: 'object',
    properties: {
      id: {
        type: 'string',
        maxLength: 1024 // <- the primary key must have set maxLength
      },
      lastUpdatedAt: {
        type: 'string'
      },
      widgetTypeId: {
        type: 'string'
      },
      objectId: {
        type: 'string'
      },
      locationId: {
        type: 'string'
      },
      commandId: {
        type: 'string'
      },
      confirmation: {
        type: 'boolean'
      },
      label: {
        type: 'string'
      },
      color: {
        type: 'string'
      },
      size: {
        type: 'string'
      },
      order: {
        type: 'number'
      },
      separator: {
        type: 'boolean'
      },
      gridStateId: {
        type: 'string',
        ref: GRIDS_COLLECTION_NAME
      },
      parentId: {
        type: 'string',
        ref: 'actions'
      },
      ...COMMON_RXDB_COLLECTION_WITH_SUB_SCHEMA, // Re-use common payload schema
      ...COMMON_SHAREABLE_RXDB_COLLECTION
    },
    required: [
      'id',
      'widgetTypeId',
      'commandId',
      'objectId',
      'order',
      'locationId',
      'gridStateId',
      'lastUpdatedAt'
    ]
  },
  migrationStrategies: MIGRATION_STRATEGIES,
  autoMigrate: false
};

export type ActionsCollection = RxCollection<Action>;
