import type { AnyRecord } from '@oms/frontend-foundation';
import type { CollectionWithSchemaPayload, VersionedSchemaExport } from './schemas.common';
import type { SubSchemaItem, SubSchemaRegistry } from './sub-schema.registry.contracts';

export function getSubSchema(key: string, subSchemaRegister: SubSchemaRegistry, reportError = true) {
  const schemaItem = subSchemaRegister[key as string];

  if (!schemaItem) {
    if (reportError) {
      console.error(`No sub schema found for key whilst running migration: ${String(key)}`);
    }
    return null;
  }

  return schemaItem;
}

export function sortSchemas(subSchemaItem: SubSchemaItem) {
  return subSchemaItem.versionedSchemas.sort((a, b) => a.version - b.version);
}

export function getLatestSubSchema(subSchemaExports: VersionedSchemaExport<any>[]) {
  const latestFirst = subSchemaExports.sort((a, b) => b.version - a.version);
  if (!latestFirst.length) {
    throw new Error('No sub schema versions found');
  }
  return latestFirst[0];
}

export function migrateSubSchema<T extends CollectionWithSchemaPayload>(
  docWithPayload: T,
  subSchemaRegister: SubSchemaRegistry
): CollectionWithSchemaPayload {
  const key = docWithPayload.subSchemaKey as string | undefined;
  const oldVersion = docWithPayload.subSchemaVersion;
  const oldPayload: AnyRecord | undefined = docWithPayload.payload;
  const newDoc: T = { ...docWithPayload };

  // Perhaps payload can start as undefined? And then we migrate it?
  // But then what are you migrating?
  // TODO: Perhaps support payload starting as undefined and then allowing it to be migrated (so long as oldVersion & key are defined.)
  if (!oldPayload || oldVersion === undefined || !key) {
    return newDoc;
  }

  const schemaByKey = oldPayload ? getSubSchema(key, subSchemaRegister) : null;

  if (!schemaByKey) {
    return { ...newDoc, subSchemaInvalid: true };
  }

  const sortedSchemas = sortSchemas(schemaByKey);

  if (sortedSchemas.length === 0) {
    return { ...newDoc, subSchemaInvalid: true };
  }

  const oldSchema = sortedSchemas.find((schema) => schema.version === oldVersion);
  const newSchema = sortedSchemas[sortedSchemas.length - 1];

  if (!oldSchema || !newSchema) {
    return { ...newDoc, subSchemaInvalid: true };
  }

  const migrationsToRun = sortedSchemas.slice(
    sortedSchemas.indexOf(oldSchema) + 1,
    sortedSchemas.indexOf(newSchema) + 1
  );

  for (const migration of migrationsToRun) {
    const migrationVersion = migration.version;
    const migrationFn = migration.migration;

    if (!migrationFn) {
      return { ...newDoc, subSchemaInvalid: true };
    }

    // newDoc.subSchemaVersion is still the old version here
    if (newDoc.payload && typeof newDoc.subSchemaVersion === 'number' && newDoc.subSchemaKey) {
      newDoc.payload = migrationFn(newDoc.payload);
      newDoc.subSchemaVersion = migrationVersion;
    } else {
      return { ...newDoc, subSchemaInvalid: true };
    }
  }

  return newDoc;
}
