import { Scope } from '@sentry/react';
import { FactsetEndpoints, MarketDataErrors } from './factset.types';
import { DeploymentStage } from '@fds/frontgate-js-sdk';
import omit from 'lodash/omit';

const sentryFactsetEndpointTag = 'factset_endpoint';
const sentryFactsetTickerTag = 'factset_ticker';
const sentryFactsetEnvTag = 'factset_env';
const sentryFactsetPayloadTag = 'factset_payload';

const readableFactsetDeploymentStage = (env: DeploymentStage): string | DeploymentStage => {
  return Object.entries(DeploymentStage).find((e) => e[1] === env)?.[0] || env;
};

export const addFactsetScopeToSentry = (
  scope: Scope,
  {
    client,
    ticker,
    requestPayload,
    message,
    endpoint
  }: Partial<Pick<MarketDataErrors, 'client' | 'ticker' | 'requestPayload'>> & {
    message: string;
    endpoint?: FactsetEndpoints;
  }
) => {
  const ctx = {
    ...(endpoint ? { [sentryFactsetEndpointTag]: endpoint } : {}),
    ...(ticker ? { [sentryFactsetTickerTag]: ticker } : {}),
    ...(client ? { [sentryFactsetEnvTag]: readableFactsetDeploymentStage(client.env) } : {}),
    ...(requestPayload
      ? { [sentryFactsetPayloadTag]: JSON.stringify(requestPayload, null, 2)?.replace(/\\u[\dA-F]{4}/gi, '') }
      : {})
  };

  scope.setContext(message, ctx);
  scope.setTags(omit(ctx, sentryFactsetPayloadTag));

  return scope;
};
