import type { ActionDefFactory } from '@oms/frontend-vgrid';
import { PlusIcon } from '@radix-ui/react-icons';
import { AuthService } from '@app/data-access/services/system/auth/auth.service';
import { JoinedInstrumentRestrictionRow, ValstroEntitlements } from '@oms/generated/frontend';
import { openInstrumentRestrictionEntry } from '@app/generated/sdk';
import { t } from '@oms/codegen/translations';

export const openEntryInstrumentRestriction: ActionDefFactory<JoinedInstrumentRestrictionRow> = (builder) =>
  builder
    .name('open_instrument_restriction_entry')
    .toolbar((toolbar) =>
      toolbar
        .id('open_instrument_restriction_entry_button')
        .component('action-button')
        .location('HorizontalToolbarLeft')
        .props({
          variant: 'link',
          leftIcon: <PlusIcon />,
          content: t('app.referenceData.instrumentRestriction.openIREntry')
        })
    )
    .access(({ appContainer }) => {
      const authService = appContainer.resolve(AuthService);

      return authService.hasEntitlement([ValstroEntitlements.OrderManage]);
    })
    .onChange((ctx) => {
      if (ctx.lifecycle !== 'change') return;

      openInstrumentRestrictionEntry(ctx.windowActor.id).catch((e) => console.error(e));
    });
