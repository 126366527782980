import { createGlobalChannel } from './channel';
import { createObservable } from './observable';
import { DEFAULT_HANDSHAKE_TIMEOUT, getGlobalOptions } from './options';
import {
  AnyInstance,
  RemoteProxy,
  ProxyMessage,
  ProxyMessageEvent,
  ExposeMessage,
  ExposeOptions,
  RemoteInstanceChangeEvent,
  CreateProxyOptions,
  Unexpose
} from './types';
import {
  getMatchingIndex,
  uuid as createUUID,
  isWritable,
  isNonSerializable,
  deepMergePreserve,
  maybeSerialize,
  deepResolveProxy,
  IS_PROXY
} from './utils';

/**
 * Constants
 */
export const PROXY_CORE_CHANNEL_REQ = 'proxy_core_channel_req';
export const PROXY_CORE_CHANNEL_RES = 'proxy_core_channel_res';
export const PROXY_SUBSCRIPTIONS_CHANNEL = 'proxy_subscriptions_channel';

/**
 * Channel used to communicate between the proxy and the remote instance
 * for core messages (get, set, call, handshake etc)
 */
const coreChannelReq = createGlobalChannel<ProxyMessage>(PROXY_CORE_CHANNEL_REQ);
const coreChannelRes = createGlobalChannel<ExposeMessage>(PROXY_CORE_CHANNEL_REQ);

/**
 * Channel used to communicate real-time updates from the remote instance to the proxy
 */
const subscriptionsChannel = createGlobalChannel<RemoteInstanceChangeEvent>(PROXY_SUBSCRIPTIONS_CHANNEL);

/**
 * Message guards
 */
function isExposeMsg(msg: ProxyMessageEvent): msg is ExposeMessage {
  return msg.from === 'expose';
}

function isReqCanExposeMsg(msg: ProxyMessageEvent): msg is ExposeMessage {
  return isExposeMsg(msg) && msg.type === 'req_can_expose';
}

function isRespCannotExposeMsg(msg: ProxyMessageEvent): msg is ExposeMessage {
  return isExposeMsg(msg) && msg.type === 'resp_cannot_expose';
}

function isProxyMsg(msg: ProxyMessageEvent): msg is ProxyMessage {
  return msg.from === 'proxy';
}

function isProxyHandshake(msg: ProxyMessageEvent): msg is ProxyMessage {
  return isProxyMsg(msg) && msg.action === 'handshake';
}

function isProxyActionMsg(msg: ProxyMessageEvent): msg is ProxyMessage {
  return isProxyMsg(msg) && !isProxyHandshake(msg);
}

/**
 * Exposes an instance to be proxied remotely
 *
 * @param instance - any object or class intance
 * @param id - unique ID for the instance
 * @param onDestroy - callback to be called when the instance is destroyed
 * @returns Unexpose - a function that can be called to unexpose the instance
 */
export function expose<T extends AnyInstance, K extends keyof T>(
  instance: T,
  id: string,
  options?: ExposeOptions<T, K> & { awaitConfirmation: true }
): Promise<[Unexpose, T]>;
export function expose<T extends AnyInstance, K extends keyof T>(
  instance: T,
  id: string,
  options?: ExposeOptions<T, K> & { awaitConfirmation?: false }
): [Unexpose, T];
export function expose<T extends AnyInstance, K extends keyof T>(
  instance: T,
  id: string,
  options?: ExposeOptions<T, K>
): [Unexpose, T] | Promise<[Unexpose, T]> {
  /**
   * Merge options with defaults and destructure
   */
  const {
    awaitConfirmation = true,
    handshakeTimeout = DEFAULT_HANDSHAKE_TIMEOUT,
    observe = true,
    autoSerialize = true,
    resolveProxy = true,
    hideWarnings,
    onDestroy,
    ignoreProperties = []
  } = {
    ...(getGlobalOptions().expose || {}),
    ...(options || {})
  };

  /**
   * State for the exposed instance
   */
  const state = {
    uuid: createUUID()
  };

  /**
   * Creates an observable instance
   * and emits events when something changes
   */
  const maybeObservedInstance = observe
    ? (createObservable(
        instance,
        (path, value, previousValue, parentValues) => {
          // Emit messages something changed
          subscriptionsChannel
            .postMessage({
              id,
              path,
              value: resolveProxy
                ? deepResolveProxy(maybeSerialize(value, [], autoSerialize))
                : maybeSerialize(value, [], autoSerialize),
              previousValue: resolveProxy
                ? deepResolveProxy(maybeSerialize(previousValue, [], autoSerialize))
                : maybeSerialize(previousValue, [], autoSerialize),
              parentValues: resolveProxy
                ? deepResolveProxy(maybeSerialize(parentValues, ignoreProperties, autoSerialize))
                : maybeSerialize(parentValues, ignoreProperties, autoSerialize)
            })
            .catch(console.error);
        },
        ignoreProperties
      ) as T)
    : instance;

  /**
   * Helper function to check if the message is for a different instance
   *
   * @param msg
   * @returns boolean
   */
  function isDifferentInstance(msg: ProxyMessageEvent): boolean {
    return isExposeMsg(msg) && msg.uuid !== state.uuid;
  }

  /**
   * Sends a message to the remote instance and returns a promise that resolves
   * when the remote instance responds
   *
   * @param msg - ProxyMessage
   * @returns Promise<void>
   */
  function checkExposure(): Promise<unknown> {
    return new Promise<void>((resolve, reject) => {
      /**
       * If there are no replies after the timeout, resolve the promise
       */
      const timeout = setTimeout(() => {
        resolve();
      }, handshakeTimeout);

      /**
       * Listener for messages from the remote instance
       * If the message is a response saying it cannot be exposed,
       * with the same ID as the instance being exposed, and the instance is active,
       * clear the timeout and reject the promise
       */
      const listener = (msg: ProxyMessageEvent) => {
        if (msg.id === id && isRespCannotExposeMsg(msg) && isDifferentInstance(msg)) {
          clearTimeout(timeout);
          coreChannelReq.removeEventListener('message', listener);
          reject(Error(`Exposed object with ID ${id} already exists.`));
        }
      };

      coreChannelReq.addEventListener('message', listener);

      /**
       * Send a message to the remote instance to check if it can be exposed
       */
      coreChannelRes
        .postMessage({
          id,
          from: 'expose',
          type: 'req_can_expose',
          uuid: state.uuid
        })
        .catch(console.error);
    });
  }

  /**
   * Resolves a nested property on an object
   *
   * @param instance - any object or class intance
   * @param path - used to track nested properties
   * @returns resolved value
   */
  function resolvePath(instance: any, path: string[]): any {
    let current = instance;
    for (let i = 0; i < path.length; i++) {
      const prop = path[i];

      // Ignore properties if it's the first item in the path
      if (i === 0 && ignoreProperties.includes(prop as unknown as K)) {
        current = undefined;
        break;
      }

      current = current[prop];
    }
    return current;
  }

  /**
   * Handles incoming messages from the remote proxy
   *
   * @param msg - ProxyMessageEvent
   */
  const listener = async (msg: ProxyMessageEvent) => {
    // If the message is not for this instance, ignore it
    if (msg.id !== id) {
      return;
    }

    /**
     * If the message is a request to check if the instance can be exposed,
     * with the same ID as the instance being exposed, and the instance is active,
     * respond with a message saying it cannot be exposed
     */
    if (isReqCanExposeMsg(msg) && isDifferentInstance(msg)) {
      coreChannelRes
        .postMessage({
          id,
          from: 'expose',
          type: 'resp_cannot_expose',
          uuid: state.uuid
        })
        .catch(console.error);
      return;
    }

    /**
     * If the message is an action from a proxy
     * and the instance is active, execute the action
     */
    if (isProxyActionMsg(msg)) {
      let result: any | undefined;
      let errorMessage: undefined | string;

      try {
        switch (msg.action) {
          case 'get': {
            result = resolvePath(maybeObservedInstance, msg.path);
            break;
          }
          case 'set': {
            if (msg.path.length === 0) {
              deepMergePreserve(maybeObservedInstance, msg.value);
              result = true;
              break;
            }
            const parent = resolvePath(maybeObservedInstance, msg.path.slice(0, -1));
            const key = msg.path[msg.path.length - 1];
            const currentValue = parent[key];

            if (!isWritable(parent, key)) {
              throw new Error(`Property ${msg.path.join('.')} is not writable`);
            }

            const isValueOkay = currentValue ? !isNonSerializable(currentValue) : true;

            if (!isValueOkay) {
              console.error(`Property ${msg.path.join('.')} is not serializable`, {
                currentValue,
                newValue: msg.value
              });
              throw new Error(`Property ${msg.path.join('.')} is not serializable`);
            }
            parent[key] = deepMergePreserve(currentValue, msg.value);
            result = true;
            break;
          }
          case 'call': {
            const func = resolvePath(maybeObservedInstance, msg.path);
            if (typeof func === 'function') {
              result = await func.bind(maybeObservedInstance)(...(msg.args || []));
            } else {
              throw new Error(`Property ${msg.path.join('.')} is not a function`);
            }
            break;
          }
        }
      } catch (e) {
        errorMessage = typeof e === 'string' ? e : (e as Error).message || 'Unknown error';
      }

      // Send a response message
      const resp: ProxyMessageEvent = {
        id,
        uuid: msg.uuid,
        from: 'expose',
        type: 'response',
        result: result !== undefined ? maybeSerialize(result, [], autoSerialize) : undefined,
        errorMessage
      };

      const sanitizedMessage = resolveProxy ? deepResolveProxy(resp) : resp;

      coreChannelRes.postMessage(sanitizedMessage).catch((e) => {
        console.error('Failed to send post message', resp);
        console.error(e);
      });
      return;
    }

    /**
     * If the message is an action from a proxy
     * and the instance is not active, respond with an error message
     */
    if (isProxyActionMsg(msg)) {
      coreChannelRes
        .postMessage({
          id,
          uuid: (msg as ProxyMessage).uuid,
          from: 'expose',
          type: 'response',
          errorMessage: `Cannot execute message. Object with ID ${id} is not active (likely because it's still initializing OR it's been destroyed).`
        })
        .catch(console.error);
      return;
    }

    /**
     * If the message is a handshake from a proxy,
     * respond with a message saying it can or cannot be exposed
     */
    if (isProxyHandshake(msg)) {
      coreChannelRes
        .postMessage({
          id,
          uuid: state.uuid,
          from: 'expose',
          result: maybeSerialize(maybeObservedInstance, [], autoSerialize),
          type: 'accept_proxy_handshake'
        })
        .catch(console.error);
      return;
    }
  };

  /**
   * Add listener for remote messages and set state to active
   */
  coreChannelReq.addEventListener('message', listener);

  /**
   * Unexpose fn
   */
  function unexpose() {
    coreChannelReq.removeEventListener('message', listener);
    onDestroy?.();
  }

  /**
   * Check if the instance is already exposed
   */
  if (awaitConfirmation) {
    return new Promise<[Unexpose, T]>((resolve, reject) => {
      checkExposure()
        .then(() => {
          resolve([unexpose, maybeObservedInstance]);
        })
        .catch((e) => {
          unexpose();
          reject(e);
        });
    });
  } else {
    /**
     * If we don't need to wait for confirmation, just in the background & unexpose if it fails
     */
    checkExposure().catch(() => {
      if (!hideWarnings) {
        console.warn(`Failed to expose object with ${id} in the background`);
      }
      unexpose();
    });

    /**
     * Return a function that can be called to unexpose the instance
     */
    return [unexpose, maybeObservedInstance];
  }
}

/**
 * Creates a proxy for a remote instance with getters, setters, methods & subscriptions
 * on the instance available as properties on the proxy
 *
 * @param id - unique ID for the instance
 * @param responseTimeout - timeout for remote responses
 * @returns a proxy for the remote instance
 */
export function createProxy<T extends AnyInstance, TIgnoreProperties extends keyof T = never>(
  id: string,
  options: CreateProxyOptions & { awaitConfirmation: true }
): Promise<RemoteProxy<T, TIgnoreProperties>>;
export function createProxy<T extends AnyInstance, TIgnoreProperties extends keyof T = never>(
  id: string,
  options?: CreateProxyOptions & { awaitConfirmation?: false }
): RemoteProxy<T, TIgnoreProperties>;
export function createProxy<T extends AnyInstance, TIgnoreProperties extends keyof T = never>(
  id: string,
  options?: CreateProxyOptions
): RemoteProxy<T, TIgnoreProperties> | Promise<RemoteProxy<T, TIgnoreProperties>> {
  /**
   * Merge options with defaults and destructure
   */
  const {
    awaitConfirmation,
    responseTimeout,
    resolveProxy = true,
    retryEvery = 0,
    handshakeTimeout = DEFAULT_HANDSHAKE_TIMEOUT
  } = {
    ...(getGlobalOptions().proxy || {}),
    ...(options || {})
  };

  const adjustedHandshakeTimeout = retryEvery > 0 ? responseTimeout : handshakeTimeout;

  /**
   * Sends a message to the remote instance and returns a promise that resolves
   * when the remote instance responds
   *
   * @param msg - ProxyMessage
   * @returns Promise<unknown>
   */
  function sendMessage(_msg: Omit<ProxyMessage, 'uuid'>, retryEvery = 0): Promise<unknown> {
    /**
     * Generate a unique ID for the message
     * and add it to the message
     */
    const uuid = createUUID();
    const msg = {
      ..._msg,
      uuid
    };
    return new Promise<unknown>((resolve, reject) => {
      /**
       * Set a timeout for the response based on the type of message
       */
      const timeoutMs = isProxyHandshake(msg) ? adjustedHandshakeTimeout : responseTimeout;

      /**
       * Create an error message based on the type of message
       */
      const errorMessage = isProxyActionMsg(msg)
        ? `Timeout error after ${timeoutMs}ms, waiting for remote reply from proxy: ${id}. Type: ${msg.action}. Proxy path: ${msg.path}.`
        : isProxyHandshake(msg)
          ? `Cannot create proxy. Object with ID ${id} not found.`
          : `Timeout error after ${timeoutMs}ms`;

      let retryEveryInterval: NodeJS.Timeout | undefined;

      /**
       * If there are no replies after the timeout, reject the promise
       */
      const timeout = setTimeout(() => {
        coreChannelRes.removeEventListener('message', listener);
        if (retryEveryInterval) {
          clearInterval(retryEveryInterval);
        }
        reject(Error(errorMessage));
      }, timeoutMs);

      /**
       * Utility function to clear the timeout and remove the listener
       */
      function clearListener() {
        clearTimeout(timeout);
        if (retryEveryInterval) {
          clearInterval(retryEveryInterval);
        }
        coreChannelRes.removeEventListener('message', listener);
      }

      /**
       * Listener for messages from the remote instance
       */
      const listener = (msg: ProxyMessageEvent) => {
        // If the message is not for this instance, ignore it
        if (msg.id !== id || !isExposeMsg(msg)) {
          return;
        }

        switch (msg.type) {
          /**
           * If the message is a response, clear the timeout and resolve or reject the promise
           */
          case 'response': {
            if (msg.uuid !== uuid) {
              return;
            }

            clearListener();
            if (msg.errorMessage) {
              reject(Error(msg.errorMessage));
            } else {
              resolve(msg.result);
            }
            break;
          }
          /**
           * If the message is a response saying it's aaccepting the handshake request
           * clear the timeout and resolve the promise
           */
          case 'accept_proxy_handshake': {
            clearListener();
            resolve(msg.result);
            break;
          }
          /**
           * If the message is a response saying it's rejecting the handshake request
           * clear the timeout and reject the promise
           */
          case 'reject_proxy_handshake': {
            // Do not clear the listener if retryEvery is set
            // We wanna keep trying until the timeout
            if (retryEvery > 0) {
              break;
            }

            clearListener();
            reject(
              Error(
                `Cannot create proxy. Object with ID ${id} is not active (likely because it's still initializing OR it's been destroyed).`
              )
            );
            break;
          }
        }
      };

      coreChannelRes.addEventListener('message', listener);
      const sanitizedMessage = resolveProxy ? deepResolveProxy(msg) : msg;
      const postMessage = () => {
        coreChannelReq.postMessage(sanitizedMessage).catch((e) => {
          console.error('Failed to post message.', msg);
          console.error(e);
        });
      };

      postMessage();

      /**
       * If there is a retry interval, keep retrying until the timeout
       */
      if (retryEvery > 0) {
        retryEveryInterval = setInterval(() => {
          postMessage();
        }, retryEvery);
      }
    });
  }

  /**
   * Creates a function that can be called to get, set or subscribe to a property
   *
   * @param prop - state type (get, set or subscribe)
   * @param path - used to track nested properties
   * @returns a function that can be called to get, set or subscribe to a property
   */
  function createStateReturn<T>(prop: 'get' | 'set' | 'subscribe', path: string[]) {
    switch (prop) {
      case 'get':
        return () => sendMessage({ id, from: 'proxy', action: 'get', path });
      case 'set':
        return (value: T) =>
          sendMessage({
            id,
            from: 'proxy',
            action: 'set',
            path,
            value
          });
      case 'subscribe':
        return (cb: (value: any) => void) => {
          /**
           * Handler for change messages from the remote instance
           *
           * @param msg - RemoteInstanceChangeEvent
           * @returns void
           */
          const handler = (msg: RemoteInstanceChangeEvent) => {
            // Ignore messages that are not for this instance
            if (msg.id !== id) {
              return;
            }

            // Exact match, call the callback with raw value
            const exactMatch = msg.path.join('.') === path.join('.');
            if (exactMatch) {
              cb(msg.value);
              return;
            }

            // Detect if a partial match, i.e `someObj.someProp.someNestedProp` matches `someObj.someProp`
            const matchesPath =
              msg.path.join('.').startsWith(path.join('.')) || path.join('.').startsWith(msg.path.join('.'));

            if (matchesPath) {
              // Detect where the partial match is in the path
              const matchingIndex = getMatchingIndex(path, msg.path);

              // Get the full value from the parentValues array (as this contains the full context)
              const fullValue =
                typeof matchingIndex === 'number' ? msg.parentValues[matchingIndex + 1] : msg.parentValues[0];

              const lastPathItem = path[path.length - 1];

              // Get the value from the full value OR from the message value
              // based on if its an object and a property is being requested
              const value = fullValue
                ? fullValue
                : typeof msg.value === 'object' && lastPathItem
                  ? msg.value[lastPathItem]
                  : undefined;

              // Call the callback with the value
              cb(value);
              return;
            }
          };

          // Add the listener
          subscriptionsChannel.addEventListener('message', handler);

          return () => {
            // Remove the listener in Unsubscribe Function
            subscriptionsChannel.removeEventListener('message', handler);
          };
        };
    }
  }

  /**
   * Creates a function that can be called to execute a function on the remote instance
   *
   * @param path - used to track nested properties
   * @returns a function that can be called to execute a function on the remote instance
   */
  function createCallFunctionReturn(path: string[]) {
    return (...args: any[]) =>
      sendMessage({
        id,
        from: 'proxy',
        action: 'call',
        path,
        args
      });
  }

  /**
   * Creates a proxy for a remote instance with getters, setters, methods & subscriptions
   * on the instance available as properties on the proxy
   * and recursively creates proxies for nested properties
   *
   * @param path - used to track nested properties
   * @returns a proxy for the remote instance
   */
  function createProxy(path: string[]): RemoteProxy<T, TIgnoreProperties> {
    return new Proxy(
      {} as unknown as T,
      {
        get(_target: T, property: keyof T) {
          if (property === IS_PROXY) {
            return true;
          }
          const prop = property as string;
          const fullPath = [...path, prop];
          switch (prop) {
            case 'then':
              return undefined;
            case 'get':
            case 'set':
            case 'subscribe':
              return createStateReturn(prop, path);
            case 'call':
              return createCallFunctionReturn(path);
            default:
              return createProxy(fullPath);
          }
        }
      } as ProxyHandler<T>
    ) as unknown as RemoteProxy<T, TIgnoreProperties>;
  }

  /**
   * Send handshake message to remote instance if awaitConfirmation is true
   * and wait for response before creating the proxy & returning it.
   */
  if (awaitConfirmation) {
    return new Promise<RemoteProxy<T, TIgnoreProperties>>((resolve, reject) => {
      sendMessage(
        {
          id,
          from: 'proxy',
          action: 'handshake',
          path: []
        },
        retryEvery
      )
        .then(() => {
          /**
           * Create & return the proxy
           */
          const proxy = createProxy([]);
          resolve(proxy);
        })
        .catch(reject);
    });
  }

  /**
   * Otherwise just create & return the proxy
   */
  const proxy = createProxy([]);
  return proxy;
}
