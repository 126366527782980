import { RxApolloClient } from '@app/data-access/api/rx-apollo-client';
import type { DataSourceCommon, ICrudService } from '@oms/frontend-foundation';
import { asDataSource } from '@oms/frontend-foundation';
import type { GetTagsQuery, TagFragment, TagsEdgeFragment, TagTypeEnum } from '@oms/generated/frontend';
import { GetTagsDocument } from '@oms/generated/frontend';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { testScoped } from '@app/workspace.registry';

@testScoped
@singleton()
export class OrderTagsService implements ICrudService<TagFragment> {
  private _apolloClient: RxApolloClient;

  constructor(@inject(RxApolloClient) apolloClient: RxApolloClient) {
    this._apolloClient = apolloClient;
  }

  watchAll$(...tagTypes: TagTypeEnum[]): Observable<DataSourceCommon<TagFragment>> {
    const result = this._apolloClient.rxWatchQuery<GetTagsQuery>({
      query: GetTagsDocument
    });

    return result.pipe(
      map(({ data }) => {
        const tags = (data.tags?.edges as TagsEdgeFragment[])?.map((e) => e.node);
        if (tagTypes.length) {
          const t = tags.filter((tag) => tag.tagTypes.some((t) => t && tagTypes.includes(t)));
          return asDataSource(t);
        }
        return asDataSource(tags);
      })
    );
  }
}
