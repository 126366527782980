import { ComponentType } from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import {
  CHARGE_FEE_TOTAL_CELL_RENDERER,
  CHARGE_MARKET_TOTAL_CELL_RENDERER,
  ChargeFeeTotalCellRenderer,
  ChargeMarketTotalCellRenderer
} from './charges/charge-group-total.cell-renderer';

export type AppCellRenderers =
  | typeof CHARGE_FEE_TOTAL_CELL_RENDERER
  | typeof CHARGE_MARKET_TOTAL_CELL_RENDERER;

export const APP_CELL_RENDERERS = {
  [CHARGE_FEE_TOTAL_CELL_RENDERER]: ChargeFeeTotalCellRenderer,
  [CHARGE_MARKET_TOTAL_CELL_RENDERER]: ChargeMarketTotalCellRenderer
} as Record<AppCellRenderers, ComponentType<ICellRendererParams>>;
