import type { FieldApi } from '@data-driven-forms/react-form-renderer';
import type { Optional } from '@oms/shared/util-types';
import type { OrderSizeRow } from '@app/data-access/offline/collections/user-preferences.collection';
import { RangeSegmentedRowUtil } from '@app/forms/common/utils/range-segmented-row-util.abstract.class';
import { extractIndicesFromFieldName } from '@app/forms/common/utils/field.utils';
import type { OrderSettingsFormValues } from '../order-settings.form-contract';

export class OrderSizeRowUtil extends RangeSegmentedRowUtil<OrderSizeRow, OrderSettingsFormValues> {
  // 🔒 Protected -------------------------------------------------- /

  protected getRows(allFormValues: OrderSettingsFormValues): Optional<OrderSizeRow[]> {
    const [profileIndex = 0] = extractIndicesFromFieldName(this.fieldName);
    return allFormValues.profiles?.[profileIndex]?.rows;
  }

  protected initRows(
    allFormValues: OrderSettingsFormValues,
    initialRows?: OrderSizeRow[]
  ): OrderSettingsFormValues {
    const clonedValues = structuredClone(allFormValues);
    const [profileIndex = 0] = extractIndicesFromFieldName(this.fieldName);
    const profile = clonedValues.profiles?.[profileIndex];
    if (!profile) {
      return clonedValues;
    }
    if (Array.isArray(profile.rows)) {
      if (Array.isArray(initialRows)) profile.rows.push(...initialRows);
    } else {
      profile.rows = initialRows || [];
    }
    return clonedValues;
  }

  protected getRowIndex(): Optional<number> {
    const [_, rowIndex] = extractIndicesFromFieldName(this.fieldName);
    return rowIndex;
  }

  // 🧊 Static -------------------------------------------------- /

  public static getProfileDescription(
    allValues: OrderSettingsFormValues,
    fieldApi: FieldApi<unknown, HTMLInputElement>
  ) {
    const [profileIndex = 0] = extractIndicesFromFieldName(fieldApi.input.name);
    return allValues.profiles?.[profileIndex]?.description;
  }
}
