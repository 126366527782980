import { SharedNotification } from '@oms/shared/oms-common';
import type { NotificationRow } from '@oms/generated/frontend';

// Our widget has a "visibility" corresponding to each tab.
export const NOTIFICATION_VISIBILITY = {
  MINE: 'MINE',
  TEAM: 'TEAM',
  ALL: 'ALL'
} as const;

export type NotificationVisibility = (typeof NOTIFICATION_VISIBILITY)[keyof typeof NOTIFICATION_VISIBILITY];

// Each tab's visibility maps to actual PrimaryAlertType values used for filtering.
export const NOTIFICATION_VISIBILITY_VALUE = {
  [NOTIFICATION_VISIBILITY.MINE]: [SharedNotification.PrimaryAlertType.MyAlert],
  [NOTIFICATION_VISIBILITY.TEAM]: [
    SharedNotification.PrimaryAlertType.MyAlert,
    SharedNotification.PrimaryAlertType.TeamAlert
  ],
  [NOTIFICATION_VISIBILITY.ALL]: [
    SharedNotification.PrimaryAlertType.MyAlert,
    SharedNotification.PrimaryAlertType.TeamAlert,
    SharedNotification.PrimaryAlertType.AllAlert
  ]
} as const;

export type NotificationEvent = {
  notification: NotificationRow;
  visibility: NotificationVisibility;
  error: any;
};

export type NotificationPreferenses = Record<string, unknown>;

export type NotificationSignal = NotificationVisibility;
