import {
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  useVGrid,
  VGrid
} from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { GridConfigEventHandler } from '@app/data-access/services/system/grids/grid-config.event-handler';
import { TableServerDatasourceService } from '@app/data-access/services/system/table-server/table-server.datasource';
import { TradeStatus, TsTradesWithFilterDocument } from '@oms/generated/frontend';
import type { TradeRow, TsTradesWithFilterSubscription } from '@oms/generated/frontend';
import { openModifyTradeAction } from './grid-actions/open-modify.trade.action';
import { cancelTradeAction } from './grid-actions/cancel.trade.action';
import { buildTradesMonitorColumnDefs } from './trades-monitor.columns';

type TradesMonitorWidgetProps = {
  id?: string;
};

export const TradesMonitorWidget = ({ id }: TradesMonitorWidgetProps) => {
  const datasourceService = useService(TableServerDatasourceService);

  const gridProps = useVGrid<TradeRow>(
    'trades-monitor',
    (builder) =>
      builder
        .tableServerColumnLibrary(buildTradesMonitorColumnDefs())
        .datasource((d) =>
          d
            .source(
              datasourceService.getSource<TradeRow, TsTradesWithFilterSubscription>({
                query: TsTradesWithFilterDocument,
                getData: (r) => r.tsTradesWithFilter,
                ...(id && {
                  filter: {
                    tradingOrderId: {
                      filterType: 'text',
                      type: 'equals',
                      filter: id
                    }
                  }
                })
              })
            )
            .rowId((r) => r.data.id)
            .cacheBlockSize(100)
        )
        .injectEvents([GridConfigEventHandler])
        .rowStateRules({
          noExecutedQuantity: (params) => params.data?.status === TradeStatus.Inactive
        })
        .sideBar()
        .rowSelection((c) => c.multiple())
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(openModifyTradeAction())
                .action(cancelTradeAction())
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
            )
            .settings((s) => s.fromFile({}))
        )
        .reactiveCustomComponents(),
    [datasourceService, id]
  );
  return <VGrid {...gridProps} />;
};

export default TradesMonitorWidget;
