import { OrderType, OrderSide, TsInvestorOrdersWithFilterDocument } from '@oms/generated/frontend';
import type { InvestorOrderRow, TsInvestorOrdersWithFilterSubscription } from '@oms/generated/frontend';
import { singleton, inject } from 'tsyringe';
import { getInvestorOrderFilter } from '@app/data-access/services/trading/data-access.trading.utils';
import type { MatchedInvestorOrdersPlacement } from '@app/containers/matched-investor-orders/matched-investor-orders-checkbox-group';
import { AuthService } from '../../system/auth/auth.service';
import { TableServerService } from '../../system/table-server/table-server.service';
import { TableServerRowSubscriptionVariables } from '../../system/table-server/table-server.datasource.contracts';
import { testScoped } from '@app/workspace.registry';
import { agSortModelToTableServerSortStr } from '../../system/table-server/filters/ag-grid.table-server.transformer';

type CompatibleAndIncompatibleOrders = Record<string, InvestorOrderRow[]>;

export type TradingOrderValues = {
  limitPrice?: number;
  instrumentId: string;
  side: OrderSide;
  orderIds?: string[];
  accountId?: string;
};

type QueryInput = Pick<TradingOrderValues, 'instrumentId' | 'side'>;

@testScoped
@singleton()
export class MatchableInvestorOrdersSubscriptionService {
  constructor(
    @inject(AuthService)
    private authService: AuthService,
    @inject(TableServerService) private tableServerService: TableServerService
  ) {}

  public queryMatchableInvestorOrders$({ instrumentId, side }: QueryInput) {
    const variables = this.getMappableOrdersQueryVariables({ side, instrumentId });

    return this.tableServerService.query$<InvestorOrderRow, TsInvestorOrdersWithFilterSubscription>({
      query: TsInvestorOrdersWithFilterDocument,
      getData: (r) => r.tsInvestorOrdersWithFilter,

      variables
    });
  }

  private getMappableOrdersQueryVariables({
    side,
    instrumentId
  }: QueryInput): TableServerRowSubscriptionVariables {
    if (!side) throw new Error('Cannot match IOs without a side');
    if (!instrumentId) throw new Error('Cannot match IOs without instrument id');

    const ownerId = this.authService.getUserId();
    if (!ownerId) throw new Error('Cannot match IOs without owner id');

    return {
      filterBy: getInvestorOrderFilter(instrumentId, side, ownerId),
      sortBy: agSortModelToTableServerSortStr([
        {
          colId: 'createdTimestamp',
          sort: side === OrderSide.Buy ? 'asc' : 'desc'
        }
      ]),
      limit: 1000,
      offset: 0
    };
  }

  private isCompatibleOrder(
    io: Pick<InvestorOrderRow, 'limitPrice' | 'orderType' | 'side' | 'openQuantity'>,
    to: TradingOrderValues,
    placement: MatchedInvestorOrdersPlacement
  ): boolean {
    if (!io.openQuantity) return false;

    // market IOs are always compatible
    if (io.orderType === OrderType.Market) {
      return true;
    }

    if (io.side && io.orderType === OrderType.Limit) {
      if (placement === 'montage') {
        return Boolean(io.limitPrice);
      }

      if (placement === 'trading-order-entry' && to) {
        return true;
      }

      throw new Error('placement must be either "montage" or "trading-order-entry"');
    }

    return false;
  }

  public clientSideFilterCompatibleAndIncompatibleOrders(
    orders: InvestorOrderRow[],
    toValues: TradingOrderValues,
    placement: MatchedInvestorOrdersPlacement
  ): CompatibleAndIncompatibleOrders {
    return orders.reduce<{
      compatibleOrders: InvestorOrderRow[];
      incompatibleOrders: InvestorOrderRow[];
    }>(
      (accum, order) => {
        if (this.isCompatibleOrder(order, toValues, placement)) {
          accum.compatibleOrders.push(order);

          accum.compatibleOrders.sort((a, b) => {
            if (a.orderType === OrderType.Market && b.orderType === OrderType.Limit) {
              return -1;
            }

            if (a.orderType === OrderType.Limit && b.orderType === OrderType.Market) {
              return 1;
            }

            return 0;
          });

          accum.compatibleOrders.sort((a, b) => {
            if (a.orderType === b.orderType) {
              if (a.validatedTimestamp < b.validatedTimestamp) {
                return -1;
              }
              if (b.validatedTimestamp < a.validatedTimestamp) {
                return 1;
              }
            }
            return 0;
          });
        } else {
          accum.incompatibleOrders.push(order);
        }
        return accum;
      },
      { compatibleOrders: [], incompatibleOrders: [] }
    );
  }
}
