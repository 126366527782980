import React, { useRef, useState, useEffect } from 'react';
import type { ICellRendererParams } from '@ag-grid-community/core';
import { useSize, format, type FormatType } from '@oms/shared-frontend/ui-design-system';

export const FLEXIBLE_DATETIME_RENDERER = 'FlexibleDatetimeCellRenderer';

const formats: FormatType[][] = [
  ['date', 'time-with-milliseconds'],
  ['datetime'],
  ['monthAndDay', 'time-with-seconds'],
  ['monthAndDay', 'time'],
  ['time']
];

export const FlexibleDatetimeCellRenderer: React.FC<
  Omit<ICellRendererParams, 'value'> & { value: string }
> = ({ value }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const hiddenRef = useRef<HTMLSpanElement>(null);

  const containerEl = useSize(containerRef, { debounceTime: 1000, throttleTime: 1000 });
  const containerWidth = containerEl ? containerEl.width : 200;

  const [displayState, setDisplayState] = useState<{ content: string; tooltip: string | null }>({
    content: '',
    tooltip: null
  });

  useEffect(() => {
    if (!hiddenRef.current || containerWidth === 0) {
      setDisplayState({ content: '', tooltip: null });
      return;
    }

    for (const fmtCombination of formats) {
      const formattedValue = fmtCombination.map((fmt) => format(fmt, value)).join(', ');

      // Render the text in the hidden span for measurement
      hiddenRef.current.textContent = formattedValue;
      const textWidth = hiddenRef.current.offsetWidth;

      if (textWidth <= containerWidth) {
        setDisplayState({ content: formattedValue, tooltip: null });
        return;
      }
    }

    // Fallback: Minimal format with a tooltip
    const minimalValue = `${format('time', value).slice(0, 2)}:⋯`;
    setDisplayState({ content: minimalValue, tooltip: format('datetime', value) });
  }, [value, containerWidth]);

  return (
    <div ref={containerRef} className="container">
      {/* Hidden span used for measurement */}
      <span ref={hiddenRef} style={{ visibility: 'hidden', position: 'absolute', whiteSpace: 'nowrap' }} />
      <span title={displayState.tooltip || undefined} data-testid="content">
        {displayState.content}
      </span>
    </div>
  );
};
