import type { ComponentType } from 'react';
import { withUpdatedData, useUpdatedData } from './with-updated-data';
import type { UpdatedData, UpdatedDataProps } from './with-updated-data';
import { InvestorOrdersSubscriptionService } from '@app/data-access/services/trading/investor-orders/investor-orders.subscriptions.service';
import type { InvestorOrderWithChargesRow } from '@app/widgets/trading/investor-order-monitor/investor-order-monitor.contracts';

export type UpdatedInvestorOrder = UpdatedData<InvestorOrderWithChargesRow>;

export const withUpdatedInvestorOrder = (
  Component: ComponentType<UpdatedInvestorOrder>,
  props: UpdatedDataProps = {}
) => {
  return withUpdatedData<InvestorOrderWithChargesRow>(Component, InvestorOrdersSubscriptionService, props);
};

export const useUpdatedInvestorOrder = (id: string | null | undefined) => {
  return useUpdatedData<InvestorOrderWithChargesRow>(InvestorOrdersSubscriptionService, id);
};
