import { OrderSideType, PriceType, QuantityType, TimeInForce } from '@oms/generated/frontend';
import { PrefixFiltered } from '@oms/shared/util-types';
import { toTypedRxJsonSchema, ExtractDocumentTypeFromTypedRxJsonSchema } from 'rxdb-v15';
import type { RxJsonSchema, RxCollection } from 'rxdb-v15';

// Strategy ------------------------------------------------------------------- /

const strategySchemaLiteral = {
  title: 'Strategy schema',
  version: 0,
  description: 'A schema for strategy',
  type: 'object',
  primaryKey: 'strategyName',
  properties: {
    strategyControls: {
      type: 'array',
      items: {
        type: 'object',
        properties: { id: { type: 'string', maxLength: 128 }, value: { type: 'string', maxLength: 128 } }
      }
    },
    strategyName: { type: 'string', maxLength: 128 },
    strategyParams: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          label: { type: 'string', maxLength: 128 },
          name: { type: 'string', maxLength: 128 },
          uiRep: { type: 'string', maxLength: 128 },
          value: { type: 'string', maxLength: 128 }
        }
      }
    },
    venueId: { type: 'string', maxLength: 128 },
    isLayoutsPopulated: { type: 'boolean' },
    orderFormValues: { type: 'object' }
  },
  required: ['strategyName', 'venueId']
} as const;

const strategySchemaTyped = toTypedRxJsonSchema(strategySchemaLiteral);

export type StrategySchemaDocType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof strategySchemaTyped>;

export const strategySchema: RxJsonSchema<StrategySchemaDocType> = strategySchemaLiteral;

// User Preferences ------------------------------------------------------------------- /

const schemaTyped = toTypedRxJsonSchema({
  title: 'User Preferences Schema',
  description: 'Stores user preferences',
  version: 0,
  keyCompression: false,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: { type: 'string', maxLength: 128 },
    // Montage settings ---------------------------------------- /
    montageSettings: {
      type: 'object',
      properties: {
        // Ask - Important: Always keep aligned with Bid defs
        askDestinationId: { type: 'string', maxLength: 128 },
        askDisplaySize: { type: 'number', minimum: 0 },
        askGtdTimestamp: { type: 'string', maxLength: 128, format: 'date-time' },
        askInitiateOrder: { type: 'boolean' },
        askOrderSize: { type: 'string', maxLength: 128 },
        askPriceType: { type: 'string', enum: Object.values(PriceType) },
        askQuantityType: { type: 'string', enum: Object.values(QuantityType) },
        askQuantityValue: { type: 'number', minimum: 0 },
        askSideType: { type: 'string', enum: Object.values(OrderSideType) },
        askStrategy: strategySchemaTyped,
        askStrategyPresets: { type: 'string', maxLength: 128 },
        askTifDuration: { type: 'string', maxLength: 128 },
        askTimeInForceType: { type: 'string', enum: Object.values(TimeInForce) },
        askTimeInForceValue: { type: 'string', maxLength: 128 },
        // Bid
        bidDestinationId: { type: 'string', maxLength: 128 },
        bidDisplaySize: { type: 'number', minimum: 0 },
        bidGtdTimestamp: { type: 'string', maxLength: 128, format: 'date-time' },
        bidInitiateOrder: { type: 'boolean' },
        bidOrderSize: { type: 'string', maxLength: 128 },
        bidPriceType: { type: 'string', enum: Object.values(PriceType) },
        bidQuantityType: { type: 'string', enum: Object.values(QuantityType) },
        bidQuantityValue: { type: 'number', minimum: 0 },
        bidSideType: { type: 'string', enum: Object.values(OrderSideType) },
        bidStrategy: strategySchemaTyped,
        bidStrategyPresets: { type: 'string', maxLength: 128 },
        bidTifDuration: { type: 'string', maxLength: 128 },
        bidTimeInForceType: { type: 'string', enum: Object.values(TimeInForce) },
        bidTimeInForceValue: { type: 'string', maxLength: 128 },
        // Flags
        hideOddLots: { type: 'boolean' },
        displayQuotesInShares: { type: 'boolean' },
        sendAttributable: { type: 'boolean' },
        // Unimplmented
        orderMappings: { type: 'object' },
        orderViewOptions: { type: 'object' }
      },
      required: [
        'askPriceType',
        'askQuantityType',
        'askSideType',
        'askTimeInForceType',
        'bidPriceType',
        'bidQuantityType',
        'bidSideType',
        'bidTimeInForceType'
      ]
    },
    // Order settings ---------------------------------------- /
    orderSettings: {
      type: 'object',
      properties: {
        // Profiles ------------- /
        profiles: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              id: { type: 'string', maxLength: 128 },
              description: { type: 'string', maxLength: 128 },
              // Rows ------------- /
              rows: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    id: { type: 'string', maxLength: 128 },
                    from: { type: 'number', minimum: 0 },
                    to: { type: 'number', minimum: 0 },
                    orderSize: { type: 'number', minimum: 0 }
                  },
                  required: ['id', 'from', 'orderSize']
                }
              }
            },
            required: ['id', 'description', 'rows']
          }
        }
      },
      required: ['profiles']
    }
  },
  required: ['id']
} as const);

export type UserPreferencesDocType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof schemaTyped>;

export const userPreferencesSchema: RxJsonSchema<UserPreferencesDocType> = schemaTyped;

export const USER_PREFERENCES_COLLECTION = {
  schema: userPreferencesSchema
};

// Types ------------------------------------------------------------------- /

export type LeftClickSettingsType = 'ask' | 'bid';

export type UserPreferencesCollection = RxCollection<UserPreferencesDocType, {}, {}>;

export type UserPreferencesSchema = UserPreferencesDocType;

export type StrategySchema = StrategySchemaDocType;

export type MontageSettingsSchema = NonNullable<UserPreferencesDocType['montageSettings']>;
export type LeftClickSettings<Type extends LeftClickSettingsType> = PrefixFiltered<
  MontageSettingsSchema,
  Type
>;

export type OrderSettingsSchema = NonNullable<UserPreferencesDocType['orderSettings']>;
export type OrderSizeProfile = NonNullable<OrderSettingsSchema['profiles'][number]>;
export type OrderSizeRow = NonNullable<OrderSizeProfile['rows'][number]>;
